import { ReduceStore } from 'flux/utils';
import Dispatcher from '../dispatchers/Dispatcher';
import Timer from '../lib/Timer';
import Penalty from '../lib/Penalty';
import parseGoal from '../lib/Goal'

class TimeStore extends ReduceStore {
    constructor() {
        super(Dispatcher);
    }

    getInitialState() {
        return {
            minutes: '--',
            seconds: '--',
            period: 1,
            duration: null,
            penalties: {
                team: [],
                opponent: []
            },
            format: 5,
            numberOfPlayersOnIce: {
                team: 5,
                opponent: 5
            },
            minuteBump: true,
            manualClosePenaltySide: null,
            penaltyType: 1,
            powerPlaysCount: 0,
            penaltyKillsCount: 0
        };
    }

    /**
     * Decrement the timers by 1. This includes the game clock and the penalty clock
     */
    decrementTime(state) {
        let newTime = Timer.decrement(state);

        if (newTime.minutes === '00' && newTime.seconds === '00') {
            Timer.stopClock();

            newTime = {
                ...newTime,
                minutes: '00',
                seconds: '00',
            };
        }

        const bumpedAndEnded = this.evalBumpedAndEndedPenalties(state, -1);
        const bumped = bumpedAndEnded.bumped;
        const endedPenalties = bumpedAndEnded.ended;

        let players = {};

        if (state.format !== 3) {
            players = this.evalAllPlayersOnIce(state, bumped);
        } else {
            players = this.evalAll3v3PlayersOnIce(state, bumped);
        }

        return {
            ...state,
            ...newTime,
            ...players,
            ...endedPenalties,
            penalties: bumped
        };
    }

    evalBumpedAndEndedPenalties(state, bumpAmount) {
        const bumpedAndEnded = this.bumpPenalties(state, bumpAmount);
        const bumped = bumpedAndEnded.bumped;
        const endedPenalties = bumpedAndEnded.ended;

        const endedCounts = {
            powerPlaysCount: state.powerPlaysCount,
            penaltyKillsCount: state.penaltyKillsCount
        };

        endedCounts.powerPlaysCount += endedPenalties.opponent;
        endedCounts.penaltyKillsCount += endedPenalties.team;

        // console.log('Ended penalties: ', endedCounts);

        return {
            bumped: bumped,
            ended: endedCounts
        };
    }

    removeStacked(penalties) {
        let found = false;

        for (let i = 0; i < penalties.length; ++i) {
            if (found) {
                continue;
            }

            if (penalties[i].stacked) {
                penalties[i].stacked = false;
                found = true;
            }
        }
    }

    /**
     * Bump all penalty timers by the `amount` arg given. This is used for both
     * normal timer decrement and manual bump up/down. When the user manually
     * bumps the normal game timer it will also bump the penalty since those have
     * to move with the game clock.
     */
    bumpPenalties(state, amount) {
        // the object that holds how many penalties ended for both teams this second.
        const endedPenalties = {
            team: 0,
            opponent: 0
        }

        // function that can be reused for both collections. This is what will
        // go through each penalty in the `state` argument and run Timer.bump on them
        const bump = (collection, side) => {
            let ret = collection.map((p) => {
                if (p.stacked) {
                    return p;
                } else {
                    return {
                        ...p,
                        ...Timer.bump(p, amount)
                    };
                }
            }).filter((t) => {
                // has our penalty ended by running out of time?
                const penaltyHasEnded = t.minutes === '00' && t.seconds === '00';

                // if the penalty has ended, we bump the ended penalties counter for this side.
                if (penaltyHasEnded) {
                    endedPenalties[side]++;
                }

                // we return only penalties that have NOT ended
                return !penaltyHasEnded;
            });

            // here we have to look at the game format - number of penalties.
            // when the result is more than 2 we can remove a stacked penalty.
            // we also have to check to see if the format is 3v3 because in that
            // case we're checking if the other team would have more than 5 players
            // on ice or not. when that's the case the penalty is stacked, it just
            // works in the opposite direction.
            if ((state.format === 3 && (state.format + ret.length) < 6) || (state.format !== 3 && (state.format - ret.length) > 2)) {
                this.removeStacked(ret);
            }

            return ret;
        };

        // run the above `bump` for both sides, updating every penalty's time
        return {
            bumped: {
                team: bump(state.penalties.team, 'team'),
                opponent: bump(state.penalties.opponent, 'opponent')
            },
            ended: endedPenalties
        };
    }

    evalAll3v3PlayersOnIce(state, penalties) {
        const team = state.format + penalties.opponent.length;
        const opponent = state.format + penalties.team.length;

        return {
            numberOfPlayersOnIce: {
                team:     team > 4 ? 5 : team,
                opponent: opponent > 4 ? 5 : opponent
            }
        };
    }

    evalAllPlayersOnIce(state, penalties) {
        const team = state.format - penalties.team.length;
        const opponent = state.format - penalties.opponent.length;

        return {
            numberOfPlayersOnIce: {
                team:     team < 3 ? 3 : team,
                opponent: opponent < 3 ? 3 : opponent
            }
        };
    }

    reduce(state, action) {
        switch (action.type) {
        case 'start-clock':
            Timer.startClock();

            return {
                ...state
            };
        case 'stop-clock':
            Timer.stopClock();

            return {
                ...state
            };
        case 'time-decrement':
            return {
                ...state,
                ...this.decrementTime(state),
                // when the time is clicking also flip back over to the minute
                // bump false to only +- in seconds.
                minuteBump: false
            };
        case 'bump-time':
        case 'bump-faceoff-time':
            // we are receiving `action.data.amount` however this does not take into
            // account the +-. We just need to look at whether or not we're incrementing
            // the clock and do (amount * -1) if we're not.
            const amount = action.data.increment ? action.data.amount : (action.data.amount * -1);
            const newTime = Timer.bump(state, amount);

            /**
             * When we are in normal bump (not automatic faceoff bump), if there are no events yet, we update
             * the game period duration that will be used at the end of the game to send up to the API for
             * initial period length field on the game itself.
             *                                                            here we check if we already have data OR if this type of tagger event count
             *                                                            is undefined (not event tagger)... only the event tagger has that prop.
             */
            let initialDuration = action.type === 'bump-faceoff-time' || (action.data.eventCount > 0 || typeof action.data.eventCount === 'undefined') ? state.duration : newTime.minutes;

            // if we have clockStarted it means we have a TOI tagger and need to set it to the new minutes...
            if (action.data.toiClockNotStarted) {
                initialDuration = newTime.minutes;
            }

            // set in window so it's accessible everywhere if the initial duration has changed
            if (Number.parseInt(state.duration) !== Number.parseInt(initialDuration) || !window.initialDuration) {
                window.initialDuration = Number.parseInt(initialDuration);
            }

            // if it is a bump faceoff time, we also need to mess with the gameStartTime as well
            if (action.type === 'bump-faceoff-time' && !window.startTimeBumped) {
                console.log('Bumping start time...');
                Timer.bumpGameStartTime(amount);
                window.startTimeBumped = true;
            }

            const faceoffBumpedAndEnded = this.evalBumpedAndEndedPenalties(state, amount);
            const faceoffBumped = faceoffBumpedAndEnded.bumped;
            const faceoffEnded = faceoffBumpedAndEnded.ended;

            /**
             * Whenever we get a request to bump the time, we have to see if it's a manual faceoff bump,
             * which always goes through with the bump.
             *
             * However, if it is a manual user bump and we are currently on 'minuteBump' (beginning of a period),
             * we do not want to do that since the period times could have changed and are adjusted at the beginning.
             *
             * In other words... bump faceoff time (-2 seconds) OR NOT in minute bump mode, bump the penalties, otherwise use current state (don't change)
             */
            return {
                ...state,
                ...newTime,
                ...faceoffEnded,
                penalties: (action.type === 'bump-faceoff-time' || !state.minuteBump) ? faceoffBumped : state.penalties,
                /**
                 * When the initial faceoff sends up the dispatch to 'bump', we turn minute bump off.
                 */
                minuteBump: action.type === 'bump-faceoff-time' ? false : state.minuteBump,
                duration: initialDuration
            };
        case 'sync-tagger-clock':
            const paddedStartMinutes = action.data < 10 ? `0${action.data}` : action.data;

            return {
                ...state,
                minutes: paddedStartMinutes,
                duration: action.data,
                seconds: '00'
            };
        case 'save-penalty':
            /**
             * When the penalty types is misconduct or Game we don't have to display any
             * penalties on the UI since they're only logged into the stats.
             * Everything else is then evaluated for the type i.e. 2+2, 5, etc
             * and logged/generated that way.
             */
            const penaltyTypeObj = Penalty.selectedPenaltyTypeOptions(state.penaltyType)

            // ---------------------------- |  this used to be '10' |
            if (action.data.penaltyType === penaltyTypeObj.misconduct || action.data.penaltyType === 'Game' || action.data.penaltyType === 'Penalty Shot' || action.data.penaltyType === null || action.data.offsetting) {
                return state;
            } else {

                /**
                 * ------------------------------------------------------------------------------------------------------------------------------------
                 * @TODO for tracking the amount of penalty kills and power plays, the 'nop' variable is what gets put in when we are adding a penalty.
                 * that is where we could check if we're adding a penalty for our team, and if so add it to state somewhere to increment the pk/pp.
                 * ------------------------------------------------------------------------------------------------------------------------------------
                 * @TODO i don't think we have to touch when the timer decrements and the penalty is over because we're not tracking event status,
                 * we are only trying to track the raw number of pp/pk.
                 * ------------------------------------------------------------------------------------------------------------------------------------
                 */
                let nop = {};

                if (state.format !== 3) {
                    const penaltyTarget = action.data.side;
                    nop = {
                        ...state.numberOfPlayersOnIce,
                        [penaltyTarget]: state.numberOfPlayersOnIce[penaltyTarget] > 3 ? --state.numberOfPlayersOnIce[penaltyTarget] : 3
                    };
                } else {
                    // when the format is 3v3 (3), we have to increase the players for
                    // the other team, not the team that committed the penalty.
                    const target = action.data.side === 'team' ? 'opponent' : 'team';
                    nop = {
                        ...state.numberOfPlayersOnIce,
                        [target]: state.numberOfPlayersOnIce[target] < 5 ? ++state.numberOfPlayersOnIce[target] : 5
                    };
                }

                return {
                    ...state,
                    numberOfPlayersOnIce: nop,
                    penalties: {
                        ...state.penalties,
                        [action.data.side]: state.penalties[action.data.side].concat({
                            player: action.data.servedByNumber || action.data.playerNumber,
                            ...Penalty.parse(action.data, state.penalties[action.data.side].length, state.format, state.penaltyType)
                        })
                    }
                };
            };
        case 'end-period':
        case 'toi-end-period':
            return {
                ...state,
                minutes:    Number.parseInt(state.duration) < 10 ? `0${Number.parseInt(state.duration)}` : state.duration,
                seconds:    '00',
                period:     ++state.period,
                minuteBump: true // when the period ends minuteBump is on
            };
        case 'manual-close-penalty-modal':
            return {
                ...state,
                manualClosePenaltySide: action.data.side
            };
        case 'close-manual-close-penalty-modal':
            return {
                ...state,
                manualClosePenaltySide: null
            };
        case 'save-goal':
        case 'manual-close-penalty':

            // See Goal.js for logic
            return parseGoal(state, action)

        case 'start-overtime':
            const format = Number.parseInt(action.data.format[0]);

            return {
                ...state,
                duration: action.data.duration,
                minutes: action.data.duration,
                format: format,
                numberOfPlayersOnIce: {
                    team: format,
                    opponent: format
                }
            };
        case 'save-auto-penalty':
            // split the length for times like 1:30
            const autoLength = action.data.length.split(':')
            // mins
            let autoMin = Number.parseInt(autoLength[0])
            // seconds or 0
            let autoSec = Number.parseInt(autoLength[1] || 0)

            /**
             * Whenever we input an auto-penalty, Erik wants it to subtract 15 seconds
             * off the penalty clocks since it usually means the user caught the penalty late,
             * so we should subtract time. What we do below is see if -15 is below 0 seconds.
             * When it is, we set the seconds to 60 seconds - that difference. In that case
             * we also decrement the minutes by 1 as well.
             */
            const autoSecDiff = autoSec - 15

            if (autoSecDiff < 0) {
                autoSec = 60 + autoSecDiff;
                --autoMin;
            } else {
                autoSec = autoSec - 15;
            }

            const autoPenaltyConfig = Penalty.selectedPenaltyTypeOptions(state.penaltyType);

            // we remove auto penalties on goal only if they're the minor penalty option.
            // action.data.length is the label value e.g. 1:30, 4, etc. which should be
            // in the same format as the penalty config major/minor strings.
            const autoPenaltyRemoveOnGoal = action.data.length === autoPenaltyConfig.minor;

            return {
                ...state,
                penalties: {
                    ...state.penalties,
                    [action.data.side]: state.penalties[action.data.side].concat({
                        minutes: `0${autoMin}`,
                        seconds: `${autoSec}`,
                        player: '?',
                        removeOnGoal: autoPenaltyRemoveOnGoal,
                        stacked: false
                    })
                }
            }
        case 'set-penalty-type':
            return {
                ...state,
                penaltyType: action.data.penaltyType
            }
        default:
            return {
                ...state
            };
        }
    }
}

export default new TimeStore();
