import React, { useState } from 'react'
import Dispatcher from '../dispatchers/Dispatcher'

export default function PenaltyTypeModal() {

    const [selected, setSelected] = useState(null)

    const save = () => {
        if (!selected) {
            window.alert('You must select a penalty type configuration.');
            return;
        }

        Dispatcher.dispatch({
            type: 'set-penalty-type',
            data: {
                penaltyType: selected
            }
        });
    }

    return (
        <div className='modal-overlay team open penalty-type-modal'>
            <div className="modal-content">
                <div className="section">
                    <h1>Select Penalty Configuration</h1>
                    <div className="btn-row-6-stack penalty-type-buttons">
                        <div className={`btn ${selected === 1 ? 'active' : ''}`} onClick={() => setSelected(1)}>
                            <span>Type 1 (2:00/5/10)</span>
                        </div>
                        <div className={`btn ${selected === 2 ? 'active' : ''}`} onClick={() => setSelected(2)}>
                            <span>Type 2 (1:30/4/8)</span>
                        </div>
                        <div className={`btn ${selected === 3 ? 'active' : ''}`} onClick={() => setSelected(3)}>
                            <span>Type 3 (1:00/3/6)</span>
                        </div>
                        <div className="btn save" onClick={save}>
                            Save
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}