import React from 'react';
import Dispatcher from '../dispatchers/Dispatcher';
import Players from '../lib/Players';

export default class ConfigureRoster extends React.Component {
    handleChange(field, position, playerId, value, playerNumber) {
        Dispatcher.dispatch({
            type: 'change-user-game-player',
            data: {
                playerId: playerId,
                position: position,
                field: field,
                value: value,
                gameId: this.props.game.id,
                playerNumber: playerNumber
            }
        });
    }

    handlePosition(newPosition, playerId) {
        Dispatcher.dispatch({
            type: 'change-user-game-player-position',
            data: {
                playerId: playerId,
                newPosition: newPosition,
                gameId: this.props.game.id
            }
        });
    }

    /**
     * Dynamically render the `starting` button depending on whether or not the
     * player is a goalie -- we only show this button for goalies.
     */
    renderStarting(player) {
        if (player.position === 'goalie') {
            return (
                <div
                    className={`btn btn-small btn-indicator ${ player.starting ? 'highlighted' : '' }`}
                    onClick={() => this.handleChange('starting', player.position, player.id, (!player.starting), player.id)}>
                    <span>Starting</span>
                </div>
            );
        } else {
            return '';
        }
    }

    renderPlayers() {
        let players = [];

        // push all players into an array
        Object.keys(this.props.game.team.players).forEach((position) => {
            this.props.game.team.players[position].forEach((player) => {
                players.push({...player, position: position});
            });
        });

        Players.sort(players);

        // return player tr
        return players.map((player) => {
            return (
                <tr key={player.id}>
                    <td className="player-num">{player.number}</td>
                    <td className="name">{player.name}</td>

                    <td className="position">
                        <div className="btn-group">
                            <div
                                onClick={() => this.handlePosition('forward', player.id)}
                                className={`btn btn-small btn-indicator ${player.position === 'forward' ? 'highlighted' : ''}`}>
                                <span>Fwd</span>
                            </div>
                            <div
                                onClick={() => this.handlePosition('defense', player.id)}
                                className={`btn btn-small btn-indicator ${player.position === 'defense' ? 'highlighted' : ''}`}>
                                <span>Def</span>
                            </div>
                            <div
                                onClick={() => this.handlePosition('goalie', player.id)}
                                className={`btn btn-small btn-indicator ${player.position === 'goalie' ? 'highlighted' : ''}`}>
                                <span>Goal</span>
                            </div>
                        </div>
                    </td>

                    <td className="faceoffs">
                        <div
                            className={`btn btn-small btn-indicator ${ player.faceoff_player ? 'highlighted' : ''}`}
                            onClick={() => this.handleChange('faceoff_player', player.position, player.id, (!player.faceoff_player), player.id)}>
                            <span>Highlight</span>
                        </div>
                    </td>

                    <td className="starting">
                        {/* don't always show starting -- only when player is a goalie */}
                        {this.renderStarting(player)}
                    </td>
            
                    <td className="active">
                        <div
                            className={`btn btn-small btn-indicator ${ player.active ? 'highlighted' : ''}`}
                            onClick={() => this.handleChange('active', player.position, player.id, (!player.active), player.id)}>
                            <span>Active</span>
                        </div>
                    </td>

                    {this.props.type === 'time-on-ice' && (
                        <td className="line">
                            <select
                                className="form-control"
                                value={player.line || ""}
                                onChange={(e) => this.handleChange('line', player.position, player.id, Number.parseInt(e.target.value), player.id)}>

                                <option value=""></option>
                                <option value="1">1</option>
                                <option value="2">2</option>
                                <option value="3">3</option>
                                <option value="4">4</option>

                            </select>
                        </td>
                    )}
                </tr>
            );
        });
    }

    render() {
        return (
            <table className="roster">
                <thead>
                    <tr>
                        <th className="playernum">#</th>
                        <th className="name">Player</th>
                        <th>Position</th>
                        <th>Faceoffs</th>
                        <th>Starting</th>
                        <th>Status</th>
                        {this.props.type === 'time-on-ice' && (
                            <th>LINE/PAIR</th>
                        )}
                    </tr>
                </thead>
                <tbody>
                    {this.renderPlayers()}
                </tbody>
            </table>
        );
    }
}
