import React from 'react';
import TaggerHeader from './TaggerHeader';
import TaggerInterface from './TaggerInterface';
import PenaltyModal from './PenaltyModal';
import GoalModal from './GoalModal';
import TeamModal from './TeamModal';
import OvertimeModal from './OvertimeModal';
import OvertimeGoalModal from './OvertimeGoalModal';
import PenaltyShotModal from './PenaltyShotModal';
import ShootoutModal from './ShootoutModal';
import EndPeriodModal from './EndPeriodModal';
import EndExitGameModal from './EndExitGameModal';
import Message from './Message';
import Dispatcher from '../dispatchers/Dispatcher';
import TaggerMenu from './TaggerMenu';
import GameStats from '../components/game-report/GameStats';
import EventEditor from '../components/event-editor/EventEditor';
import ManualClosePenaltyModal from './ManualClosePenaltyModal';
import PenaltyTypeModal from './PenaltyTypeModal';
import ZoneEventOutcomeModal from './ZoneEventOutcomeModal';
import BattleOutcomeModal from './BattleOutcomeModal';

/**
 * Main component for rendering the API tagger
 */
export default class Tagger extends React.Component {
    componentWillMount() {
        Dispatcher.dispatch({
            type: 'sync-tagger-clock',
            data: this.props.game.period_duration
        });
    }

    componentDidUpdate (prevProps, prevState, snapshot) {
        if (this.props.game.faceoffBump && !this.props.game.clockRunning) {
            Dispatcher.dispatch({
                type: 'bump-faceoff-time',
                data: {
                    increment: false,
                    amount: 2
                }
            });
        }
    }

    render() {
        if(this.props.game.view === 'game-stats') {
            return this.renderStats()
        } else if(this.props.game.view === 'event-editor') {
            return this.renderEventEditor();
        } else {
            return this.renderTagger();
        }
    }

    renderStats() {
        return <GameStats game={this.props.game} />;
    }

    renderEventEditor() {
        return <EventEditor game={this.props.game} />;
    }

    renderTagger() {
        return (
            <div id="tagger">

                <TaggerMenu
                    clockRunning={this.props.game.clockRunning}
                    taggerPaused={this.props.game.taggerPaused}
                    open={this.props.game.sidebar}
                    endGame='end-game'
                    uploading={this.props.game.uploading}
                    tagger='event'
                    teamName={this.props.game.team.name}
                    opposingName={this.props.game.opponent_team_name}
                    showAutoPenalty={true}
                    time={this.props.time}
                    modal={this.props.game.modal} 
                    hideEditor={false}  />

                <Message message={this.props.game.message} flagEvent={this.props.game.flagEvent} />

                <TaggerHeader
                    teamName={this.props.game.team.name}
                    opponent_team_name={this.props.game.opponent_team_name}
                    time={this.props.time}
                    shots={this.props.game.shots}
                    goals={this.props.game.goals}
                    eventCount={this.props.game.events.length} />

                <TaggerInterface
                    configuration={this.props.game.configuration}
                    players={this.props.game.team.players}
                    currentGoalie={this.props.game.current_goalie}
                    time={this.props.time}
                    playerLock={this.props.game.playerLock}
                    iceLock={this.props.game.iceLock}
                    outcomeLock={this.props.game.outcomeLock}
                    faceoffLock={this.props.game.faceoffLock}
                    lockedEvent={this.props.game.lockedEvent}
                    bottomMenu={this.props.game.bottomMenu}
                    topMenu={this.props.game.topMenu}
                    bottomItem={this.props.game.bottomItem}
                    direction={this.props.game.direction}
                    clockStopped={this.props.game.clockStopped}
                    showFaceoffAlerts={this.props.game.team.use_faceoff_alerts}
                    eventButton={this.props.game.team.event_button}
                    battleButton={this.props.game.team.battle_replaces_generic_event}
                    faceoffAlert={this.props.game.faceoffAlert} />

                <PenaltyModal
                    open={this.props.game.modal === 'penalty'}
                    teamName={this.props.game.team.name}
                    opposingName={this.props.game.opponent_team_name}
                    time={this.props.time}
                    players={this.props.game.team.players}
                    offsettingPenalty={this.props.game.offsettingPenalty}
                    modalRef={React.createRef()}
                    penaltyType={this.props.time.penaltyType}
                    eventCount={this.props.game.events.length} />

                <TeamModal
                    open={this.props.game.modal === 'team'}
                    teamName={this.props.game.team.name}
                    opposingName={this.props.game.opponent_team_name}
                    time={this.props.time} />
                
                <ZoneEventOutcomeModal
                    open={this.props.game.modal === 'zone-event-outcome'}
                    time={this.props.time} />

                <BattleOutcomeModal
                    open={this.props.game.modal === 'battle-outcome'}
                    time={this.props.time} />

                <GoalModal
                    open={this.props.game.modal === 'goal'}
                    players={this.props.game.team.players}
                    time={this.props.time}
                    defense={this.props.game.lockedEvent && this.props.game.lockedEvent.team === this.props.game.opponent_team_name}
                    player={this.props.game.lockedEvent ? this.props.game.lockedEvent.player : null}
                    form={this.props.game.goalModal}
                    currentGoalie={this.props.game.current_goalie} />

                { this.props.game.modal === 'select-penalty-type' && <PenaltyTypeModal /> }

                <OvertimeModal
                    open={this.props.game.modal === 'overtime'}
                    time={this.props.time}
                    duration={this.props.game.period_duration} />

                <OvertimeGoalModal
                    open={this.props.game.modal === 'overtime-goal'}
                    time={this.props.time} />

                <PenaltyShotModal
                    open={this.props.game.modal === 'penalty-shot'}
                    time={this.props.time} />

                <ShootoutModal
                    open={this.props.game.modal === 'shootout'}
                    time={this.props.time}
                    players={this.props.game.team.players}
                    teamName={this.props.game.team.name}
                    opponentName={this.props.game.opponent_team_name}
                    goals={this.props.game.shootoutGoals} />

                <EndPeriodModal 
                    open={this.props.game.modal === 'end-period'} 
                    time={this.props.time} />

                <EndExitGameModal 
                    open={this.props.game.modal === 'end-game' || this.props.game.modal === 'exit-game'}
                    modalType={this.props.game.modal}
                    headerText={ this.props.game.modal === 'end-game' ? 'End Game (Save Data)' : 'Exit Game (Delete Data)' }
                    buttonText={ this.props.game.modal === 'end-game' ? 'End Game' : 'Exit Game' }
                    time={this.props.time} />

                <ManualClosePenaltyModal
                    open={this.props.game.modal === 'manual-close-penalty-modal'} />

            </div>
        );
    }
}
