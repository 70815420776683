import React from 'react'
import Dispatcher from '../dispatchers/Dispatcher'

export default function ModalTimeButtons({ time, eventCount }) {

    const bumpTime = (isIncrementing) => {
        Dispatcher.dispatch({
            type: 'bump-time',
            data: {
                increment:  isIncrementing,
                amount:     1, // always bump 1 second per task
                eventCount: eventCount,
                time:       time
            }
        });
    }

    return (
        <div className="section modal-time-buttons" style={{ width: '50%', margin: 'auto' }}>
            <div className="inner">
                <div className="label">Adjust Clock</div>
                <div className="btn-row-2">
                    <div
                        className='btn'
                        onClick={() => bumpTime(false)}>
                        <span>- 1 Second</span>
                    </div>
                    <div
                        className='btn'
                        onClick={() => bumpTime(true)}>
                        <span>+ 1 Second</span>
                    </div>
                </div>
            </div>
        </div>
    )
}
