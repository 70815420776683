import React from 'react'
import Strings from '../../lib/Strings'
import Dispatcher from '../../dispatchers/Dispatcher'

export default function TimeOnIceZones({ time, activeZone }) {
    const handleClick = (zone) => {
        Dispatcher.dispatch({
            type: 'toi-zone-click',
            data: {
                zone: zone,
                time: time
            }
        })
    }

    // "OZ Control", "OZ No Control", "DZ Control" and "DZ No Control"
    return ["oz_control", "oz_no_control", "dz_control", "dz_no_control"].map((zone) => (
        <div key={zone} className={`btn ${activeZone && activeZone.zone === zone ? 'active' : ''}`} onClick={() => handleClick(zone)}>
            <span>
                {Strings.titleize(zone)}
            </span>
        </div>
    ))
}
