import React from 'react'
import TeamRow from './TeamRow'

export default class TeamStats extends React.Component {
    render() {
        return (
            <table style={{ width: '100%' }}>
                <thead>
                    <tr>
                        <th>Teams</th>
                        <th>G</th>
                        <th>ICE</th>
                        <th>OFF</th>
                        {/* GvA */}
                        <th>G<span style={{ textTransform: 'lowercase' }}>V</span>A</th>
                        {/* TkA */}
                        <th>T<span style={{ textTransform: 'lowercase' }}>K</span>A</th>
                        <th>TSOG</th>
                        <th>TSATT</th>
                        <th>FO %</th>
                        <th>BLK</th>
                        <th>CHK</th>
                        <th>PIM</th>
                    </tr>
                </thead>
                <tbody>

                    <TeamRow
                        teamName={this.props.teamName}
                        events={this.props.events}
                        side='team' />

                    <TeamRow
                        teamName={this.props.opposingName}
                        events={this.props.events}
                        side='opponent' />

                </tbody>
            </table>
        )
    }
}