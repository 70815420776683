import React from 'react';
import Dispatcher from '../../dispatchers/Dispatcher';
import Players from '../../lib/Players';

export default class TimeOnIcePlayers extends React.Component {
    click(player) {
        Dispatcher.dispatch({
            type: 'toi-player-click',
            data: {
                player: player,
                goalie: this.props.goalie,
                time: this.props.time,
                position: this.props.position
            }
        });
    }

    active(playerId) {
        for (let i = 0; i < this.props.active.length; ++i) {
            if (this.props.active[i].id === playerId) {
                return 'active';
            }
        }

        return '';
    }

    render() {
        const players = this.props.players;

        Players.sort(players);

        return this.props.players.map((player, idx) => {
            // only render active players on the UI
            if (player.active) {
                return (
                    <div
                        key={idx}
                        onClick={() => this.click(player)}
                        className={`btn ${this.active(player.id)} player-line-${player.line || 'none'} ${player.faceoff_player ? 'faceoff-border' : ''}`}>
                        <span>{player.number}<br /><small>{player.name.split(' ')[0]} {player.name.split(' ')[1][0]}</small></span>
                    </div>
                );
            } else {
                return '';
            }
        });
    }
}
