import React from 'react';
import Dispatcher from '../../dispatchers/Dispatcher';

export default class EndGameModal extends React.Component {
    endGame = () => {
        Dispatcher.dispatch({ type: 'toi-end-game', data: { time: this.props.time } });
    }

    cancel = () => {
        Dispatcher.dispatch({
            type: 'toi-toggle-end-modal', 
            data: { 
                modal: 'end-game',
                open: false
             } 
        });
    }

    render() {
        return (
            <div className={`modal-overlay team ${this.props.open ? 'open' : ''}`}>
                <div className="modal-content">
                    <div className="section">
                        <h1>End Game</h1>
                        <div className="btn-row-6-stack">
                            <div className="btn" onClick={this.endGame}>
                                <span>End Game</span>
                            </div>
                            <div className="btn" onClick={this.cancel}>
                                <span>Cancel</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
