import React from 'react'
import Dispatcher from '../../dispatchers/Dispatcher'

const back = (isSyncStats) => {
    if (isSyncStats) {
        window.location.reload();
        return;
    } else {
        Dispatcher.dispatch({ type: 'switch-view', data: 'tagger' });
    }
}

export default function StatsHeader({ handlePeriodClick, activePeriod, periodsPlayed, isSyncStats }) {
    return (

        <div className="btn-row-10">

            {
                periodsPlayed.map(number => {
                    return (
                        <div key={number} className={`btn ${activePeriod === number ? 'active' : ''}`} onClick={() => handlePeriodClick(number)}>
                            <span>{number === null ? 'Game' : `P${number}`}</span>
                        </div>
                    )
                })
            }

            <div className="btn right blue" style={{ float: 'right', width: '200px' }} onClick={() => back(isSyncStats)}>
                <span>{isSyncStats ? 'Back to Game Select' : 'Back to Game'}</span>
            </div>

        </div>

    )
}