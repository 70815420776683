export default class Strings {
    static TOKEN_KEY = 'token';
    static GAMES_KEY = 'games';
    static COMPLETED_GAMES_KEY = 'completed-games';
    static TAGGER_ID = 'tagger-id'

    /**
     * Transform a string, making it "title case" i.e. user_name -> User Name
     */
    static titleize(string) {
        return string.split('_').map((section) => {
            return section.toUpperCase();
        }).join(' ');
    }
}
