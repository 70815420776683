import React from 'react';
import GoalieRow from './GoalieRow';

export default class GoalieStats extends React.Component {
    renderGoalieStatsRows() {
        return this.props.players['goalie'].map((player, idx) => {
            return (
                <GoalieRow
                    key={idx}
                    player={player}
                    events={this.props.events}
                    teamName={this.props.teamName} />
            );
        });
    }

    render() {
        return (
            <table className='goalie'>
                <thead>
                    <tr>
                        <th>Goalies</th>
                        <th>Start</th>
                        <th>Goals</th>
                        <th>Saves</th>
                        <th>TSOG</th>
                        <th>Save %</th>
                    </tr>
                </thead>
                <tbody>
                    {this.renderGoalieStatsRows()}
                </tbody>
            </table>
        );
    }
}