import React from 'react';
import Dispatcher from '../dispatchers/Dispatcher';
import Strings from '../lib/Strings';

export default class InterfaceBottom extends React.Component {
    handleParentClick(button) {
        // items that we use with the 'mini' data
        const optional = ['shot', 'miss', 'block'];

        // when we're not tracking secondary information and we're on
        // one of the above contexts we send an item click directly
        // instead of loading up another menu context
        if (!this.props.trackSecondary && optional.includes(button)) {
            this.handleItemClick(button);
        } else {
            Dispatcher.dispatch({
                type: 'bottom-menu-parent-click',
                data: {
                    menu: button,
                    time: this.props.time
                }
            });
        }
    }

    handleItemClick(item) {
        Dispatcher.dispatch({
            type: 'bottom-menu-item-click',
            data: {
                item: item,
                time: this.props.time
            }
        });
    }

    handleCancel() {
        Dispatcher.dispatch({ type: 'cancel-mini-action' });
    }

    evalClockStop() {
        Dispatcher.dispatch({ type: 'bottom-row-stop-clock' });
    }

    handleGoalClick() {
        if (this.props.bottomMenu !== 'goal') {
            this.handleParentClick('goal');
        } else {
            this.handleCancel();
        }
    }

    flag() {
        Dispatcher.dispatch({
            type: 'flag',
            data: {
                type: 'flag',
                time: this.props.time
            }
        });
    }

    endPeriod() {
        Dispatcher.dispatch({
            type: 'show-end-period-modal',
            data: true
        });
    }

    renderMenu() {
        switch(this.props.bottomMenu) {
        case 'stop':
            return (
                <div>
                    <div
                        onClick={() => this.handleCancel()}
                        className="btn active">
                        <span>Resume Clock</span>
                    </div>
                    <div
                        onClick={() => this.handleItemClick('icing')}
                        className={`btn ${this.props.bottomItem === 'icing' ? 'active' : ''}`}>
                        <span>Icing</span>
                    </div>
                    <div
                        onClick={() => this.handleItemClick('offside')}
                        className={`btn ${this.props.bottomItem === 'offside' ? 'active' : ''}`}>
                        <span>Offside</span>
                    </div>
                    <div
                        onClick={() => this.handleItemClick('other')}
                        className={`btn ${this.props.bottomItem === 'other' ? 'active' : ''}`}>
                        <span>Other</span>
                    </div>
                    <div
                        onClick={() => this.handleItemClick('penalty')}
                        className={`btn ${this.props.bottomItem === 'penalty' ? 'active' : ''}`}>
                        <span>Penalty</span>
                    </div>
                    <div
                        onClick={() => this.endPeriod()}
                        className="btn">
                        <span>End of Period</span>
                    </div>
                </div>
            );
        case 'shot':
            return (
                <div>
                    <div
                        onClick={() => this.handleCancel()}
                        className="btn active">
                        <span>Shot</span>
                    </div>
                    <div
                        className={`btn ${this.props.bottomItem === 'rebound_in_play' ? 'active' : ''}`}
                        onClick={() => this.handleItemClick('rebound_in_play')}>
                        <span>Rebound in Play</span>
                    </div>
                    <div
                        className={`btn ${this.props.bottomItem === 'deflect_in_play' ? 'active' : ''}`}
                        onClick={() => this.handleItemClick('deflect_in_play')}>
                        <span>Deflect in Play</span>
                    </div>
                    <div
                        className={`btn ${this.props.bottomItem === 'deflect_out_of_play' ? 'active' : ''}`}
                        onClick={() => this.handleItemClick('deflect_out_of_play')}>
                        <span>Deflect out of play</span>
                    </div>
                    <div
                        className={`btn ${this.props.bottomItem === 'puck_frozen' ? 'active' : ''}`}
                        onClick={() => this.handleItemClick('puck_frozen')}>
                        <span>Puck frozen</span>
                    </div>
                </div>
            );
        case 'miss':
            return (
                <div>
                    <div
                        onClick={() => this.handleCancel()}
                        className="btn active">
                        <span>Miss</span>
                    </div>
                    <div
                        className={`btn ${this.props.bottomItem === 'miss_in_play' ? 'active' : ''}`}
                        onClick={() => this.handleItemClick('miss_in_play')}>
                        <span>Miss In Play</span>
                    </div>

                    <div
                        className={`btn ${this.props.bottomItem === 'post_crossbar_in_play' ? 'active' : ''}`}
                        onClick={() => this.handleItemClick('post_crossbar_in_play')}>
                        <span>Post In Play</span>
                    </div>

                    <div
                        className={`btn ${this.props.bottomItem === 'post_crossbar_out_of_play' ? 'active' : ''}`}
                        onClick={() => this.handleItemClick('post_crossbar_out_of_play')}>
                        <span>Post Out Of Play</span>
                    </div>

                    <div
                        className={`btn ${this.props.bottomItem === 'out_of_play' ? 'active' : ''}`}
                        onClick={() => this.handleItemClick('out_of_play')}>
                        <span>Out Of Play</span>
                    </div>
                </div>
            );
        case 'block':
            return (
                <div>
                    <div
                        onClick={() => this.handleCancel()}
                        className="btn active">
                        <span>Block</span>
                    </div>
                    <div
                        className={`btn ${this.props.bottomItem === 'clean_block' ? 'active' : ''}`}
                        onClick={() => this.handleItemClick('clean_block')}>
                        <span>Clean Block</span>
                    </div>
                    <div
                        className={`btn ${this.props.bottomItem === 'deflect_in_play' ? 'active' : ''}`}
                        onClick={() => this.handleItemClick('deflect_in_play')}>
                        <span>Deflect In Play</span>
                    </div>
                    <div
                        className={`btn ${this.props.bottomItem === 'deflect_out_of_play' ? 'active' : ''}`}
                        onClick={() => this.handleItemClick('deflect_out_of_play')}>
                        <span>Deflect out of play</span>
                    </div>
                </div>
            );
        case 'mini':
            return (
                <div>
                    <div
                        onClick={() => this.handleCancel()}
                        className="btn active">
                        <span>{Strings.titleize(this.props.bottomItem)}</span>
                    </div>

                    {
                        /**
                         * Render 'miss' sub-options for the mini menu
                         */
                        this.props.bottomItem === 'miss' && (
                            <div
                                className={`btn ${this.props.lockedEvent.value === 'post' ? 'active' : ''}`}
                                onClick={() => this.handleItemClick('post')}>
                                <span>Post</span>
                            </div>
                        )
                    }

                    <div
                        className={`btn ${this.props.clockStopped ? ' active secondary' : ''}`}
                        onClick={() => this.evalClockStop()}>
                        <span>Stop Clock</span>
                    </div>
                </div>
            );
        default:
            return (
                <div>
                    <div className="btn stop" onClick={() => this.handleParentClick('stop')}>
                        <span>Stop in Play</span>
                    </div>
                    <div className="btn" onClick={() => this.handleParentClick('shot')}>
                        <span>Shot</span>
                    </div>
                    <div className="btn" onClick={() => this.handleParentClick('miss')}>
                        <span>Miss</span>
                    </div>
                    <div className="btn" onClick={() => this.handleParentClick('block')}>
                        <span>Block</span>
                    </div>
                    <div className="btn stop" onClick={() => this.flag()}>
                        <span>Flag</span>
                    </div>
                    <div
                        className={`btn ${this.props.bottomMenu === 'goal' ? 'active' : ''}`}
                        onClick={() => this.handleGoalClick()}>
                        <span>Goal</span>
                    </div>
                </div>
            );
        }
    }

    render() {
        return (
            <div className={`section tagger-bottom-buttons action-row ${(this.props.playerLock || this.props.faceoffLock || this.props.iceLock) && this.props.bottomMenu !== 'goal' ? 'inactive' : ''}`}>
                {this.renderMenu()}
            </div>
        );
    }
}
