/**
 * Player related helpers
 */
export default class Players {
    /**
     * Sort players by their jersey numbers.
     */
    static sort(players) {
        let sorted = players;

        sorted.sort((a, b) => {
            return Number.parseInt(a.number) - Number.parseInt(b.number);
        });
    }
}
