import React from 'react';
import Dispatcher from '../../dispatchers/Dispatcher';

export default class EndPeriodModal extends React.Component {
    endPeriod = () => {
        Dispatcher.dispatch({ type: 'toi-end-period', data: { time: this.props.time } });

        // ---------------------------------------------------
        // NOTE disabled Thu Mar 30 02:29:50 PM EDT 2023
        // https://switchbox.teamwork.com/app/tasks/36467779
        // ---------------------------------------------------
        // Dispatcher.dispatch({
        //     type: 'upload-in-progress-game',
        //     data: {
        //         tagger: 'toi'
        //     }
        // });
        // ---------------------------------------------------
    }

    cancel = () => {
        Dispatcher.dispatch({
            type: 'toi-toggle-end-modal', 
            data: { 
                modal: 'period',
                open: false
             } 
        });
    }

    render() {
        return (
            <div className={`modal-overlay team ${this.props.open ? 'open' : ''}`}>
                <div className="modal-content">
                    <div className="section">
                        <h1>End Period</h1>
                        <div className="btn-row-6-stack">
                            <div className="btn" onClick={this.endPeriod}>
                                <span>End Period</span>
                            </div>
                            <div className="btn" onClick={this.cancel}>
                                <span>Cancel</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
