import Penalty from './Penalty'

/**
 * Shared function that will parse a goal and return its intended time data.
 *
 * Time data needs to be based on if any penalties were cancelled and therefore the new players on ice count.
 *
 * This was all originally written in 2019 and copied into a shared file in 2023 so that the GameStore
 * could use the same logic without duplicating all this madness.
 */
export default function parseGoal(time, action) {
    /**
     * There are 2 context where we might be needing to ending out a penalty
     * based on the other team scoring. The first is the team actually scoring
     * on this tagger, and the other is that a coach with another tagger needs
     * to end the penalty that was tagged on another device. In the latter scenario,
     * the 'side' is tracked when the user clicks the X and is stored here in the
     * time store. That's why we need to either check for that or the side sent up
     * from the save-goal event itself. This is instead of passing that side all the
     * way down throughout the tagger and re-dispatching it here. Since we already
     * have the side in that modal, we can just keep it in the store here and clear it
     * once the penalty is removed (see below).
     */
    const closingPenaltySide = time.manualClosePenaltySide || action.data.side;

    // the index of the penalty we're working with
    let idx = null;

     /**
      * Whenever both teams have the same amount of players on ice (even strength)
      * the penalties never cancel per Erik. When that is the case we can go ahead
      * and just return time right away since we won't be doing anything.
      */
     if (evenStrength(time)) {
         return time;
     }

    // If we get to this point, we need to increment the PP/PK because if the goal ends the penalty
    // or it keeps going, it will count as pp/pk, and for major penalties, if the timer ends the penalty
    // with 1 goal, the 2 opportunities will get logged for the 1st goal, and then when the penalty ends.
    const penaltyCounts = {
        powerPlaysCount: time.powerPlaysCount,
        penaltyKillsCount: time.penaltyKillsCount,
    };

    // Here we need to evaluate 'closingPenaltySide' to determine which penalty counter to increment.
    if (closingPenaltySide === 'team') {
        penaltyCounts.penaltyKillsCount++;
    } else {
        penaltyCounts.powerPlaysCount++;
    }

    /**
     * Here we're getting the first penalty that is "actionable" after a goal.
     * This means the first penalty in the list that is either marked as
     * `removeOnGoal` or `removeOnGoalAfter2` and the time is less than 2.
     * We set the current index so we can work with the same penalty later.
     * We have to do this instead of the 1st penalty because if a player commits
     * a major (5 min) penalty and 1 minute later a players commits a minor (2 min)
     * penalty, if a goal is scored the *minor* penalty is released, not the major.
     * Because of that here we're skipping over major penalties since we can never
     * do anything with them in terms of cancellation after a goal.
     */
    const penalty = time.penalties[closingPenaltySide].filter((p, _idx) => {
        const penaltyTypeObject = Penalty.selectedPenaltyTypeOptions(time.penaltyType)

        // split the penalty type object's minor value for those values that have min:seconds
        const minor = penaltyTypeObject.minor.split(':')

        const min = Number.parseInt(minor[0])
        const sec = Number.parseInt(minor[1] || '00')

        // this is the variable that it is interpreted to see if the penalty on the clock is less than the
        // minor penalty value for the current penalty type configuration. if that is the case and it is
        // marked as removeOnGoalAfter2 it will remove the penalty once under the minor length.
        let penaltyLess = false

        // penalty is less than minor if the minutes of the penalty are less
        if (Number.parseInt(p.minutes) < min) {
            penaltyLess = true
        }

        // penalty is less than minor if the minutes are the same but the seconds are less than the minor seconds
        // we have to check this scenario since we have minor lengths that are are seconds ow a s well... we have to check
        // both the minutes and the seconds.
        if (Number.parseInt(p.minutes) === min && Number.parseInt(p.seconds) < sec) {
            penaltyLess = true
        }

        // if (!p.stacked && (p.removeOnGoal || (p.removeOnGoalAfter2 && Number.parseInt(p.minutes) < 2))) {
        if (!p.stacked && (p.removeOnGoal || (p.removeOnGoalAfter2 && penaltyLess))) {
            if (idx === null) { // only set index if it's null -- grabbing the first valid penalty's index
                idx = _idx;
            }
            return true;
        } else {
            return false;
        }
    })[0];

    // when we don't have a penalty just return time.
    // if (!penalty) { return { ...time }; }
    // If we do NOT have a penalty to "operate on", but we DO have a penalty in the list (should just be for major, do not remove on goal penalties),
    // we still want to increment the penalty interator and return the rest of the time store as-is.
    // Whenever the penalty ends by time, it will also get increased by 1, giving us the functionality we need of major penalties being goals + 1.
    if (!penalty) {
        if (time.penalties[closingPenaltySide].length > 0) {
            return {
                ...time,
                ...penaltyCounts
            };
        } else {
            return time;
        }
    }

    // callback that will reset the penalty to 2 minutes
    const resetCallback = () => {

        const penaltyTypeObject = Penalty.selectedPenaltyTypeOptions(time.penaltyType)

        // split the penalty type object's minor value for those values that have min:seconds
        const minor = penaltyTypeObject.minor.split(':')

        return time.penalties[closingPenaltySide].map((p, _idx) => {
            if (_idx === idx) {
                // we have to use the configuration's minor minutes:seconds values... do minor || 00 in case of no seconds
                return { ...penalty, minutes: `0${minor[0]}`, seconds: minor[1] || '00' };
            } else {
                return p;
            }
        });
    };

    // callback to remove the penalty from the timer
    const removeCallback = () => {
        let ret = time.penalties[closingPenaltySide].filter((p, _idx) => { return _idx !== idx; });
        removeStacked(ret);
        return ret;
    };

    // callback that will return time with penalties based on the `cb` arg callback.
    const returnCallback = (cb) => {
        const sidePenalties = cb();

        let returnEval = {};

        if (time.format !== 3) {
            returnEval = evalPlayersOnIce(closingPenaltySide, time, sidePenalties);
        } else {
            const evalSide = closingPenaltySide === 'team' ? 'opponent' : 'team';
            returnEval = eval3v3PlayersOnIce(evalSide, time, sidePenalties);
        }

        return {
            ...time,
            ...returnEval,
            ...penaltyCounts,
            manualClosePenaltySide: null,
            penalties: {
                ...time.penalties,
                [closingPenaltySide]: sidePenalties
            }
        };
    };

    if (penalty.removeOnGoal) {
        // The penalty is marked to be removed on goal
        const pminutes = Number.parseInt(penalty.minutes)
        const pseconds = Number.parseInt(penalty.seconds)
        const pconfig  = Penalty.selectedPenaltyTypeOptions(time.penaltyType)
        const cminor   = pconfig.minor.split(':')
        const minorMin = Number.parseInt(cminor[0])
        const minorSec = Number.parseInt(cminor[1]) || 0

        /**
         * This logic used to be:
         *      if (Number.parseInt(penalty.minutes) > 2) {
         * However, now everything is dynamic. We have to grab the penalty minutes and seconds
         * and see if they are essentially more than the dynamic penalty configuration minor minutes and seconds.
         *
         * We check if the penalty minutes is more (automatic reset back to the minor), or that they are the same
         * but the seconds are higher (still higher so we still reset).
         */
        if (pminutes > minorMin || (pminutes === minorMin && pseconds > minorSec)) {
            // when the penalty minutes are 2+ we just reset to 2 minutes
            return returnCallback(resetCallback);
        } else {
            // here the penalty is marked to remove on goal and there's less than 2 mins.
            return returnCallback(removeCallback);
        }
    } else if (penalty.removeOnGoalAfter2 && Number.parseInt(penalty.minutes) < 2) {
        // here the penalty is NOT marked to remove on goal but it IS marked
        // to remove on goal once the penalty timer is less than 2 minutes.
        return returnCallback(removeCallback);
    } else {
        // when a don't remove on goal penalty is found and a goal is scored, we still want to increment penalty counts.
        return {
            ...time,
            ...penaltyCounts
        };
    }
}

function evenStrength(state) {
    return state.numberOfPlayersOnIce.team === state.numberOfPlayersOnIce.opponent;
}

function removeStacked(penalties) {
    let found = false;

    for (let i = 0; i < penalties.length; ++i) {
        if (found) {
            continue;
        }

        if (penalties[i].stacked) {
            penalties[i].stacked = false;
            found = true;
        }
    }
}

function eval3v3PlayersOnIce(side, state, penalties) {
    const numberOfPenalties = state.format + penalties.length;
    return {
        numberOfPlayersOnIce: {
            ...state.numberOfPlayersOnIce,
            [side]: numberOfPenalties > 4 ? 5 : numberOfPenalties
        }
    };
}

function evalPlayersOnIce(side, state, penalties) {
    const numberOfPenalties = state.format - penalties.length;
    return {
        numberOfPlayersOnIce: {
            ...state.numberOfPlayersOnIce,
            [side]: numberOfPenalties < 3 ? 3 : numberOfPenalties
        }
    };
}
