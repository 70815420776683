import React from 'react';
import Dispatcher from '../dispatchers/Dispatcher';

export default class BattleOutcomeModal extends React.Component {
    save(outcome) {
        Dispatcher.dispatch({
            type: 'battle-outcome-modal-click',
            data: {
                outcome: outcome,
                time: this.props.time
            }
        });
    }

    render() {
        return (
            <div className={`modal-overlay team ${this.props.open ? 'open' : ''}`}>
                <div className="modal-content">
                    <div className="section">
                        <h1>Select Outcome</h1>
                        <div className="btn-row-6-stack">
                            <div className="btn" onClick={() => this.save('won')}>
                                <span>WON</span>
                            </div>
                            <div className="btn" onClick={() => this.save('lost')}>
                                <span>LOST</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
