import React from 'react';
import Dispatcher from '../dispatchers/Dispatcher';
import Penalty from '../lib/Penalty';
import Players from '../lib/Players.js';
import ModalTimeButtons from './ModalTimeButtons';

export default class PenaltyModal extends React.Component {
    constructor(props) {
        super(props);

        this.state = this.initialState();
    }

    initialState() {
        return {
            team:           this.props.teamName,
            infraction:     null,
            length:         null,
            player:         null,
            playerNumber:   null,
            servedBy:       null,
            servedByNumber: null,
            activeSide:     'committed',
            offsetting:     false,
            bench:          false,
            firstFilled:    false,
            zone:           null
        };
    }

    close() {
        Dispatcher.dispatch({ type: 'close-penalty-modal' });
        this.setState(this.initialState());
    }

    handleSave(addAnotherPenalty) {
        try {
            this.handlePenaltyModalSave(addAnotherPenalty || false);
        } catch (err) {
            // for now just return false since failing here typically means they haven't
            // filled out enough information in the modal for us to close safely.
            console.warn(err);
            return false;
        }
    }

    handlePenaltyModalSave(addAnotherPenalty) {
        let length = null;
        let total = null;

        // penalty shot has no length that we know of
        if ((this.state.length !== 'Penalty Shot') && (this.state.length !== 'Game') && (this.state.length !== 'Match')) {
            // get the length of the penalty i.e. 5+10
            length = this.state.length.split(' ')[0];

            /**
             * Some of the dynamic penalty options have :30 values e.g. 1:30 minutes... doing eval
             * on that will break, so for now we just do a manual replace for :30 -> .5 so the eval math works.
             */
            const lengthWithParsedSeconds = length.replace(/:30/g, '.5')

            // the amount of time for statistics is the total
            // penalty time i.e. 5+10=15 minute penalty.
            // total = eval(length);
            // eslint-disable-next-line
            total = eval(lengthWithParsedSeconds);
        }

        if (this.state.length === 'Game') {
            const type = Penalty.selectedPenaltyTypeOptions(this.props.penaltyType)

            // total = 10;
            // length = '10';

            total = Number.parseInt(type.misconduct)
            length = type.misconduct
        }

        /**
         * When a `Match` penalty is entered, the `player` gets a 10 minute penalty and the `servedBy` only spends 5
         * in the box. We have it hooked up where the `total` attribute is the actual length and what will go into the data, but the
         * `length` attribute is mapped to `penaltyType` which is what is used by the penalty board. This way we can have
         * differing lengths between the report data and the scoreboard.
         */
        if (this.state.length === 'Match') {
            const type = Penalty.selectedPenaltyTypeOptions(this.props.penaltyType)

            // total = 10;
            // length = '5';

            total = Number.parseInt(type.misconduct)
            length = type.major
        }

        /**
         * If it is a penalty shot drawn by us and they didn't select a player, pop up an alert
         */
        if (this.state.length === 'Penalty Shot' && this.props.teamName !== this.state.team && !this.state.player) {
            window.alert('You must select the COMMITTED AGAINST player before continuing.');
            return;
        }

        /**
         * For 'player' and 'playerNumber' fields, we have to check first is our team is the committing team and if
         * there is no player set. We had a request to default to '?' for these types of penalties to assist in
         * the filtering process on the admin side. Basically, if our team committed the penalty and the person
         * tagging never set a player, we want to default to '?'. This could also have been achieved by updating
         * state defaults but this was a simpler fix.
         */
        Dispatcher.dispatch({
            type: 'save-penalty',
            data: {
                ...this.state,
                length: total,                                                               // used for stats
                penaltyType: this.state.length === 'Penalty Shot' ? 'Penalty Shot' : length, // used for timer
                time: this.props.time,
                side: this.props.teamName === this.state.team ? 'team' : 'opponent',
                penaltyShot: this.state.length === 'Penalty Shot',
                player: (this.state.player == null && (this.state.team === this.props.teamName)) ? "?" : this.state.player,
                playerNumber: (this.state.player == null && (this.state.team === this.props.teamName)) ? "?" : this.state.playerNumber,
                addAnotherPenalty: addAnotherPenalty
            }
        });

        if (!this.state.offsetting || this.state.firstFilled) {
            // this will reset the state of the penalty modal, for if it's closed or if they click save and next
            // in order to add another penalty... nothing else needs to be done since after each penalty is saved
            // we are already resetting the state (except for offsetting penalties)
            this.setState(this.initialState());
        } else {
            this.setState({
                team:           this.state.team === this.props.teamName ? this.props.opposingName : this.props.teamName,
                player:         null,
                playerNumber:   null,
                servedBy:       null,
                servedByNumber: null,
                infraction:     null,
                activeSide:     'committed',
                firstFilled:    true,
                zone:           this.flipZones()
            });
        }

        // Scroll to the top of the modal. This is passed as a prop instead of in state because we send all of state
        // to the dispatch reducer and things will blow up when you send a ref in JSON. instead of manually hacking
        // the ref out of the payload, it's a lot easier to just pass one as a prop here.
        this.props.modalRef.current.scrollTo(0, 0);
    }

    /**
     * Reverse the zone in the case of an offsetting penalty, since the perspective of the penalty flips when
     * it shifts to the other team.
     */
    flipZones() {
        switch (this.state.zone) {
            // for null and neutral, don't do anything -- return state as-is.
            case null:
            case 'neutral':
                return this.state.zone;
            case 'defensive':
                return 'offensive';
            default:
                return 'defensive';
        }
    }

    setItem(section, i) {
        if (this.props.offsettingPenalty && section !== 'infraction') { return; }

        // allow user to deselect option by tapping it again -- so if it's the same, set to null, otherwise set the attribute
        // this only is used by length, infraction, and zone.
        this.setState({ [section]: this.state[section] === i ? null : i });
    }

    setTeam(team) {
        if (this.props.offsettingPenalty) { return; }

        const opponent = this.props.opposingName === team;

        this.setState({
            team: team,
            servedBy: opponent ? null : this.state.servedBy,
            servedByNumber: opponent ? null : this.state.servedByNumber,
            activeSide: opponent ? 'committed' : this.state.activeSide
        });
    }

    setPlayer(player) {
        const target = this.state.activeSide === 'committed'  ?
              { player: 'player', number: 'playerNumber' }    :
              { player: 'servedBy', number: 'servedByNumber' };

        if (this.state[target.player] !== player.id) {
            this.setState({
                [target.player]: player.id,
                [target.number]: player.number
            });
        } else {
            this.setState({
                [target.player]: null,
                [target.number]: null
            });
        }
    }

    setServedBy(player) {
        this.setState({
            servedBy: player.id,
            servedByNumber: player.number
        });
    }

    toggleCommittedServed() {
        // do not toggle if we are on the opposing team committed
        if (this.state.team === this.props.opposingName && this.state.activeSide === 'committed') {
            return;
        }

        // do not toggle if we have a bench penalty -- can only stay on served by
        if (this.state.bench) {
            return;
        }

        this.setState({
            activeSide: this.state.activeSide === 'committed' ? 'served' : 'committed'
        });
    }

    infractions() {
        return [
            'Chrg/Brding', 'Checking', 'Checking from Behind', 'Cross Checking', 'Delay of Game',
            'Elbowing', 'Fighting', 'Goalie Interference', 'Head Contact', 'High Sticking', 'Holding',
            'Hooking', 'Interference', 'Misconduct', 'Roughing', 'Slashing', 'Too Many Men', 'Tripping',
            'Unsports. Conduct', 'Other'
        ];
    }

    types() {
        // return [
        //     '2 min', '2+2 min', '5+10 min', '5 min', '2+5 min', '10 min', '2+10 min', 'Game', 'Penalty Shot', 'Match'
        // ];

        const penaltyType = Penalty.selectedPenaltyTypeOptions(this.props.penaltyType)

        return penaltyType.buttons.map(b => `${b.label} MIN`)                // use the label attribute off the button configurations
                                  .concat(`${penaltyType.misconduct} MIN`)   // we also have to add the misconduct (10 min) option
                                  .concat(['Game', 'Penalty Shot', 'Match']) // we also need the hard coded text values that are separate
    }

    renderTypes() {
        return this.types().map((t) => {
            return (
                <div
                    key={t}
                    onClick={() => this.setItem('length', t)}
                    className={`btn ${this.state.length === t ? 'active' : ''}`}>
                    {/* <span>{this.renderTypeValue(t)}</span> */}
                    <span>{t}</span>
                </div>
            );
        });
    }

    renderTypeValue(value) {
        switch(value) {
        case '2+5 min':
            return '5+2 min';
        default:
            return value;
        }
    }

    renderInfractions() {
        return this.infractions().map((i) => {
            return (
                <div
                    key={i}
                    onClick={() => this.setItem('infraction', i)}
                    className={`btn ${this.state.infraction === i ? 'active' : ''}`}>
                    <span>{i}</span>
                </div>
            );
        });
    }

    playerActiveClass(player) {
        if (this.state.player === player.id) {
            return 'active';
        }

        if (this.state.servedBy === player.id) {
            return 'active secondary';
        }

        return '';
    }

    renderPlayers() {
        let players = [];

        const penaltyNumbers = this.props.time.penalties.team.map((p) => { return p.player; });

        ['forward', 'defense', 'goalie'].forEach((key) => {
            this.props.players[key].forEach((p) => {
                if (p.number !== '?' && p.active && !penaltyNumbers.includes(p.number)) {
                    players.push(p);
                }
            });
        });

        Players.sort(players);

        return players.map((p, x) => {
            return (
                <div
                    key={x}
                    className={`btn ${this.playerActiveClass(p)}`}
                    onClick={() => this.setPlayer(p)}>
                    <span>{p.number}</span>
                </div>
            );
        });
    }

    playerHeader() {
        if (this.state.team === this.props.opposingName) {
            return 'committed against';
        } else {
            if (this.state.activeSide === 'served') {
                return 'served by';
            } else {
                return 'offending player';
            }
        }
    }

    // toggle offsetting mode -- this is what is evaluated when the user hits save
    toggleOffsetting = () => {
        this.setState({ offsetting: !this.state.offsetting });
    }

    /**
     * toggle bench penalty mode. when there is a bench penalty the user
     * can only select the served by option and the player is null.
     * we do not have to check whether or not it's getting turned on or
     * off since the on mode wipes out any data. toggling it back off would
     * mean it was turned on and the data was removed in the first place.
     */
    toggleBench = () => {
        this.setState({
            bench:      !this.state.bench,
            activeSide: 'served',
            player:     null
        });
    }

    renderZones = () => {
        return ['defensive', 'neutral', 'offensive'].map((zone, key) => {
            return (
                <div
                    key={key}
                    onClick={() => this.setItem('zone', zone)}
                    className={`btn ${this.state.zone === zone ? 'active' : ''}`}>
                    <span>{zone.toUpperCase()}</span>
                </div>
            );
        });
    }

    render() {
        return (
            <div className={`modal-overlay pen-modal ${this.props.open ? 'open' : ''}`}>
                {/* The ref for the modal has to be here since that is what has the overflow styles on it, not the parent */}
                <div className="modal-content" ref={this.props.modalRef} style={{ top: '20px' }}>

                    <ModalTimeButtons time={this.props.time} eventCount={this.props.eventCount} />

                    <div className="section">
                        <div className="inner">
                            <div className="label">Offending Team</div>
                            <div className="btn-row-3">
                                <div
                                    className={`btn ${this.state.team === this.props.teamName ? 'active' : ''}`}
                                    onClick={() => this.setTeam(this.props.teamName)}>
                                    <span>{this.props.teamName}</span>
                                </div>
                                <div
                                    className={`btn ${this.state.offsetting ? 'active secondary' : ''}`}
                                    onClick={this.toggleOffsetting}>
                                    <span>Offsetting Penalties</span>
                                </div>
                                <div
                                    className={`btn ${this.state.team === this.props.opposingName ? 'active' : ''}`}
                                    onClick={() => this.setTeam(this.props.opposingName)}>
                                    <span>{this.props.opposingName}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="section">
                        <div className="inner">
                            <div className="btn-row-2 penalty-toggles">
                                <div
                                    onClick={() => this.toggleCommittedServed()}
                                    className={`btn ${this.state.activeSide === 'committed' ? 'active' : ''}`}>
                                    <span>Committed By (Team)</span>
                                </div>
                                {
                                    (this.state.team !== this.props.opposingName) && (

                                        <div
                                            onClick={() => this.toggleCommittedServed()}
                                            className={`btn ${this.state.activeSide === 'served' ? 'active secondary' : ''}`}>
                                            <span>Served By (Player)</span>
                                        </div>

                                    )
                                }
                            </div>
                        </div>
                    </div>
                    <div className="section">
                        <div className="inner">
                            <div className="label">{this.playerHeader()}</div>
                            <div className="btn-row-13 btn-multi-line player-list">
                                {this.renderPlayers()}
                                <div
                                    className={`btn ${this.playerActiveClass({ id: '?' })}`}
                                    onClick={() => this.setPlayer({ id: '?', number: '?' })}>
                                    <span>?</span>
                                </div>
                                <div
                                    className={`btn btn-bench ${this.state.bench ? 'active secondary' : ''}`}
                                    onClick={this.toggleBench}>
                                    <span>BENCH</span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="section">
                        <div className="inner">
                            <div className="label">Infraction</div>
                            <div className="btn-row-5 btn-multi-line">
                                {this.renderInfractions()}
                            </div>
                        </div>
                    </div>
                    <div className="section">
                        <div className="inner">
                            <div className="label">Type</div>
                            <div className="btn-row-5 btn-multi-line">
                                {this.renderTypes()}
                            </div>
                        </div>
                    </div>
                    <div className="section">
                        <div className="inner">
                            <div className="label">Zone Penalty Committed</div>
                            <div className="btn-row-3 btn-multi-line">
                                {this.renderZones()}
                            </div>
                        </div>
                    </div>
                    <div className="section">
                        <div className="btn-group-3 center">
                            <div
                                className="btn save"
                                onClick={() => this.handleSave(true)}>
                                Save &amp; Add
                            </div>

                            <div className="btn save red" onClick={() => this.close()}>Exit Don't Save</div>

                            <div
                                className="btn save"
                                onClick={() => this.handleSave()}>
                                Save &amp; Exit
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
