import React from 'react';

import Faceoff from './Faceoff';
import Shot from './Shot';
import Block from './Block';
import Miss from './Miss';
import Goal from './Goal';
import ZoneEntry from './ZoneEntry';
import ZoneExit from './ZoneExit';
import Penalty from './Penalty';

export default class EventCard extends React.Component {
    constructor(props) {
        super(props)

        this.state = {
            event: this.props.event,
        }
    }

    updateValue = (event, fieldName) => {
        this.props.updateEventData(this.props.index, fieldName, event.target.value)
    }

    renderEventForm = () => {
        switch (this.props.event.type) {
            case 'faceoff':
                return <Faceoff event={this.props.event} game={this.props.game} updateEventData={this.props.updateEventData} index={this.props.index}/>;
            case 'shot':
                return <Shot event={this.props.event} game={this.props.game} updateEventData={this.props.updateEventData} index={this.props.index}/>;
            case 'block':
                return <Block event={this.props.event} game={this.props.game} updateEventData={this.props.updateEventData} index={this.props.index}/>;
            case 'miss':
                return <Miss event={this.props.event} game={this.props.game} updateEventData={this.props.updateEventData} index={this.props.index}/>;
            case 'goal':
                return <Goal event={this.props.event} game={this.props.game} updateEventData={this.props.updateEventData} index={this.props.index} renderPlayers={this.renderPlayers} playersOnIceOptions={this.playersOnIceOptions()} />;
            case 'zone_entry':
                return <ZoneEntry event={this.props.event} game={this.props.game} updateEventData={this.props.updateEventData} index={this.props.index}/>;
            case 'zone_exit':
                return <ZoneExit event={this.props.event} game={this.props.game} updateEventData={this.props.updateEventData} index={this.props.index} />;
            case 'penalty':
                return <Penalty event={this.props.event} game={this.props.game} updateEventData={this.props.updateEventData} index={this.props.index} />;
            default:
                // event with no extra inputs
        }
    }

    playersOnIceOptions = () => {
        let poi = [];
        this.props.game.team.players['defense'].forEach((player) => poi.push({ value: player.id, label: `D ${player.number} ${player.name}` }));
        this.props.game.team.players['forward'].forEach((player) => poi.push({ value: player.id, label: `F ${player.number} ${player.name}` }));
        this.props.game.team.players['goalie'].forEach((player) => poi.push({ value: player.id, label: `G ${player.number} ${player.name}` }));
        // this.setState({ playersOnIce: poi })
        return poi;
    }

    renderPlayers = () => {
        let keyIndex = 0;
        let defense = this.props.game.team.players['defense'].map((player, i) => {
            keyIndex++;
            return <option key={keyIndex} value={player.id}>D #{player.number} {player.name}</option>
        })

        let forward = this.props.game.team.players['forward'].map((player, i) => {
            keyIndex++;
            return <option key={keyIndex} value={player.id}>F #{player.number} {player.name}</option>
        })

        let goalie = this.props.game.team.players['goalie'].map((player, i) => {
            keyIndex++;
            return <option key={keyIndex} value={player.id}>G #{player.number} {player.name}</option>
        })

        return [...defense, ...forward, ...goalie];
    }

    titleCase = (s) => {
        return s.replace (/^[-_]*(.)/, (_, c) => c.toUpperCase())       // Initial char (after -/_)
        .replace (/[-_]+(.)/g, (_, c) => ' ' + c.toUpperCase())
    }

    shouldRenderPlayerInput = () => {
        const ignoredEvents = ['generic', 'offside', 'icing', 'flag', 'stop'];
        if(!ignoredEvents.includes(this.props.event.type)) {
            return true
        } else {
            return false;
        }
    }

    toggleDeletionState = () => {
        if (this.props.willDelete) {
            this.props.removeEventToDelete(this.props.index)
        } else {
            this.props.addEventToDelete(this.props.index)
        }
    }
    
    render() {
        return (
            <div className="event-card">
                <div className="period-display">Period {this.state.event.period}</div>
                <div className="event-type-display">
                    {this.titleCase(this.state.event.type) === 'Turnover' ? 'Giveaway' : this.titleCase(this.state.event.type)}
                </div>
                <div className="time-display">{this.state.event.time}</div>
                {/* <p>{this.titleCase(this.state.event.type)} Period: {this.state.event.period} Time: {this.state.event.time}</p> */}

                <br />
                <div style={{ textAlign: "center" }}>
                    <div
                        onClick={() => this.toggleDeletionState()}
                        className={`btn delete-btn ${this.props.willDelete ? 'active' : ''}`}>
                        <span>{this.props.willDelete ? 'Will be deleted on save' : 'Delete Event'}</span>
                    </div>
                </div>
                
                <div className="event-col">
                    <label>Team</label>
                    <select name="team" className="form-control event-input" value={this.props.event.team} onChange={(e) => this.updateValue(e, 'team')}>
                        <option value={this.props.game.team.name}>{this.props.game.team.name}</option>
                        <option value={this.props.game.opponent_team_name}>{this.props.game.opponent_team_name}</option>
                    </select>
                </div>
                
                {this.shouldRenderPlayerInput() &&
                    <div className="event-col">
                        <label>Player</label>
                        <select name="player" className="form-control event-input" value={this.props.event.player} onChange={(e) => this.updateValue(e, 'player')}>
                            <option value=''></option>
                            {this.renderPlayers()}
                        </select>
                    </div>
                }
                
                {this.renderEventForm()}
            </div>
        );
    }
}
