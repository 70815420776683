import React from 'react';
import Client from '../lib/Client';

export default class Login extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            email: '',
            password: ''
        };
    }

    handleChange(e) {
        this.setState({ [e.target.name]: e.target.value });
    }

    handleSave() {
        Client.login(this.state);
    }

    renderButton() {
        if (!this.props.user.loggingIn) {
            return (
                <input
                    onClick={() => this.handleSave()}
                    type="submit"
                    value="Log In"
                    className="btn save" />
            );
        } else {
            return (
                <p>Logging in...</p>
            );
        }

    }

    renderError() {
        if (this.props.user.error) {
            return (
                <div className="alert">
                    <p>{this.props.user.error}</p>
                </div>
            );
        } else {
            return '';
        }
    }

    render() {
        return (
            <div id="tagger">
                <div className="login">
                    <div className="logo">
                        <img src="logo.svg" alt=""/>
                    </div>
                    <div className="login-form clearfix">
                        <div className="inner">
                            {this.renderError()}
                            <div className="form-group">
                                <label htmlFor="username">Email</label>
                                <input
                                    onChange={(e) => this.handleChange(e)}
                                    type="text"
                                    name="email"
                                    placeholder="email"
                                    className="form-control uname" />
                            </div>
                            <div className="form-group">
                                <label htmlFor="password">Password</label>
                                <input
                                    onChange={(e) => this.handleChange(e)}
                                    type="password"
                                    name="password"
                                    placeholder="password"
                                    className="form-control password" />
                            </div>
                            <div className="center">
                                {this.renderButton()}
                            </div>
                        </div>
                    </div>
                    <div className="center">
                        <p>App requires a team and roster to be created at <a style={{ textDecoration: 'none', color: '#2993C3' }} href="https://athleticperformanceinsight.com" target="_blank">athleticperformanceinsight.com</a></p>
                        <br />
                        <p>Questions? <a style={{ color: '#2993C3' }} href="https://www.athleticperformanceinsight.com/contact-us" target="_blank">Contact Us</a></p>
                    </div>
                </div>
            </div>

        );
    }
}
