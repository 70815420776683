import React from 'react';
import Dispatcher from '../dispatchers/Dispatcher';
import Toggle from 'react-toggle';
import 'react-toggle/style.css';
import AutoPenaltyModal from './AutoPenaltyModal';

export default class TaggerMenu extends React.Component {
    toggle() {
        Dispatcher.dispatch({ type: 'toggle-sidebar' });
    }

    /**
     * Really not happy with this but this is a consequence of the client
     * requesting multiple modals for ending/exiting games. The different
     * taggers don't share the same data/stores so we can't reuse the same
     * modals without a pretty significant rework. As it stands, each tagger
     * has to handle their own logic for modals to end or exit games
     * @param {*} modalType 
     */
    endOrExit(modalType) {
        if (this.props.tagger === 'toi') {
            Dispatcher.dispatch({
                type: 'toi-toggle-end-modal', 
                data: { 
                    modal: modalType,
                    open: true
                 } 
            });

        } else if (this.props.tagger === 'event') {
            Dispatcher.dispatch({
                type: 'show-end-exit-game-modal',
                data: modalType
            });

        } else {
            Dispatcher.dispatch({
                type: 'video-toggle-end-modal', 
                data: { 
                    modal: modalType,
                    open: true
                 } 
            });
        }
    }

    running() {
        Dispatcher.dispatch({ type: 'toggle-running-clock' });
    }

    paused() {
        Dispatcher.dispatch({ type: 'toggle-tagger-paused' });
    }

    renderRunningClock() {
        if (!this.props.hideRunningClock) {
            return (
                <div className="item">
                    Running Clock
                    &emsp;
                    <Toggle
                        checked={this.props.clockRunning}
                        onChange={() => this.running()} />
                </div>
            );
        } else {
            return '';
        }
    }

    renderPauseTagger() {
        return (
            <div className="item">
                Pause Tagger
                &emsp;
                <Toggle
                    checked={this.props.taggerPaused}
                    onChange={() => this.paused()} />
            </div>
        )
    }

    stats() {
        Dispatcher.dispatch({ type: 'view-game-stats' });
    }

    editor() {
        Dispatcher.dispatch({ type: 'view-event-editor' });
    }

    renderUpload() {
        if (!this.props.tagger && !this.props.showUpload) {
            return '';
        } else if (this.props.uploading) {
            return (
                <div className="item menu-upload flashing">
                    <i>Uploading...</i>
                </div>
            );
        } else {
            return (
                <div className="item" onClick={() => this.upload()}>
                    In Game Upload
                </div>
            );
        }
    }

    upload() {
        Dispatcher.dispatch({
            type: 'upload-in-progress-game',
            data: {
                // send both the tagger type and any attributes from the time store we need,
                // in this case we're sending the pp/pk stuff.
                tagger: this.props.tagger,
                time: {
                    powerPlaysCount: this.props.time.powerPlaysCount,
                    penaltyKillsCount: this.props.time.penaltyKillsCount,
                }
            }
        });
    }

    autoPenalty() {
        Dispatcher.dispatch({ type: 'open-auto-penalty-modal' })
    }

    render() {
        return (
            <div className={`sidebar ${this.props.open ? '' : 'closed'}`}>
                <div className="exit btn save small right" onClick={() => this.toggle()}>
                    Back to Game
                </div>

                <div className="items">
                    {
                        !this.props.hideEditor && (
                            <div className="item" onClick={() => this.editor()}>
                                Edit Game Events
                            </div>
                        )
                    }

                    <div className="item"></div>

                    {this.renderUpload()}

                    <div className="item"></div>

                    {
                        !this.props.hideStats && (
                            <div className="item" onClick={() => this.stats()}>
                                View Game Stats
                            </div>
                        )
                    }

                    <div className="item"></div>

                    {this.renderRunningClock()}

                    <div className="item"></div>

                    {this.props.tagger === 'event' && this.renderPauseTagger()}

                    {
                        this.props.showAutoPenalty && (
                            <>
                                <div className="item"></div>

                                <div className="item" onClick={() => this.autoPenalty()}>
                                    Add Penalty
                                </div>

                                <AutoPenaltyModal
                                    open={this.props.modal === 'auto-penalty'}
                                    teamName={this.props.teamName}
                                    opposingName={this.props.opposingName}
                                    time={this.props.time} />
                            </>
                        )
                    }

                    <div className="item"></div>

                    <div className="item" onClick={() => this.endOrExit('end-game')}>
                        End Game (Save Data)
                    </div>

                    <div className="item" onClick={() => this.endOrExit('exit-game')}>
                        Exit Game (Delete Data)
                    </div>

                </div>
            </div>
        );
    }
}
