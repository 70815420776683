import React from 'react';

export default class SkaterRow extends React.Component {
    /**
     * Calculate the `player`'s +- stat. The player stars at 0 and for each goal
     * they were on the ice for, we check whether or not we scored the goal or not.
     * This only impacts the player for goals they were on ice for. When we scored
     * the goal, the player gets +1, otherwise -1 to their total stat.
     */
    plusMinus() {
        // return value of +-
        // This starts at 0 and goes + or - based on each goal the player was on ice for.
        let total = 0;

        this.props.events.filter((event) => {
            /**
             * Shouldn't be counting power play goals for or against.  The formal definition of +/- is "A player is
             * awarded a "plus" each time he is on the ice when his Club scores an even-strength or shorthanded goal.
             * He receives a "minus" if he is on the ice for an even-strength or shorthanded goal scored by the opposing Club.
             */
            return event.type === 'goal' && event.value !== 'power_play';
        }).forEach((event) => {
            if (event.players_on_ice && event.players_on_ice.includes(this.props.player.id)) {
                // Check to see if we scored or not.
                if (event.team === this.props.teamName) {
                    ++total;
                } else {
                    --total;
                }
            }
        });

        return total;
    }

    /**
     * Calculate the player's faceoff win percentage. If the player has not
     * faced any faceoffs it will display --, otherwise 0% means they DID
     * have faceoffs and didn't win any of them.
     */
    faceoffWinPercentage() {
        // Total faceoffs (event objects) for `player`.
        const faceoffs = this.props.events.filter((event) => {
            return event.type === 'faceoff' && event.player === this.props.player.id && event.result !== 'kicked_out';
        });

        // Return -- if there is no faceoff data for this user.
        if (!faceoffs.length) {
            return '--';
        }

        // Faceoff win event objects for won-faceoffs.
        const wins = faceoffs.filter((faceoff) => {
            return faceoff.result.match(/win/);
        });

        // Wins / Total faceoffs * 100 to give percentage.
        return `${Math.round(((wins.length / faceoffs.length) * 100))}%`;
    }

    /**
     * Calculate the number of penalty minutes the `player` has served.
     */
    penaltyMinutes() {
        // Gather the total penalties where the `player` is the penalty player.
        // then we map those penalties down to the individual minutes.
        const penalties = this.props.events.filter((event) => {
            // type has to be penalty, `player` has to match, and it has to be on our side (we committed).
            return event.type === 'penalty' && event.player === this.props.player.id && event.side === 'team';
        }).map((penalty) => {
            // map array down to the individual minutes scored
            return penalty.length;
        });

        // If we don't have any penalties for this player we can't reduce.
        // When that's the case default to 0 mins.
        if (penalties.length) {
            // total the array of minutes
            return penalties.reduce((a, b) => {
                return a + b;
            });
        } else {
            return 0;
        }
    }

    render() {
        const nameSplit = this.props.player.name.split(' ');

        return (
            <tr>
                {/* player: # - Last Name First Initial */}
                <td className='left'>{`${this.props.player.number} ${nameSplit[nameSplit.length - 1]}`}</td>
                {/* position */}
                <td>{this.props.player.position[0]}</td>
                {/* goals */}
                <td>
                    {
                        this.props.events.filter((event) => {
                            return event.type === 'goal' && event.player === this.props.player.id;
                        }).length
                    }
                </td>
                {/* assists */}
                <td>
                    {
                        this.props.events.filter((event) => {
                            return event.type === 'goal' && (event.assist_1 === this.props.player.id || event.assist_2 === this.props.player.id);
                        }).length
                    }
                </td>
                {/* points */}
                <td>
                    {
                        this.props.events.filter((event) => {
                            // eslint-disable-next-line
                            return event.type === 'goal' && (event.player === this.props.player.id || event.assist_1 === this.props.player.id || event.assist_2 === this.props.player.id);
                        }).length
                    }
                </td>
                {/* +- */}
                <td>{this.plusMinus()}</td>
                {/* tsog: shots, goals */}
                <td>
                    {
                        this.props.events.filter((event) => {
                            return (event.type === 'shot' || event.type === 'goal') && event.player === this.props.player.id;
                        }).length
                    }
                </td>
                {/* tsatt: shots, goals, blocks, misses */}
                <td>
                    {
                        this.props.events.filter((event) => {
                            return (event.type === 'shot' || event.type === 'goal' || event.type === 'miss' || (event.type === 'block' && event.team === this.props.teamName)) && event.player === this.props.player.id;
                        }).length
                    }
                </td>
                {/* total */}
                <td>
                    {
                        this.props.events.filter((event) => {
                            return event.type === 'faceoff' && event.player === this.props.player.id && event.result !== 'kicked_out';
                        }).length
                    }
                </td>
                {/* faceoff win percentage */}
                <td>{this.faceoffWinPercentage()}</td>

                {/* BLK - Blocks on our side (defensive block) */}
                <td>
                    {
                        this.props.events.filter((event) => {
                            return event.type === 'block' && event.team !== this.props.teamName && event.player === this.props.player.id;
                        }).length
                    }
                </td>

                {/* CHK - checks */}
                <td>
                    {
                        this.props.events.filter((event) => {
                            return event.type === 'check' && event.player === this.props.player.id;
                        }).length
                    }
                </td>

                {/* penalties in minutes */}
                <td>{this.penaltyMinutes()}</td>
            </tr>
        );
    }
}