import React from 'react';
import Dispatcher from '../dispatchers/Dispatcher';
import Players from '../lib/Players';

export default class InterfacePlayers extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            /**
             * Did we just hold down the goalie change long press? If so a click
             * event is going to register on the same button, we use this to check
             * if it was an actual check or a release from a change goalie press-hold.
             */
            goalieHold: false
        };
    }

    handleClick(player) {
        /**
         * When the user does a long press to change the goalie, it will still fire
         * off a click event when the user releases. When the change goalie Dispatch
         * gets fired off, we set state's `goalieHold` to `true`. When that is true
         * and we receive a click event we want to set that goalieHold back to false
         * and simply return, otherwise the goalie will be selected as the player
         * in whatever event the user is on.
         */
        if (this.state.goalieHold) {
            this.setState({ goalieHold: false });
            return;
        }

        Dispatcher.dispatch({
            type: 'player-button-click',
            data: {
                player: player,
                time: this.props.time
            }
        });
    }

    handleButtonPress (position, player) {
        if (position === 'goalie') {
            window.buttonPressTimer = window.setTimeout(() => {
                /**
                 * When we fire off the Dispatch event to change the goalie
                 * we have to also set our local component state to `goalieHold`
                 * to `true` since we don't want to fire off an actual click event
                 * which is what would happen. We don't want to register both
                 * events so we set the state here.
                 */
                this.setState({ goalieHold: true });

                Dispatcher.dispatch({
                    type: 'change-goalie',
                    data: player,
                    time: this.props.time
                });
            }, 750);
        }
    }

    handleButtonRelease () {
        window.clearTimeout(window.buttonPressTimer);
    }

    renderButtons(section) {
        let players = this.props.players[section];

        Players.sort(players);

        return this.props.players[section].map((player) => {
            const penaltyNumbers = this.props.time.penalties.team.map((p) => { return p.player; });

            if (!player.active || penaltyNumbers.includes(player.number)) {
                return '';
            }

            const active = (this.props.lockedEvent && this.props.lockedEvent.player === player.id) ? 'active' : '';
            const hl = this.props.currentGoalie === player.id ? 'highlighted' : '';

            return (
                <div
                    key={player.number}
                    onClick={() => this.handleClick(player)}
                    onTouchStart={() => this.handleButtonPress(section, player)}
                    onTouchEnd={() => this.handleButtonRelease()}
                    onMouseDown={() => this.handleButtonPress(section, player)}
                    onMouseUp={() => this.handleButtonRelease()}
                    onMouseLeave={() => this.handleButtonRelease()}
                    className={`btn ${active} ${hl}`}>
                    <span className={this.props.lockedEvent && this.props.lockedEvent.type === 'faceoff' && player.faceoff_player ? 'faceoff-highlight' : ''}>{player.number}</span>
                </div>
            );
        });
    }

    unknown() {
        return { id: '?', number: '?' };
    }

    render() {
        return (
            <div className="section players">
                <div className="inner">
                    <div className="forwards">
                        <div className="label">Forwards</div>
                        <div className="player-numbers btn-row-5">
                            {this.renderButtons('forward')}
                        </div>
                    </div>
                    <div className="defense">
                        <div className="label">Defense</div>
                        <div className="player-numbers btn-row-3">
                            {this.renderButtons('defense')}
                            <div
                                onClick={() => this.handleClick(this.unknown())}
                                className={`btn ${this.props.lockedEvent && this.props.lockedEvent.player === '?' ? 'active' : ''}`}>
                                <span>?</span>
                            </div>
                        </div>
                    </div>

                    <div className="goalies">
                        <div className="label">Goalies</div>
                        <div className="player-numbers btn-row-1">
                            {this.renderButtons('goalie')}
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
