/**
 * Main class for penalty related functionality. This mainly deals with parsing
 * penalty times and configurations for all of the different types/lengths of
 * penalties i.e. 2, 2+5, etc.
 */
export default class Penalty {
    /**
     * Parse a penalty object and return an object with the correct minutes
     * and behavior of the respective penalty. This needs to return both
     * the minutes of the penalty to go onto the scoreboard as well as whether
     * or not the penalty is cancelled when the other team scores a goal.
     */
    static parse(penalty, penaltyCount, format, selectedType) {
        const type = this.selectedPenaltyTypeOptions(selectedType)
        const penaltyLabel = penalty.penaltyType
        const buttonConfig = type.buttons.filter(b => b.label === penaltyLabel)[0]

        return {
            minutes:            buttonConfig.minutes,
            seconds:            buttonConfig.seconds || '00',
            removeOnGoal:       buttonConfig.removeOnGoal,
            removeOnGoalAfter2: buttonConfig.removeOnGoalAfter2 || false,
            stacked:            this.evalStacked(penaltyCount, format)
        }
    }

    static evalStacked(penaltyCount, format) {
        if (format === 3) {
            return (penaltyCount + 1) > 2;
        } else {
            return format - (penaltyCount + 1) < 3;
        }
    }

    static penaltyLengthOptions() {
        return [
            {
                type: 1,
                minor: '2',
                major: '5',
                misconduct: '10',
                buttons: [
                    {
                        label: '2',
                        minutes: '02',
                        removeOnGoal: true
                    },
                    {
                        label: '2+2',
                        minutes: '04',
                        removeOnGoal: true
                    },
                    {
                        label: '5+10',
                        minutes: '05',
                        removeOnGoal: false
                    },
                    {
                        label: '5',
                        minutes: '05',
                        removeOnGoal: false
                    },
                    {
                        label: '5+2',
                        minutes: '07',
                        removeOnGoal: false,
                        removeOnGoalAfter2: true
                    },
                    {
                        label: '2+10',
                        minutes: '02',
                        removeOnGoal: true
                    }
                ]
            },
            {
                type: 2,
                minor: '1:30',
                major: '4',
                misconduct: '8',
                buttons: [
                    /**
                     * @TODO see if seconds will be taken into account in the `minutes` block here...
                     */
                    {
                        label: '1:30',
                        minutes: '01',
                        seconds: '30',
                        removeOnGoal: true
                    },
                    {
                        label: '1:30+1:30',
                        minutes: '03',
                        removeOnGoal: true
                    },
                    {
                        label: '4+8',
                        minutes: '04',
                        removeOnGoal: false
                    },
                    {
                        label: '4',
                        minutes: '04',
                        removeOnGoal: false
                    },
                    {
                        label: '4+1:30',
                        minutes: '05',
                        seconds: '30',
                        removeOnGoal: false,
                        removeOnGoalAfter2: true
                    },
                    {
                        label: '1:30+8',
                        minutes: '01',
                        seconds: '30',
                        removeOnGoal: true
                    }
                ]
            },
            {
                type: 3,
                minor: '1',
                major: '3',
                misconduct: '6',
                buttons: [
                    {
                        label: '1',
                        minutes: '01',
                        removeOnGoal: true
                    },
                    {
                        label: '1+1',
                        minutes: '02',
                        removeOnGoal: true
                    },
                    {
                        label: '3+6',
                        minutes: '03',
                        removeOnGoal: false
                    },
                    {
                        label: '3',
                        minutes: '03',
                        removeOnGoal: false
                    },
                    {
                        label: '3+1',
                        minutes: '04',
                        removeOnGoal: false,
                        removeOnGoalAfter2: true
                    },
                    {
                        label: '1+6',
                        minutes: '01',
                        removeOnGoal: true
                    }
                ]
            },
        ];
    }

    static selectedPenaltyTypeOptions(type) {
        return this.penaltyLengthOptions().filter(o => o.type === type)[0]
    }
}
