import React from 'react';
import Dispatcher from '../dispatchers/Dispatcher';

export default class ConfigureClock extends React.Component {
    bumpTime(increment) {
        Dispatcher.dispatch({
            type: 'bump-configure-clock',
            data: {
                increment: increment,
                gameId: this.props.gameId
            }
        });
    }

    render() {
        return (
            <div className="period-length">
                <div className="time">
                    <div className="inner-container">
                        <div
                            onClick={() => this.bumpTime(false)}
                            className="adj-time minus">
                            <div>-1m</div>
                        </div>
                        <div
                            onClick={() => this.bumpTime(true)}
                            className="adj-time plus">
                            <div>+1m</div>
                        </div>
                        <div className="current-time">
                            {`${this.props.duration < 10 ? '0' : ''}${this.props.duration}:00`}
                            <br/>
                            <span>Period Length</span>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
