import Dispatcher from '../dispatchers/Dispatcher';
import moment from 'moment'

/**
 * Main class for handling shared Timer functionality.
 * This is intended to share functionality between
 * the game clock and penalty clocks.
 */
export default class Timer {
    haveStartedClock = false;

    /**
     * Decrement the timer by a second.
     */
    static decrement(prevTime) {
        return this.bump(prevTime, -1);
    }

    /**
     * Bump the time in any given direction by specifying
     * the `amount`. For decrement, just use -n. This same
     * function is used for both normal clock decrement as
     * well as manually bumping the time +- 2s.
     */
    static bump(time, amount) {
        const min = Number.parseInt(time.minutes);
        const sec = Number.parseInt(time.seconds);

        let newMin;
        let newSec;

        if (sec === 0 && amount < 0) {
            /**
             * Here we check if the seconds are at 0 and we are
             * subtracting time from the clock. When that is the case
             * we want to start at the top of the clock in terms of
             * seconds minus the amount to subtract. In other words
             * if the clock is at 0 and we subtract 2, run 60 - 2.
             */
            newSec = `${60 + amount}`;
            newMin = `${min - 1}`;
        } else if (sec + amount >= 60) {
            /**
             * If the seconds + the amount to bump is greater than 60,
             * we have to set the time to seconds + amount and subtract
             * that number by 60, giving us the correct "leftover" amount
             * to add to the clock.
             */
            newSec = `${(sec + amount) - 60}`;
            newMin = `${min + 1}`;
        } else if ((sec + amount) < 0) {
            /**
             * When the seconds + amount is less than 0, we instead of
             * subtracting 60 like above, we plus 60, giving us the
             * correct number in the opposite direction. It's important
             * to note that in this use case the amount will be negative,
             * meaning it's going to run sec + -num.
             */
            newSec = `${(sec + amount) + 60}`;
            newMin = `${min - 1}`;
        } else {
            /**
             * If we're not in in a special use case, just add
             * the amount to seconds and keep minutes the same.
             */
            newSec = `${sec + amount}`;
            newMin = `${min}`;
        }

        /**
         * Always check to pad the seconds and minutes with a leading 0 when in 0-9.
         */
        if (newSec.length === 1) {
            newSec = `0${newSec}`;
        }

        if (newMin.length === 1) {
            newMin = `0${newMin}`;
        }

        /**
         * If the user bumps the time below 0 minutes, just reset the time to 00:00
         */
        if (newMin < 0) {
            newMin = '00';
            newSec = '00';
        }

        return {
            minutes: newMin,
            seconds: newSec
        }
    }

    /**
     * Shared function for starting the clock
     */
    static startClock() {
        if (!this.haveStartedClock) {
            this.haveStartedClock = true;
            window.gameStartTime = new Date();
            console.log(window.gameStartTime);
        }

        if (window.clockTimeout) {
            return;
        }

        window.clockTimeout = window.setInterval(() => {
            Dispatcher.dispatch({ type: 'time-decrement' });
        }, window.clock.interval || 1000);
    }

    /**
     * Update the window.gameStartTime by amount of seconds
     *
     * @param {int} seconds amount of seconds to add to game start time
     */
    static bumpGameStartTime(seconds) {
        const newStart = moment(window.gameStartTime).add(seconds, 'seconds')
        console.log('Initial start time: ', window.gameStartTime)
        console.log('New start time from faceoff bump: ', newStart.toDate())
        window.gameStartTime = newStart.toDate()
    }

    static pauseTagger() {
        // window.pauseTaggerSeconds = 0;
        if (!window.pauseTaggerSeconds) {
            window.pauseTaggerSeconds = 0;
        }

        if (!window.thispauseTaggerSeconds) {
            window.thispauseTaggerSeconds = 0;
        }

        window.pauseTaggerInterval = window.setInterval(function () {
            ++window.pauseTaggerSeconds;
            ++window.thispauseTaggerSeconds;
        }, 1000);

        if (window.clockTimeout) {
            window.pauseTaggerStartClock = true
            this.stopClock();
        }
    }

    static unpauseTagger() {
        // turn off interval
        window.clearInterval(window.pauseTaggerInterval);
        // grab seconds
        var seconds = window.thispauseTaggerSeconds;
        // subtract game start time by amount of seconds we have.
        var started = moment(window.gameStartTime);
        var newStart = started.add(seconds, 'seconds');

        // update window.gameStartTime to the newStart date
        window.gameStartTime = newStart.toDate();

        if (window.pauseTaggerStartClock) {
            this.startClock();
            window.pauseTaggerStartClock = undefined;
        }

        window.thispauseTaggerSeconds = 0;
    }

    static offsetRealTime() {
        const timeNow = moment(new Date());

        if (!window.pauseTaggerSeconds) {
            return timeNow.toISOString();
        }

        const offset = moment(timeNow).subtract(window.pauseTaggerSeconds, 'seconds');

        return offset.toISOString();
    }

    /**
     * Shared function for stopping the clock
     */
    static stopClock() {
        window.clearInterval(window.clockTimeout);
        window.clockTimeout = null;
    }

    /**
     * Set the clock interval attr inside of window.clock
     */
    static setInterval(interval) {
        this.stopClock();
        window.clock.interval = interval;
        this.startClock();
    }

    static setDebugFunctions() {
        /**
         * Helper functions that can be called from the
         * browser console to make debugging easier.
         */
        window.clock = {
            // start the clock
            start: () => { this.startClock(); },
            // stop the clock
            stop: () => { this.stopClock(); },
            // set a custom clock interval
            setInterval: (interval) => { this.setInterval(interval); }
        };
    }
}
