import React from 'react';

export default class Miss extends React.Component {

    updateValue = (event, fieldName) => {
        this.props.updateEventData(this.props.index, fieldName, event.target.value);
    }

    render() {
        return (
            <div className="event-col">
                <label>Result</label>
                <select name="result" className="form-control" value={this.props.event.value} onChange={(e) => this.updateValue(e, 'value')}>
                    <option value='miss'>Miss</option>
                    <option value='miss_in_play'>Miss in play</option>
                    <option value='post'>Post</option>
                    <option value='post_crossbar_in_play'>Post/Crossbar in play</option>
                    <option value='post_crossbar_out_of_play'>Post/Crossbar out of play</option>
                    <option value='out_of_play'>Out of play</option>
                    <option value='penalty_shot'>Penalty shot</option>
                </select>
            </div>
        );
    }
}
