import React from 'react';
import Dispatcher from '../dispatchers/Dispatcher';
import Offset from '../lib/Offset';
import Strings from '../lib/Strings';

export default class PenaltyShotModal extends React.Component {
    constructor(props) {
        super(props);

        this.state = this.initialState();
    }

    initialState() {
        return {
            result: null,
            shot_type: null,
            net: null,
            approach: null
        };
    }

    save() {
        Dispatcher.dispatch({
            type: 'save-penalty-shot',
            data: {
                form: this.state,
                time: this.props.time
            }
        });

        this.setState(this.initialState());
    }

    setItem(attr, value) {
        this.setState({ [attr]: value });
    }

    renderOptions(attr, set) {
        return set.map((item, idx) => {
            return (
                <div
                    key={idx}
                    className={`btn ${this.state[attr] === item ? 'active' : ''}`}
                    onClick={() => this.setItem(attr, item)}>
                    <span>{Strings.titleize(item)}</span>
                </div>
            );
        });
    }

    results() {
        return ['goal', 'miss', 'save'];
    }

    shotTypes() {
        return ['wrist', 'snap', 'slap', 'backhand', 'deke_to_forehand', 'deke_to_backhand'];
    }

    approachTypes() {
        return [ 'left', 'middle', 'right' ];
    }

    netClick(e) {
        this.setState({ net: Offset.parse(e.target, e) });
    }

    perc() {
        const format = Offset.formatPercentage(this.state.net);

        return {
            left: format.left,
            top: format.top
        };
    }

    renderPuck() {
        if (this.state.net) {
            const split = this.perc();

            return <div className="puck" style={{ left: split.left, top: split.top }}></div>;
        } else {
            return '';
        }
    }

    /**
     * When a user clicks to cancel a penalty shot, it does not remove the penalty itself...
     * the goal is to only cancel out of the penalty shot entry.
     */
    cancel() {
        // clear state
        this.setState({
            result: null,
            shot_type: null,
            net: null,
            approach: null
        });

        // close modal
        Dispatcher.dispatch({ type: 'close-modal' });
    }

    render() {
        return (
            <div className={`modal-overlay pen-modal ${this.props.open ? 'open' : ''}`}>
                <div className="modal-content">
                    <div className="section">
                        <div className="inner">
                            <div className="label">Result</div>
                            <div className="btn-row-3 btn-multi-line">
                                {this.renderOptions('result', this.results())}
                            </div>
                        </div>
                    </div>
                    <div className="section">
                        <div className="inner">
                            <div className="label">Type of Shot</div>
                            <div className="btn-row-3 btn-multi-line">
                                {this.renderOptions('shot_type', this.shotTypes())}
                            </div>
                        </div>
                    </div>
                    {/* <div className="section">
                        <div className="inner">
                            <div className="label">Approach Path</div>
                            <div className="btn-row-3 btn-multi-line">
                                {this.renderOptions('approach', this.approachTypes())}
                            </div>
                        </div>
                    </div> */}
                    <div className="section net-container">
                        <div className="inner">
                            {/* <div className="label">Shot Position</div> */}
                            <img
                                onClick={(e) => this.netClick(e)}
                                alt="net"
                                src="net.svg"
                                width="350px" />
                            {this.renderPuck()}
                        </div>
                    </div>
                    <div className="section">
                        <div className="inner">
                            <div className="btn-row-2">
                                <div className="save btn" onClick={() => this.save()}>Save</div>
                                <div className="btn save red" onClick={() => this.cancel()}>Exit Don't Save</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
