import React from 'react'
import Dispatcher from '../../dispatchers/Dispatcher';
import inflection from 'inflection';
import ClearLine from './ClearLine';

export default function TimeOnIceLines({ position, active, time, showLines }) {
    const lines = position === 'forward' ? [1, 2, 3, 4] : [1, 2, 3]

    const handleClick = (line) => {
        Dispatcher.dispatch({
            type: 'toi-line-click',
            data: {
                position: position,
                line: line,
                time: time
            }
        });
    }

    // this will always at least return the ClearLine button, but will only show the line toggles if showLines is true
    return (
        <div className="line-toggles btn-row-5">

            {
                showLines && lines.map(l => (
                    <div className={`btn line ${active === l ? 'active' : ''} player-line-${l}`} key={l} onClick={() => handleClick(l)}>
                        <span>{inflection.ordinalize(`${l} ${position === 'forward' ? 'Line' : 'Pair'}`)}</span>
                    </div>
                ))
            }

            <ClearLine position={position} time={time} />

        </div>
    )
}
