import React from 'react';
import Dispatcher from '../dispatchers/Dispatcher';
import Timer from '../lib/Timer';

export default class GameClock extends React.Component {
    componentDidMount() {
        Timer.setDebugFunctions();
    }

    bumpTime(isIncrementing) {
        Dispatcher.dispatch({
            type: 'bump-time',
            data: {
                increment: isIncrementing,
                amount: this.props.time.minuteBump ? 60 : 2,
                eventCount: this.props.eventCount,
                time: this.props.time,
                toiClockNotStarted: this.props.toiClockNotStarted
            }
        });
    }

    renderBumpAmount() {
        if (this.props.time.minuteBump) {
            return '1m';
        } else {
            return '2s';
        }
    }

    render() {
        return (
            <div className="time">
                <div className="inner-container">
                    <div
                        onClick={() => this.bumpTime(false)}
                        className="adj-time minus">
                        <div>{`-${this.renderBumpAmount()}`}</div>
                    </div>
                    <div
                        onClick={() => this.bumpTime(true)}
                        className="adj-time plus">
                        <div>{`+${this.renderBumpAmount()}`}</div>
                    </div>
                    <div className="current-time">
                        {`${this.props.time.minutes}:${this.props.time.seconds}`}
                        <br/>
                        <span>Period {this.props.time.period}</span>
                    </div>
                </div>
            </div>
        );
    }
}
