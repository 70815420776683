import { ReduceStore } from 'flux/utils';
import Dispatcher from '../dispatchers/Dispatcher';
import Timer from '../lib/Timer';
import Storage from '../lib/Storage';
import Client from '../lib/Client';
 import moment from 'moment';

class TimeOnIceStore extends ReduceStore {
    constructor() {
        super(Dispatcher);
        this.audio = new Audio("faceoff_confirmation.mp3");
    }

    getInitialState() {
        return {
            shifts:             [],   // the array of individual shifts (player,start,end)
            activeShifts:       [],   // the current shifts/players on ice
            players:            [],   // array of players with seconds_played
            zones:              [],   // array of active zone "shift" or time objects
            activeZone:         null, // object of zone "shift" or time object
            message:            '',   // UI alert message
            completed:          true, // has the tagger/game been completed?
            tagger_type:        'time_on_ice',
            sidebar:            false,
            showEndPeriodModal: false,
            showExitGameModal:  false,
            showEndGameModal:   false,
            clockStarted:       false,
            clockRunning:       false,
            clockStartedAlert:  false,
            lines: {
                forward: null,
                defense: null
            }
        };
    }

    /**
     * Return an array of all game team players, not grouped by position.
     */
    allPlayers(playerSet) {
        let ret = [];

        ['forward', 'defense', 'goalie'].forEach((pos) => {
            playerSet[pos].forEach((player) => {
                ret.push({
                    ...player,
                    seconds_played: 0
                });
            });
        });

        return ret;
    }

    /**
     * Return whether or not a player is currently on the ice.
     */
    activePlayer(state, playerId) {
        for (let i = 0; i < state.activeShifts.length; ++i) {
            if (state.activeShifts[i].id === playerId) {
                return state.activeShifts[i];
            }
        }

        return false;
    }

    endAllShifts(state, endGoalieShift, time) {
        const shiftsToEnd = endGoalieShift ? state.activeShifts : state.activeShifts.filter((shift) => {
            return !shift.goalie;
        });

        const goalieShift = endGoalieShift ? [] : state.activeShifts.filter((shift) => {
            return shift.goalie;
        });

        return {
            ...state,
            shifts: state.shifts.concat(shiftsToEnd.map((a) => {
                return {
                    ...a,
                    ...this.endTimes(time)
                    // end: new Date().toISOString(),
                    // end_game_clock: `${time.minutes}:${time.seconds}`
                };
            })),
            activeShifts: goalieShift,
            activeZone: null,
            clockRunning: false,
            zones: !state.activeZone ? state.zones : state.zones.concat({
                ...state.activeZone,
                ...this.endTimes(time)
                // end: new Date().toISOString(),
                // end_game_clock: `${time.minutes}:${time.seconds}`
            }),
            lines: {
                forward: null,
                defense: null
            }
        };
    }

    bumpSecondsPlayed(state, amount) {
        /**
         * What we do here is get an array of the current shift's player ids
         * and map each player in our `state.players` array. If that player
         * is included in `activeIds` we just return the player after incrementing
         * their seconds played by 1.
         */
        const activeIds = state.activeShifts.map((p) => { return p.id; });

        return {
            ...state,
            // update the overall time the user has played in the game
            players: state.players.map((player) => {
                if (activeIds.includes(player.id)) {
                    return {
                        ...player,
                        seconds_played: player.seconds_played + amount
                    };
                } else {
                    return player;
                }
            }),
            // update the time for the individual active shifts
            activeShifts: state.activeShifts.map((shift) => {
                return {
                    ...shift,
                    seconds_played: shift.seconds_played + amount
                };
            }),
            activeZone: !state.activeZone ? state.activeZone : {
                ...state.activeZone,
                seconds: state.activeZone.seconds + amount
            }
        };
    }

    calculateSecondsSinceStart() {
        const now = moment(new Date())
        const gameStart = moment(window.gameStartTime)

        const seconds = now.diff(gameStart, 'seconds')

        return seconds
    }

    startTimes(time) {
        return {
            start: new Date().toISOString(),
            start_game_clock: `${time.minutes}:${time.seconds}`,
            start_seconds: this.calculateSecondsSinceStart()
        }
    }

    endTimes(time) {
        return {
            end: new Date().toISOString(),
            end_game_clock: `${time.minutes}:${time.seconds}`,
            end_seconds: this.calculateSecondsSinceStart()
        }
    }

    reduce(state, action) {
        switch (action.type) {
        case 'select-tagger':
            return {
                ...state,
                ...action.data.game,
                players: this.allPlayers(action.data.game.team.players)
            };
        case 'bump-configure-clock':
            /**
             * When the user is on the game configuration screen the period duration needs
             * to change for the `toi` object as well (in the game container prop). This runs
             * the same logic that will run in the time store.
             */
            let duration = action.data.increment ? ++state.period_duration : --state.period_duration;

            if (duration < 0) {
                duration = 0;
            }

            return {
                ...state,
                period_duration: duration
            };
        case 'toi-player-click':
            let foundPlayer = this.activePlayer(state, action.data.player.id);

            if (foundPlayer) {
                // remove player from active
                return {
                    ...state,
                    activeShifts: state.activeShifts.filter((player) => {
                        return player.id !== action.data.player.id;
                    }),
                    shifts: state.shifts.concat({
                        ...foundPlayer,
                        ...this.endTimes(action.data.time)
                        // end: new Date().toISOString(),
                        // end_game_clock: `${action.data.time.minutes}:${action.data.time.seconds}`
                    }),
                    lines: {
                        ...state.lines,
                        [action.data.position]: null
                    }
                };
            } else {
                // add player to active
                return {
                    ...state,
                    activeShifts: state.activeShifts.concat({
                        ...action.data.player,
                        goalie: action.data.goalie,
                        position: action.data.position,
                        // start: new Date().toISOString(),
                        // start_game_clock: `${action.data.time.minutes}:${action.data.time.seconds}`,
                        ...this.startTimes(action.data.time),
                        period: action.data.time.period,
                        end: null,
                        end_game_clock: null,
                        end_seconds: 0,
                        seconds_played: 0
                    }),
                    lines: {
                        ...state.lines,
                        [action.data.position]: null
                    }
                };
            }
        case 'toi-sync-starting-players':
            /**
             * Whenever the time on ice tagger launches, we need to determine which goalies
             * were marked as starting in order to add them to the active shifts array here.
             * That way the goalies that were marked as active show up as active without
             * the coach having to find and click them again.
             *
             * The reason we do it here and not when the user clicks the player in the configure
             * roster component is because that persists in local storage in case they want to
             * configure the game before-hand and then come back and start the game later.
             * Since that persists in local storage, we would end up having the same problem
             * of the goalie not being active on the tagger unless we run the process on the
             * tagger itself, which is what we are doing here instead of on configure click.
             */
            try {
                return {
                    ...state,
                    activeShifts: state.team.players.goalie.filter(g => g.starting).map(p => {
                        return {
                            ...p,
                            goalie: true,
                            start: new Date().toISOString(),
                            start_game_clock: `${action.data.duration < 10 ? '0' : ''}${action.data.duration}:00`,
                            start_seconds: 0,
                            end: null,
                            end_game_clock: null,
                            end_seconds: 0,
                            seconds_played: 0,
                            period: 1 // we always wipe shifts after each period -- this should always be 1
                        };
                    })
                };
            } catch (e) {
                console.error('Error: could not parse starting goalies: ', e);
                return state;
            }
        case 'toi-start':
            Timer.startClock();
            this.audio.play();
            return {
                ...state,
                message:           'Clock started',
                clockStarted:      true,
                clockRunning:      true,
                clockStartedAlert: true
            };
        case 'toi-stop':
            Timer.stopClock();
            return {
                ...state,
                message: 'Clock stopped',
                activeZone: null,
                clockRunning: false,
                zones: !state.activeZone ? state.zones : state.zones.concat({
                    ...state.activeZone,
                    ...this.endTimes(action.data.time)
                    // end: new Date().toISOString(),
                    // end_game_clock: `${action.data.time.minutes}:${action.data.time.seconds}`
                })
            };
        case 'clear-message':
            return { ...state, message: '' };
        case 'time-decrement':
            return this.bumpSecondsPlayed(state, 1)
        case 'bump-time':
            const incrementBump = action.data.increment;
            const bumpAmount = action.data.amount;

            /**
             * If the user is bumping at the beginning of a period by 60 seconds,
             * we don't want to take time off the shift... since technically no shift
             * has started at that point... we don't want to bump the active shifts
             * by minutes or the player times at the beginning of periods or whatever...
             */
            if (Number.parseInt(bumpAmount) === 60) {
                return {
                    ...state,
                    activeShifts: state.activeShifts.map((shift) => {
                        const m = Number.parseInt(action.data.time.minutes) + (incrementBump ? 1 : -1)

                        const formattedM = m < 10 ? `0${m}` : `${m}`

                        return {
                            ...shift,
                            start_game_clock: `${formattedM}:${action.data.time.seconds}`
                        }
                    })
                };
            }

            return this.bumpSecondsPlayed(state, (incrementBump ? (bumpAmount * -1) : bumpAmount));
        case 'toi-end-period':
            Timer.stopClock();
            /**
             * When the period ends we concat onto the shifts array all of
             * our active shifts, setting (mapping) each one to the obj + end
             * time of right now.
             */
            return {
                ...this.endAllShifts(state, false, action.data.time),
                showEndPeriodModal: false
            }
        case 'toi-end-game':
            const finalShifts = this.endAllShifts(state, true, action.data.time);

            Storage.completeGame(finalShifts);

            return { ...state, shoeEndGameModal: false };
        case 'toggle-sidebar':
            return { ...state, sidebar: !state.sidebar };
        case 'upload-in-progress-game':
            if (action.data.tagger === 'toi') {
                Client.uploadGameInProgress(state);
            }
            return state;
        case 'toi-toggle-end-modal':
            if (action.data.modal === 'period') {
                return {
                    ...state,
                    showEndPeriodModal: action.data.open
                }
            } else if (action.data.modal === 'end-game') {
                return {
                    ...state,
                    showEndGameModal: action.data.open
                }
            }

            return {
                ...state,
                showExitGameModal: action.data.open
            }
        case 'toi-zone-click':
            const newZoneObj = {
                zone:             action.data.zone,
                period:           action.data.time.period,
                // start:            new Date().toISOString(),
                // start_game_clock: `${action.data.time.minutes}:${action.data.time.seconds}`,
                ...this.startTimes(action.data.time),
                end:              null,
                end_game_clock:   null,
                end_seconds:      0,
                seconds:          0
            }

            // IF THERE IS NO ACTIVE ZONE, SET IT TO WHAT WAS SENT
            if (!state.activeZone) {
                return {
                    ...state,
                    activeZone: newZoneObj
                }
            }

            // the "processed" active zone record with its effective end times
            const zoneToSave = {
                ...state.activeZone,
                ...this.endTimes(action.data.time)
                // end: new Date().toISOString(),
                // end_game_clock: `${action.data.time.minutes}:${action.data.time.seconds}`
            }

            // IF THERE IS AN ACTIVE ZONE AND IT'S THE SAME, TURN IT OFF
            if (state.activeZone.zone === action.data.zone) {
                return {
                    ...state,
                    activeZone: null,
                    zones: state.zones.concat(zoneToSave)
                }
            }

            // IF THERE IS AN ACTIVE ZONE AND IT'S NOT THE SAME, END THE ACTIVE, START NEW
            return {
                ...state,
                activeZone: newZoneObj,
                zones: state.zones.concat(zoneToSave)
            }
        case 'toi-line-click':
            /**
             * ----------------------------
             * data: [position, line]
             * ----------------------------
             * @TODO here we need to also figure out which players to take on/off based
             * on their position and line settings.
             *
             * @TODO how do we determine that the player(s) don't have a line -- do nothing?
             * ----------------------------
             */
            return {
                ...state,
                lines: {
                    ...state.lines,
                    [action.data.position]: action.data.line
                },
                shifts: state.shifts.concat(
                    state.activeShifts.filter(p => p.position === action.data.position) // ignore goalies and other position lines
                        .filter(s => s.seconds_played > 0) // only take shifts that were longer than a second in case they click around...
                        .map(s => { // turn off active shifts
                            return {
                                ...s,
                                ...this.endTimes(action.data.time)
                                // end: new Date().toISOString(),
                                // end_game_clock: `${action.data.time.minutes}:${action.data.time.seconds}`
                            }
                        })
                ),
                activeShifts: state.activeShifts.filter(p => p.position !== action.data.position) // always keep other positions
                    .concat(
                        state.team.players[action.data.position]
                            .filter(p => p.line === action.data.line) // only grab players on the selected line
                            .map(p => {
                                return {
                                    ...p,
                                    position: action.data.position,
                                    // start: new Date().toISOString(),
                                    // start_game_clock: `${action.data.time.minutes}:${action.data.time.seconds}`,
                                    ...this.startTimes(action.data.time),
                                    end_game_clock: null,
                                    end: null,
                                    end_seconds: 0,
                                    period: action.data.time.period,
                                    seconds_played: 0
                                }
                            })
                    )
            }
        case 'toi-line-clear':
            return {
                ...state,
                lines: {
                    ...state.lines,
                    [action.data.position]: null
                },
                shifts: state.shifts.concat(
                    state.activeShifts
                        .filter(p => p.position === action.data.position)
                        .filter(s => s.seconds_played > 0) // only take shifts that were longer than a second in case they click around...
                        .map(s => {
                            return {
                                ...s,
                                ...this.endTimes(action.data.time)
                                // end: new Date().toISOString(),
                                // end_game_clock: `${action.data.time.minutes}:${action.data.time.seconds}`
                            }
                        })
                ),
                activeShifts: state.activeShifts.filter(p => p.position !== action.data.position)
            };
        case 'clear-faceoff-alert':
            return { ...state, clockStartedAlert: false };
        default:
            return { ...state };
        }
    }
}

export default new TimeOnIceStore();
