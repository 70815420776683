import React from 'react';
import Select from 'react-select'

export default class Goal extends React.Component {
    constructor(props) {
        super(props)

        this.state = {
            defaults: []
        }
    }

    updateValue = (event, fieldName) => {
        this.props.updateEventData(this.props.index, fieldName, event.target.value)
    }

    updatePlayersOnIceValue = (players) => {
        this.props.updateEventData(this.props.index, 'players_on_ice', players.map((player) => player.value))
    }
    
    defaultOptions = () => {
        let result = [];
        this.props.playersOnIceOptions.forEach((poi, i) => 
            this.props.event.players_on_ice.includes(poi.value) ? result.push(i) : ''
        )

        let defaultPlayers = result.map((i) => this.props.playersOnIceOptions[i])

        return defaultPlayers;
    }

    render() {
        return (
            <>
                <div className="event-col">
                    <label>Result</label>
                    <select name="result" className="form-control" value={this.props.event.value} onChange={(e) => this.updateValue(e, 'value')}>
                    <option value='even'>Even</option>
                    <option value='power_play'>Power Play</option>
                    <option value='short_handed'>Short-Handed</option>
                    <option value='extra_attacker'>Extra-Attacker</option>
                    <option value='delayed_penalty'>Delayed Penalty</option>
                    <option value='empty_net'>Empty Net</option>
                    <option value='penalty_shot'>Penalty shot</option>
                    </select>
                </div>

                <div className="event-col">
                    <label>Assist #1</label>
                    <select name="assist_1" className="form-control event-input" value={this.props.event.assist_1} onChange={(e) => this.updateValue(e, 'assist_1')}>
                        <option value=''></option>
                        {this.props.renderPlayers()}
                    </select>
                </div>
                
                <div className="event-col">
                    <label>Assist #2</label>
                    <select name="assist_2" className="form-control event-input" value={this.props.event.assist_2} onChange={(e) => this.updateValue(e, 'assist_2')}>
                        <option value=''></option>
                        {this.props.renderPlayers()}
                    </select>
                </div>

                <div style={{ color: "black !important" }}>
                    <label style={{ fontSize: '25px', marginTop: '20px' }}>Players on Ice</label>
                    <Select className="black-select" isMulti options={this.props.playersOnIceOptions} defaultValue={this.defaultOptions()} onChange={(e) => this.updatePlayersOnIceValue(e)} />
                </div>
            </>
        );
    }
}
