import { ReduceStore } from 'flux/utils';
import Dispatcher from '../dispatchers/Dispatcher';
import Storage from '../lib/Storage';
import Games from '../lib/Games';

class UserStore extends ReduceStore {
    constructor() {
        super(Dispatcher);
    }

    getInitialState() {
        return {
            user: null,
            loggingIn: false,
            error: null,
            games: [],
            gamesUploading: [],
            gamesLoading: false
        };
    }

    /**
     * Update an individual player attribute i.e. notes, active, etc.
     */
    updatePlayers(data, players, removeGoalies) {
        let ret = players;
        let pos = players[data.position];

        // loop through players in the target position
        let updated = pos.map((player) => {
            if (player.id === data.playerId) {
                // return new player with field: value
                return {
                    ...player,
                    [data.field]: data.value
                };
            } else {
                if (removeGoalies) {
                    return {
                        ...player,
                        starting: false
                    };
                } else {
                    return player;
                }
            }
        });

        // set new position array
        ret[data.position] = updated;

        return ret;
    }

    /**
     * Update an individual player's position. We do this by looping over, finding
     * the player, deleting them from their original position, and adding them
     * to the target position array.
     */
    updatePlayerPosition(data, players) {
        let ret = players;
        let targetPlayer = null;

        Object.keys(ret).forEach((position) => {
            ret[position].forEach((player, index) => {
                if (player.id === data.playerId) {
                    // set target player before deleting
                    targetPlayer = ret[position][index];
                    // remove player from current position
                    delete ret[position][index];
                    // flatten array -- delete will leave `empty` member
                    ret[position] = ret[position].flat();
                }
            });
        });

        // set player to new position
        ret[data.newPosition].push(targetPlayer);

        return ret;
    }

    updatePlayerNumbers(data, players) {
        Object.keys(players).map(function(key, index) {
            // eslint-disable-next-line
            return players[key].map((player, index) => {
                if (data.alternateJerseys) {
                    player.number = player.alternate_number
                } else {
                    player.number = player.original_number
                }
            });
        });

        return players;
    }

    reduce(state, action) {
        switch (action.type) {
        case 'logging-in':
            return {
                ...state,
                error: null,
                loggingIn: true
            };
        case 'login-error':
            return {
                ...state,
                loggingIn: false,
                error: action.data
            };
        case 'logged-in':
            // save off the attributes into local storage from the logged in user
            // i.e. user token, upcoming game information, etc.
            Storage.setToken(action.data.token);

            // prep our games to be saved by setting some defaults i.e. active, etc.
            Games.configure(action.data.games);

            window.location.reload();

            return {
                ...state,
                loggingIn: false,
                error: null
            };
        case 'load-games':
            return {
                ...state,
                games: Storage.getGames(),
                completedGames: Storage.getCompletedGames(),
                gamesLoading: false
            };
        case 'begin-loading-games':
            return { ...state, gamesLoading: true };
        case 'change-user-game-player':
            // this will get injected on the game level which is where the current goalie attr is
            let goalie = {};
            // flag to pass to updatePlayers on if we should remove other `active` flags on other goalies
            let removeCurrentGoalies = false;

            // when the position is goalie & we're toggling 'starting' we need to evaluate
            // the current goalie for the game.
            if (action.data.position === 'goalie' && action.data.field === 'starting') {
                if (action.data.value) {
                    // when it's marked as active, set the current goalie and mark
                    // the flag to remove other currently active goalies so there's
                    // only ever one active goalie that's flagged.
                    goalie.current_goalie = action.data.playerNumber;
                    removeCurrentGoalies = true;
                } else {
                    // when there's no goalie just set to null since we're enforcing
                    // only one active at a time, there's only 1 that will switch.
                    goalie.current_goalie = null;
                }
            }

            // build the games object that will be returned & saved into local storage.
            const games = state.games.map((game) => {
                if (game.id === action.data.gameId) {
                    return {
                        ...game,
                        ...goalie,
                        team: {
                            ...game.team,
                            players: this.updatePlayers(action.data, game.team.players, removeCurrentGoalies)
                        }
                    };
                } else {
                    return game;
                }
            });

            Storage.setGames(games);

            return {
                ...state,
                games: games
            };
        case 'change-user-game-player-position':
            const posGames = state.games.map((game) => {
                if (game.id === action.data.gameId) {
                    return {
                        ...game,
                        team: {
                            ...game.team,
                            players: this.updatePlayerPosition(action.data, game.team.players)
                        }
                    };
                } else {
                    return game;
                }
            });

            Storage.setGames(posGames);

            return {
                ...state,
                games: posGames
            };
        case 'change-user-alternate-jersey':
            const jerseyGames = state.games.map((game) => {
                if (game.id === action.data.gameId) {
                    return {
                        ...game,
                        alternate_jerseys: action.data.alternateJerseys,
                        team: {
                            ...game.team,
                            players: this.updatePlayerNumbers(action.data, game.team.players)
                        }
                    };
                } else {
                    return game;
                }
            });

            Storage.setGames(jerseyGames);

            return {
                ...state,
                games: jerseyGames
            };
        case 'bump-configure-clock':
            const clockGames = state.games.map((game) => {
                if (game.id === action.data.gameId) {
                    let duration = action.data.increment ? ++game.period_duration : --game.period_duration;

                    if (duration < 0) {
                        duration = 0;
                    }

                    return {
                        ...game,
                        period_duration: duration
                    };
                } else {
                    return game;
                }
            });

            Storage.setGames(clockGames);

            return {
                ...state,
                games: clockGames
            };
        case 'remove-completed-game':
            const completedGames = Storage.getCompletedGames().filter((g) => {
                return g.id !== action.data.id;
            });

            Storage.setCompletedGames(completedGames);

            return {
                ...state,
                completedGames: completedGames,
                gamesUploading: state.gamesUploading.filter((gameId) => {
                    return gameId !== action.data.id;
                })
            };
        case 'start-upload-game':
            return {
                ...state,
                gamesUploading: state.gamesUploading.concat(action.data)
            };
        case 'remove-completed-games-error':
            return {
                ...state,
                gamesUploading: state.gamesUploading.filter((gameId) => {
                    return gameId !== action.data;
                })
            };
        case 'logout':
            Storage.logout();
            return state;
        default:
            return { ...state };
        }
    }
}

export default new UserStore();
