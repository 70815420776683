import React from 'react';
import Dispatcher from '../dispatchers/Dispatcher';

export default class FaceoffMenu extends React.Component {
    handleClick(item, value) {
        Dispatcher.dispatch({
            type: 'faceoff-button-click',
            data: {
                item: item,
                value: value
            }
        });
    }

    renderFaceoffButtons() {
        // get rid of kicked out since it's always there
        return this.props.configuration.filter(b => b.value !== 'kicked_out').map((c) => {
            return (
                <div
                    key={c.value}
                    className={`btn faceoff ${this.props.lockedEvent.result === c.value ? 'active' : ''}`}
                    onClick={() => this.handleClick('result', c.value)}>
                    <span>{c.display_name}</span>
                </div>
            );
        });
    }

    handleVideoStampClick() {
        Dispatcher.dispatch({
            type: 'video-stamp',
            data: {
                time: this.props.time
            }
        })
    }

    render() {
        return (
            <div className="section" style={{ display: 'flex' }}>
                <div style={{ flex: '1' }}>
                    <div className="label">Faceoff Result</div>
                    <div className={`btn-row-fit`}>
                        {this.renderFaceoffButtons()}
                    </div>
                </div>
                <div style={{ flex: '0.3' }}>
                    <div className="label">Opponent Hand</div>
                    <div className="btn-row-2">
                        <div
                            className={`btn faceoff ${this.props.lockedEvent.opponent_hand === 'left' ? 'active' : ''}`}
                            onClick={() => this.handleClick('opponent_hand', 'left')}>
                            <span>Left</span>
                        </div>
                        <div
                            className={`btn faceoff ${this.props.lockedEvent.opponent_hand === 'right' ? 'active' : ''}`}
                            onClick={() => this.handleClick('opponent_hand', 'right')}>
                            <span>Right</span>
                        </div>
                    </div>
                </div>
                <div className="sep-left pl-1 flex-quarter" style={{ flex: '0.15' }}>
                    <div className="label" style={{paddingBottom:'7px'}}>&nbsp;</div>
                    <div
                        className='btn faceoff'
                        style={{ marginTop: '3px' }}
                        onClick={() => this.handleVideoStampClick()}>
                        <span>VIDEO STAMP</span>
                    </div>
                </div>
            </div>
        );
    }
}
