import React from 'react';
import GameContainer from '../containers/GameContainer';
import '../scss/app.scss';

export default class App extends React.Component {
    render() {
        return (
            <GameContainer />
        );
    }
}
