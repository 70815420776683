/**
 * Helper class for determining and working with offset positions for image clicks
 * i.e. rinks, net images, etc.
 */
export default class Offset {
    /**
     * Return the `x,y` position in terms of percentages that the user clicked
     * on a given image.
     */
    static parse(image, event, scrollingContainerOffsetTop) {
        const img = image;
        const width = img.width;
        const height = img.height;

        const scrollOffset = scrollingContainerOffsetTop || 0;

        let offsetX = img.offsetLeft;
        let offsetY = img.offsetTop;

        let cur = img;

        while (cur.offsetParent) {
            offsetX += cur.offsetParent.offsetLeft;
            offsetY += cur.offsetParent.offsetTop;
            cur = cur.offsetParent;
        }

        const imageClickX = event.clientX - offsetX;
        const imageClickY = event.clientY - offsetY + scrollOffset;

        const percX = imageClickX / width;
        const percY = imageClickY / height;

        // take the individual x and why percentages and only show 4 decimals
        const xRounded = percX.toFixed(4);
        const yRounded = percY.toFixed(4);

        // final string that is returned and set as the x,y coordinates
        const target = `${xRounded},${yRounded}`;

        return target;
    }

    /**
     * Return the `left` and `top` positions for a given `x,y` string
     */
    static formatPercentage(p) {
        const split = p.split(',');

        const format = (element) => { return `${element * 100}%`; };

        return {
            left: format(split[0]),
            top: format(split[1])
        };
    }
}
