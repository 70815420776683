import React from 'react';
import GameClock from './GameClock';
import TaggerHeaderTeamStats from './TaggerHeaderTeamStats';
import Dispatcher from '../dispatchers/Dispatcher';

export default class TaggerHeader extends React.Component {
    openMenu() {
        Dispatcher.dispatch({ type: 'toggle-sidebar' });
    }

    undo() {
        Dispatcher.dispatch({ type: 'undo' });
    }

    render() {
        return (
            <div className="header">
                <div className="host">
                    <div className="team-name">
                        <div className="menu" onClick={() => this.openMenu()}>
                            Menu
                            &nbsp;
                            <span className="vellipsis">&hellip;</span>
                        </div>
                        <div className="name">{this.props.teamName}</div>
                    </div>

                    <TaggerHeaderTeamStats
                        penalties={this.props.time.penalties.team}
                        showNumber={true}
                        shots={this.props.shots.team}
                        goals={this.props.goals.team}
                        reverse={false}
                        side='team' />

                </div>

                <GameClock time={this.props.time} eventCount={this.props.eventCount} />

                <div className="opponent">
                    <div className="team-name">
                        <div className="name">{this.props.opponent_team_name}</div>
                        <div className="undo" onClick={() => this.undo()}>&larr; Undo</div>
                    </div>

                    <TaggerHeaderTeamStats
                        penalties={this.props.time.penalties.opponent}
                        showNumber={false}
                        shots={this.props.shots.opponent}
                        goals={this.props.goals.opponent}
                        reverse={true}
                        side='opponent' />

                </div>
                <div className="color-bar"></div>
                <div className="clear"></div>
            </div>
        );
    }
}
