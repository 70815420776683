import React from 'react';
import Dispatcher from '../dispatchers/Dispatcher';
import TaggerTypeModal from './TaggerTypeModal';
import GameCard from './GameCard';
import Message from './Message';
import Client from '../lib/Client';

export default class SelectGame extends React.Component {
    componentWillMount() {
        // TODO right now if we don't set a timeout it complains about
        // dispatching at the same time -- if we can't fix it this
        // will wait for it to be over
        window.setTimeout(() => {
            Dispatcher.dispatch({ type: 'load-games' });
        }, 100);
    }

    logout() {
        Dispatcher.dispatch({ type: 'logout' });
    }

    /**
     * We only want to show the 'upcoming' section where there's more than
     * one game.
     */
    upcomingGamesBlock() {
        if (this.props.games.length > 1) {
            return (
                <div className="btn-row-2 section upcoming">
                    <h2>Upcoming</h2>
                    <div className="card-container">
                        {this.renderUpcomingGames()}
                    </div>
                </div>
            );
        } else {
            return '';
        }
    }

    renderUpcomingGames() {
        return this.props.games.slice(1).map((game) => {
            return (
                <GameCard
                    key={game.id}
                    game={game}
                    completed={false} />
            );
        });
    }

    isGameUploading(game) {
        return this.props.gamesUploading.includes(game.id);
    }

    renderCompletedGames() {
        return this.props.completedGames.map((game) => {
            return (
                <GameCard
                    key={game.id}
                    game={game}
                    completed={true}
                    uploading={this.isGameUploading(game)} />
            );
        });
    }

    completedGamesBlock() {
        if (this.props.completedGames && this.props.completedGames.length) {
            return (
                <div className="section completed-games">
                    <h2>Completed Games</h2>
                    {this.renderCompletedGames()}
                </div>
            );
        } else {
            return '';
        }
    }

    renderNext() {
        if (this.props.games.length) {
            return (
                <div className="section next-up">
                    <h2>Next Up</h2>
                    {/* Use the 'card' container as the link or whatever you it is you need to select the game. */}
                    <GameCard game={this.props.games[0]} completed={false} />
                </div>
            );
        } else {
            return '';
        }
    }

    render() {
        return (
            <div id="tagger">
                <div className="select-game">
                    <h1>
                        <div className="btn select-action logout" onClick={() => this.logout()}>
                            Logout
                        </div>
                        Select a Game
                        <div className={`btn select-action download sync-games ${ this.props.gamesLoading ? 'flashing' : '' }`} onClick={() => Client.fetchGames()}>
                            Refresh List
                        </div>
                    </h1>
                    {this.renderNext()}
                    {this.upcomingGamesBlock()}
                    {this.completedGamesBlock()}
                    <TaggerTypeModal
                        open={this.props.modal === 'select-tagger'}
                        game={this.props.game} />
                </div>
                <Message message={this.props.message} />
            </div>
        );
    }
}
