import React from 'react';
import TaggerHeaderTeamStatsPenaltiesContainer from './TaggerHeaderTeamStatsPenaltiesContainer';

export default class TaggerHeaderTeamStats extends React.Component {
    render() {
        if (!this.props.reverse) {
            return (
                <div className="team-stats">
                    <div className="shots">
                        <div className="label">Shots</div>
                        <div className="shotnum">{this.props.shots}</div>
                    </div>

                    <TaggerHeaderTeamStatsPenaltiesContainer
                        penalties={this.props.penalties}
                        showNumber={this.props.showNumber}
                        side={this.props.side} />

                    <div className="score">{this.props.goals}</div>
                </div>
            );
        } else {
            return (
                <div className="team-stats">
                    <div className="score">{this.props.goals}</div>

                    <TaggerHeaderTeamStatsPenaltiesContainer
                        penalties={this.props.penalties}
                        showNumber={this.props.showNumber}
                        side={this.props.side} />

                    <div className="shots">
                        <div className="label">Shots</div>
                        <div className="shotnum">{this.props.shots}</div>
                    </div>
                </div>
            );
        }
    }
}
