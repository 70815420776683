import React from 'react';
import Dispatcher from '../dispatchers/Dispatcher';

export default class OvertimeModal extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            mode: 'list',
            duration: this.props.duration,
            format: '5v5'
        };
    }

    formatClick(format) {
        this.setState({ format: format });
    }

    end() {
        Dispatcher.dispatch({
            type: 'end-game',
            data: {
                time: {
                    powerPlaysCount: this.props.time.powerPlaysCount,
                    penaltyKillsCount: this.props.time.penaltyKillsCount
                }
            }
        });
    }

    duration() {
        this.setState({ mode: 'duration' });
    }

    start() {
        Dispatcher.dispatch({
            type: 'start-overtime',
            data: {
                time: this.props.time,
                duration: Number.parseInt(this.state.duration),
                format: this.state.format
            }
        });

        this.setState({ mode: 'list' });
    }

    shootout() {
        Dispatcher.dispatch({ type: 'start-shootout' });
    }

    renderBody() {
        if (this.state.mode === 'list') {
            return this.renderList();
        } else {
            return this.renderDuration();
        }
    }

    renderList() {
        return (
            <div className="section">
                <h1>Select Overtime Option</h1>
                <div className="btn-row-6-stack">
                    <div className="btn" onClick={() => this.duration()}>
                        <span>Overtime</span>
                    </div>
                    <div className="btn" onClick={() => this.shootout()}>
                        <span>Shootout</span>
                    </div>
                    <div className="btn" onClick={() => this.end()}>
                        <span>End Game (Tie)</span>
                    </div>
                </div>
            </div>
        );
    }

    handleDurationChange(e) {
        this.setState({ duration: e.target.value.trim() });
    }

    renderDuration() {
        return (
            <div className="section">
                <h1>Overtime Options</h1>
                <div className="form-group">
                    <label>Period Duration</label>
                    <input
                        onChange={(e) => this.handleDurationChange(e)}
                        className="form-control"
                        name="duration"
                        type="text"
                        value={this.state.duration} />
                </div>
                <label>Overtime Format</label>
                <div className="btn-row-3">
                    <div className={`btn ${this.state.format === '5v5' ? 'active' : ''}`} onClick={() => {this.formatClick('5v5')}}><span>5v5</span></div>
                    <div className={`btn ${this.state.format === '4v4' ? 'active' : ''}`} onClick={() => {this.formatClick('4v4')}}><span>4v4</span></div>
                    <div className={`btn ${this.state.format === '3v3' ? 'active' : ''}`} onClick={() => {this.formatClick('3v3')}}><span>3v3</span></div>
                </div>
                <div className="btn save wide" style={{marginTop: '15px'}} onClick={() => this.start()}>Save & Continue</div>
            </div>
        );
    }

    render() {
        return (
            <div className={`modal-overlay other ${this.props.open ? 'open' : ''}`}>
                <div className="modal-content">
                    {this.renderBody()}
                </div>
            </div>
        );
    }
}
