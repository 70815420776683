import Dispatcher from '../dispatchers/Dispatcher';
import axios from 'axios';
import Endpoints from './Endpoints';
import Games from './Games';

export default class Client {
    static login(form) {
        Dispatcher.dispatch({ type: 'logging-in' });

        axios.post(Endpoints.LOGIN, form).then((response) => {
            if (response.data.error) {
                Dispatcher.dispatch({
                    type: 'login-error',
                    data: response.data.error
                });
            } else {
                // set tokens etc
                Dispatcher.dispatch({
                    type: 'logged-in',
                    data: response.data
                });

                // set view to next screen
                Dispatcher.dispatch({
                    type: 'switch-view',
                    data: 'select'
                });
            }
        }).catch((error) => {
            Dispatcher.dispatch({
                type: 'login-error',
                data: error.message
            });
        });
    }

    /**
     * Fetch upcoming games from the server
     */
    static fetchGames() {
        Dispatcher.dispatch({ type: 'begin-loading-games' });

        axios.get(Endpoints.FETCH_GAMES).then((response) => {
            Games.configure(response.data.games);
            // let the load state stick for at least a second, otherwise it looks jittery
            window.setTimeout(() => {
                Dispatcher.dispatch({ type: 'load-games' });
            }, 1000);
        }).catch((error) => {
            alert(error.message);
        });
    }

    /**
     * Upload a game to the server
     */
    static uploadGame(game) {
        // set uploading status for the individual game to show loading text
        Dispatcher.dispatch({
            type: 'start-upload-game',
            data: game.id
        });

        axios.post(Endpoints.UPLOAD_GAME, game).then((response) => {
            Dispatcher.dispatch({
                type: 'remove-completed-game',
                data: game
            });
        }).catch((error) => {
            Dispatcher.dispatch({
                type: 'remove-completed-games-error',
                data: game.id
            });

            alert(error.message);
        });
    }

    /**
     * Upload a game that is currently in progress
     */
    static uploadGameInProgress(game) {
        axios.post(Endpoints.UPLOAD_GAME, game).then((response) => {
            this.doneUploading();
        }).catch((err) => {
            console.log(err.message);
            this.doneUploading();
        });
    }

    static doneUploading() {
        window.setTimeout(() => {
            Dispatcher.dispatch({ type: 'done-uploading' });
        }, 1000);
    }
}
