import React from 'react';
import Dispatcher from '../dispatchers/Dispatcher';
import Strings from '../lib/Strings';
import Offset from '../lib/Offset';
import Players from '../lib/Players';
import Toggle from 'react-toggle';

export default class GoalModal extends React.Component {
    handleSave() {
        Dispatcher.dispatch({
            type: 'save-goal',
            data: {
                form: this.props.form, // we send this because the TimeStore needs it also
                time: this.props.time,
                side: this.props.defense ? 'team' : 'opponent'
            }
        });
    }

    setItem(section, i, object) {
        let target = i;

        if (i === this.props.player) {
            return;
        }

        // some of the data attributes are an array... when that's the case we
        // need to push/filter the array instaed of just setting the value.
        if (Array.isArray(this.props.form[section])) {
            if (!this.props.form[section].includes(i)) {
                // number is not in player array
                target = this.props.form[section].concat(i);
            } else {
                // number is in player array
                target = this.props.form[section].filter((p) => { return p !== i; });
            }
        }

        Dispatcher.dispatch({
            type: 'set-goal-modal-item',
            data: {
                section: section,
                target: target,
                object: object
            }
        });
    }

    playerClass(player) {
        if (Array.isArray(this.props.form[this.props.form.item])) {
            return this.props.form[this.props.form.item].includes(player.id);
        } else {
            return this.props.form[this.props.form.item] === player.id;
        }
    }

    renderPlayers(field) {
        let players = [];

        const penaltyNumbers = this.props.time.penalties.team.map((p) => { return p.player; });

        ['forward', 'defense', 'goalie'].forEach((key) => {
            this.props.players[key].forEach((p) => {
                // don't show options for ?, not active players, and players in the penalty box
                if (p.number !== '?' && p.active && !penaltyNumbers.includes(p.number)) {
                    // do not show goalies in the players on ice section
                    if (this.props.form.item !== 'players_on_ice' || key !== 'goalie') {
                        // do not show goalies that are not the current goalie
                        if (!(key === 'goalie' && p.id !== this.props.currentGoalie)) {
                            // If on assist 1, don't show player selected in assist 2 and vice versa
                            if (!((this.props.form.item === 'assist_1' && p.id === this.props.form.assist_2) || (this.props.form.item === 'assist_2' && p.id === this.props.form.assist_1))) {
                                players.push({ ...p, position: key }); // add the player position to the player obj for when it's clicked
                            }
                        }
                    }
                }
            });
        });

        Players.sort(players);

        return players.map((p, x) => {
            // We hide `this.props.player` which is the id of the player that scored the goal
            // on all of the tabs except for the `players_on_ice` where the user can see the
            // player that scored the goal as a player on ice. They can't unselect the player
            // they can only view them so they can see all 5 players on ice.
            if (p.id !== this.props.player || this.props.form.item === 'players_on_ice') {
                return (
                    <div
                        key={x}
                        className={`btn ${ this.playerClass(p) || p.id === this.props.player ? 'active' : '' } ${ p.id === this.props.player ? 'secondary' : '' } ${this.extraPlayerClass(p)}`}
                        onClick={() => this.setItem(this.props.form.item, p.id, p)}>
                        <span>{p.number}</span>
                    </div>
                );
            } else {
                return '';
            }
        });
    }

    /**
     * Determine whether or not the player is considered 'extra', i.e. they're not the goal scorer, and not the assist.
     *
     * @param {*} player the player being rendered (button)
     * @returns extra player className
     */
    extraPlayerClass(player) {
        // ignore if we're not on the players on ice tab
        if (this.props.form.item !== 'players_on_ice') {
            return '';
        }

        // if the goal scorer (player), assist 1, and assist 2 aren't the current player (those have their own classes)
        if (![this.props.player, this.props.form.assist_1, this.props.form.assist_2].includes(player.id)) {
            // if the current player is selected -- otherwise all non-selected players would have the extra-player class,
            // not just the selected players.
            if (this.props.form.players_on_ice.includes(player.id)) {
                return 'extra-player';
            }
        }

        return '';
    }

    types() {
        return [
            'even', 'power_play',
            'short_handed', 'extra_attacker',
            'delayed_penalty', 'empty_net'
        ];
    }

    shotTypes() {
        return [
            'wrist', 'snap', 'slap', 'one_timer',
            'backhand', 'rebound', 'deflection', 'tap_in',
            'deke_to_forehand', 'deke_to_backhand', 'jam', 'other'
        ];
    }

    renderTypes() {
        return this.types().map((t) => {
            return (
                <div
                    key={t}
                    onClick={() => this.setItem('value', t)}
                    className={`btn ${this.props.form.value === t ? 'active' : ''}`}>
                    <span>{Strings.titleize(t)}</span>
                </div>
            );
        });
    }

    renderShotTypes() {
        return this.shotTypes().map((t) => {
            return (
                <div
                    key={t}
                    onClick={() => this.setItem('shot_type', t)}
                    className={`btn ${this.props.form.shot_type === t ? 'active' : ''}`}>
                    <span>{Strings.titleize(t)}</span>
                </div>
            );
        });
    }

    renderAssistSections() {
        if (!this.props.defense) {
            return ['assist_1', 'assist_2'].map((section) => {
                return (
                    <div key={section} className="section">
                        <div className="inner">
                            <div className="label">{Strings.titleize(section)}</div>
                            <div className="btn-row-12 btn-multi-line player-list">
                                {this.renderPlayers(section)}
                            </div>
                        </div>
                    </div>
                );
            });
        } else {
            return '';
        }
    }

    changeItem(item) {
        Dispatcher.dispatch({
            type: 'change-goal-modal-item',
            data: item
        });
    }

    renderTopItems() {
        const offenseOnly = ['assist_1', 'assist_2', 'screen']; // we don't use screen anymore below, but we were told to keep the code that we can

        return ['assist_1', 'assist_2', 'players_on_ice'].map((item) => {
            if (!this.props.defense || !offenseOnly.includes(item)) {
                return (
                    <div
                        key={item}
                        onClick={() => this.changeItem(item)}
                        className={`btn ${this.props.form.item === item ? 'active' : ''}`}>
                        <span>{Strings.titleize(item)}</span>
                    </div>
                );
            } else {
                return '';
            }
        });
    }

    netClick(e) {
        Dispatcher.dispatch({
            type: 'goal-modal-net-click',
            data: Offset.parse(e.target, e)
        });
    }

    perc() {
        const format = Offset.formatPercentage(this.props.form.net);

        return {
            left: format.left,
            top: format.top
        };
    }

    renderPuck() {
        if (this.props.form.net) {
            const split = this.perc();

            return <div className="puck" style={{ left: split.left, top: split.top }}></div>;
        } else {
            return '';
        }
    }

    renderDefenseScreenToggle() {
        if (this.props.defense) {
            return (
                <div className="screened" style={{ float: 'right', textAlign: 'center' }}>
                    <div className="label">Goalie Was Screened</div>
                    <Toggle
                        onChange={() => this.setItem('goalie_screened', !this.props.form.goalie_screened)}
                        checked={this.props.form.goalie_screened} />
                </div>
            );
        } else {
            return '';
        }
    }

    close = () => {
        Dispatcher.dispatch({
            type: 'close-goal-modal'
        });
    }

    render() {
        return (
            <div className={`modal-overlay pen-modal goal-modal ${this.props.open ? 'open' : ''}`}>
                <div className="modal-content">
                    <div className="section">
                        <div className="btn-row-3 top-row">
                            {this.renderTopItems()}
                            {/* We were told to keep the code for screening if possible, in case we want to turn it back on */}
                            {/* {this.renderDefenseScreenToggle()} */}
                        </div>
                    </div>
                    <div className="section">
                        <div className="inner">
                            <div className="label">Players</div>
                            <div className="btn-row-12 btn-multi-line player-list">
                                {this.renderPlayers()}
                            </div>
                        </div>
                    </div>
                    <div className="section flex">
                        <div className="col-4 pr-1 sep-right">
                            <div className="inner">
                                <div className="label">Game Situation</div>
                                <div className="btn-row-2 btn-multi-line">
                                    {this.renderTypes()}
                                </div>
                            </div>
                        </div>
                        <div className="col-8 pl-1 sep-left">
                            <div className="inner">
                                <div className="label">Shot Type</div>
                                <div className="btn-row-4 btn-multi-line">
                                    {this.renderShotTypes()}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="section net-container">
                        <div className="inner">
                            <img onClick={(e) => this.netClick(e)} className="net" src="net.svg" alt="net" />
                            {this.renderPuck()}
                        </div>
                    </div>
                    <div className="section center">
                        <div className="btn-row-2">
                            <div className="btn save" onClick={() => this.handleSave()}>Save</div>
                            <div className="btn save red" onClick={() => this.close()}>Exit Don't Save</div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
