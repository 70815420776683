import React from 'react';
import Players from '../../lib/Players';
import SkaterRow from './SkaterRow';

export default class SkaterStats extends React.Component {
    renderSkaterStatRows() {
        let players = [];

        ['defense', 'forward'].forEach((position) => {
            this.props.players[position].forEach((player) => {
                if (player.active) {
                    players.push({ ...player, position: position });
                }
            });
        });

        Players.sort(players);

        return players.map((player, idx) => {
            return (
                <SkaterRow
                    key={idx}
                    player={player}
                    events={this.props.events}
                    teamName={this.props.teamName} />
            );
        });
    }

    render() {
        return (
            <table style={{ width: '100%' }}>
                <thead>
                    <tr>
                        <th>Skaters</th>
                        <th>Pos</th>
                        <th>G</th>
                        <th>A</th>
                        <th>Pts</th>
                        <th>+/-</th>
                        <th>TSOG</th>
                        <th>TSATT</th>
                        <th>FO #</th>
                        <th>FO %</th>
                        <th>BLK</th>
                        <th>CHK</th>
                        <th>PIM</th>
                    </tr>
                </thead>
                <tbody>
                    {this.renderSkaterStatRows()}
                </tbody>
            </table>
        );
    }
}