import React from 'react'
import Dispatcher from '../dispatchers/Dispatcher';

const endPenalty = () => {
    Dispatcher.dispatch({ type: 'manual-close-penalty' });
}

const cancel = () => {
    Dispatcher.dispatch({ type: 'close-manual-close-penalty-modal' });
}

export default function ManualClosePenaltyModal({ open }) {
    return (
        <div className={`modal-overlay team ${open ? 'open' : ''}`}>
            <div className="modal-content">
                <div className="section">
                    <h1>Are you sure you want to end this penalty?</h1>
                    <div className="btn-row-6-stack">
                        <div className="btn" onClick={endPenalty}>
                            <span>End Penalty</span>
                        </div>
                        <div className="btn" onClick={cancel}>
                            <span>Cancel</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}