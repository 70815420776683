import React from 'react';
import Dispatcher from '../dispatchers/Dispatcher';

export default class OvertimeGoalModal extends React.Component {
    end() {
        Dispatcher.dispatch({
            type: 'end-game',
            data: {
                time: {
                    powerPlaysCount: this.props.time.powerPlaysCount,
                    penaltyKillsCount: this.props.time.penaltyKillsCount
                }
            }
        });
    }

    continue() {
        Dispatcher.dispatch({ type: 'close-modal' });
    }

    render() {
        return (
            <div className={`modal-overlay other ${this.props.open ? 'open' : ''}`}>
                <div className="modal-content">
                    <div className="section">
                        <h1>End Game?</h1>
                        <div className="btn-row-6-stack">
                            <div className="btn" onClick={() => this.end()}>
                                <span>Yes - End Game</span>
                            </div>
                            <div className="btn" onClick={() => this.continue()}>
                                <span>No - Continue Game</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
