import React from 'react';

export default class Block extends React.Component {

    updateValue = (event, fieldName) => {
        this.props.updateEventData(this.props.index, fieldName, event.target.value);
    }

    render() {
        return (
            <div className="event-col">
                <label>Result</label>
                <select name="result" className="form-control" value={this.props.event.value} onChange={(e) => this.updateValue(e, 'value')}>
                    <option value='block'>Block</option>
                    <option value='clean_block'>Clean block</option>
                    <option value='deflect_in_play'>Deflect in play</option>
                    <option value='deflect_out_of_play'>Deflect out of play</option>
                </select>
            </div>
        );
    }
}
