import React from 'react'
import TaggerHeaderPenaltyClose from './TaggerHeaderPenaltyClose';
import TaggerHeaderTeamStatsPenalty from './TaggerHeaderTeamStatsPenalty';

export default function TaggerHeaderTeamStatsPenaltiesContainer({ penalties, showNumber, side }) {
    return (
        <div className="penalties">
            <div className="pen-group">
                <div className="label">
                    Penalties
                    { penalties.length ? <TaggerHeaderPenaltyClose side={side} /> : null }
                </div>
                {
                    penalties.map((p, idx) => {
                        return (
                            <TaggerHeaderTeamStatsPenalty
                                key={idx}
                                penalty={p}
                                showNumber={showNumber} />
                        );
                    })
                }
            </div>
        </div>
    )
}