import React from 'react';

export default class Faceoff extends React.Component {

    updateValue = (event, fieldName) => {
        this.props.updateEventData(this.props.index, fieldName, event.target.value)
    }

    renderResultOptions() {
        return this.props.game.configuration.faceoff.actions.map((option, i) => {
            return <option key={i} value={option.value}>{option.display_name}</option>
        });
    }

    render() {
        return (
            <>
                <div className="event-col">
                    <label>Result</label>
                    <select name="result" className="form-control event-input" value={this.props.event.result} onChange={(e) => this.updateValue(e, 'result')}>
                        {this.renderResultOptions()}
                    </select>
                </div>

                <div className="event-col">
                    <label>Opponent Hand</label>
                    <select name="opponent_hand" className="form-control event-input" value={this.props.event.opponent_hand} onChange={(e) => this.updateValue(e, 'opponent_hand')}>
                        <option value=''></option>
                        <option value='left'>LEFT</option>
                        <option value='right'>RIGHT</option>
                    </select>
                </div>
            </>
        );
    }
}
