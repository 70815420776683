import React from 'react';
import Dispatcher from '../../dispatchers/Dispatcher'
import EventCard from './EventCard';
import SaveUpdatedEventsModal from './SaveUpdatedEventsModal';

export default class EventEditor extends React.Component {
    constructor(props) {
        super(props)

        this.state = {
            period: null,
            game: this.props.game,
            events: this.props.game.events,
            showSaveModal: false,
            eventsToDelete: [],
        }
    }

    componentDidMount() {
        this.scrollToBottom();
    }

    scrollToBottom = () => {
        this.el.scrollIntoView({ behavior: 'smooth' });
    }

    updateEventData = (i, fieldName, value) => {
        // console.log('Updating event data in parent');
        // console.log(this.state.events[i])
        // console.log(fieldName)
        // console.log(value)

        let updatedEvents = [...this.state.events];

        // If we are updating player values, we need to convert the value to an integer so the in-game stats
        // can still track them
        if (fieldName === 'player' || fieldName === 'assist_1' || fieldName === 'assist_2') {
            updatedEvents[i] = {...updatedEvents[i], [fieldName]: parseInt(value)}
        } else {
            updatedEvents[i] = {...updatedEvents[i], [fieldName]: value}
        }

        this.setState({
            events: updatedEvents
        })
    }

    renderEvents() {
        return this.state.events.map((event, i) => {
            return <EventCard 
                        key={i}
                        event={event}
                        game={this.state.game}
                        index={i}
                        updateEventData={this.updateEventData}
                        addEventToDelete={this.addEventToDelete}
                        removeEventToDelete={this.removeEventToDelete}
                        willDelete={this.state.eventsToDelete.includes(i)}
                    />
        });
    }

    addEventToDelete = (index) => {
        this.setState({ eventsToDelete: [...this.state.eventsToDelete, index] })
    }

    removeEventToDelete = (index) => {
        this.setState({ eventsToDelete: [...this.state.eventsToDelete.filter((events, i) => i !== index)] })
    }

    removeEventsMarkedForDeletion = () => {
        let finalEventList = this.state.events.filter(( event, i ) => {
            return !this.state.eventsToDelete.includes(i);
        });

        return finalEventList;
    }

    saveUpdatedEventData = () => {
        Dispatcher.dispatch({
            type: 'save-updated-event-data',
            data: {
                events: this.removeEventsMarkedForDeletion()
            }
        });
    }

    renderActionButtons = () => {
        return(
            <>
                <div className="btn-row-10"></div>
                <div className="btn right blue" style={{ float: 'right', width: '275px' }} onClick={() => Dispatcher.dispatch({ type: 'switch-view', data: 'tagger' })}>
                    <span>Close without saving changes</span>
                </div>

                {/* If we want to return to having a confirmation modal before saving
                <div className="btn left green" style={{ float: 'left', width: '275px' }} onClick={() => this.setState({ showSaveModal: true })}>
                    <span>Save & overwrite event data</span>
                </div> */}

                <div className="btn left green" style={{ float: 'left', width: '275px' }} onClick={this.saveUpdatedEventData}>
                    <span>Save & overwrite event data</span>
                </div>
            </>
        )
    }

    render() {
        return (
            <div id="tagger" className="game-report">
                {this.renderActionButtons()}
                <br />

                <h1>Edit Events</h1>
                <div className="events-container">
                    {this.renderEvents()}
                    <div ref={el => { this.el = el; }} />
                </div>

                <br />
                {this.renderActionButtons()}

                <SaveUpdatedEventsModal isOpen={this.state.showSaveModal} closeModal={() => this.setState({ showSaveModal: false })} saveData={this.saveUpdatedEventData} />
            </div>
        );
    }
}
