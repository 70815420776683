import React from 'react';

export default class Penalty extends React.Component {

    updateValue = (event, fieldName) => {
        this.props.updateEventData(this.props.index, fieldName, event.target.value)
    }

    infractions() {
        return [
            'Chrg/Brding', 'Checking', 'Checking from Behind', 'Cross Checking', 'Delay of Game',
            'Elbowing', 'Fighting', 'Goalie Interference', 'Head Contact', 'High Sticking', 'Holding',
            'Hooking', 'Interference', 'Misconduct', 'Roughing', 'Slashing', 'Too Many Men', 'Tripping',
            'Unsports. Conduct', 'Other'
        ];
    }

    renderInfractions() {
        return this.infractions().map((infraction, i) => {
            return (
                <option key={i} value={infraction}>{infraction}</option>
            );
        });
    }

    render() {
        return (
            <>
                <div className="event-col">
                    <label>Infraction</label>
                    <select name="infraction" className="form-control event-input" value={this.props.event.infraction} onChange={(e) => this.updateValue(e, 'infraction')}>
                        <option value=''></option>
                        {this.renderInfractions()}
                    </select>
                </div>

                <div className="event-col">
                    <label>Zone</label>
                    <select name="zone" className="form-control event-input" value={this.props.event.zone} onChange={(e) => this.updateValue(e, 'zone')}>
                        <option value=''></option>
                        <option value='defensive'>Defensive</option>
                        <option value='neutral'>Neutral</option>
                        <option value='offensive'>Offensive</option>
                    </select>
                </div>
            </>
        );
    }
}
