import React from 'react';
import Dispatcher from '../dispatchers/Dispatcher';
import Offset from '../lib/Offset';

export default class InterfaceRink extends React.Component {

    constructor(props) {
        super(props)

        this.state = {
            /**
             * The current position/coordinates of the puck
             */
            puck: null
        };
    }

    changeDirection() {
        Dispatcher.dispatch({ type: 'change-direction' });
    }

    handleButtonPress() {
        window.buttonPressTimer = window.setTimeout(() => {
            Dispatcher.dispatch({ type: 'change-direction' });
        }, 750);
    }

    handleButtonRelease () {
        window.clearTimeout(window.buttonPressTimer);
    }

    handleClick(area, e, name) {
        let target = area;

        let isTeam;
        let lockedSpot = true;
        let dir = null;

        if (target === 'free') {
            lockedSpot = false;

            // the only way to get the scroll position of the main tagger itself is from the parent html tag,
            // not any of the child divs.
            target = Offset.parse(document.getElementById('rink'), e, document.querySelector('html').scrollTop);

            const percX = target.split(',')[0];

            if (this.props.direction === 'right') {
                isTeam = percX > 0.5;
            } else {
                isTeam = percX < 0.5;
            }

            dir = percX < 0.5 ? 'left' : 'right';

            // when we have a faceoff, the user can't click freely.
            if (this.props.faceoff || !this.props.iceLock) {
                return;
            }
        }

        if (name) { // this the same as above checking for 'free' -- faceoffs
            const side = this.props.direction;

            if (name === 'center') {
                dir = 'center';
            } else {
                const split = name.split('_');

                dir = split[split.length - 1] === side ? 'attacking' : 'defending';
            }
        }

        const danger = e.target.dataset.danger || null;

        // Since giveaways/takeaways can now use the ice, we need to override all previous logic and
        // explicitly set the team to our team every time we have one of those eventss
        if (this.props.lockedEvent && ['turnover', 'takeaway'].includes(this.props.lockedEvent.type)) {
            isTeam = true;
        }

        const sendClick = () => {
            Dispatcher.dispatch({
                type: 'ice-clicked',
                data: {
                    target: target,
                    playerLock: this.determinePlayerLock(isTeam),
                    isTeam: isTeam,
                    lockedSpot: lockedSpot,
                    time: this.props.time,
                    side: dir,
                    danger: danger,
                    zoneName: name
                }
            });
        }

        // if the user clicked a faceoff spot, just send the click and return, otherwise
        // load up the puck position in state
        if (typeof target === 'number') {
            sendClick();
            return;
        } else {
            this.setState({ puck: target });
        }

        /**
         * When the opponent clicked, we wait for 1 second so the puck sticks to the screen no matter what.
         * Then, we enter the loop of checking for the locked event and waiting to remove the check/puck
         * until we have no locked even in props.
         *
         * Otherwise, we send the click and initialize the checker to check for props to remove the puck
         * as soon as the user has completed the entire locked event (modal, etc.).
         */
        if (!isTeam) {
            /**
             * Whenever the ice is clicked for an opponent goal, we have to wait before registering the click
             * and sending the dispatcher action. If we don't do this the puck will not "stick" before the modal
             * opens... it will just open instantly. Erik wants the puck to stick on the ice before the modal opens.
             */
            if (this.props.lockedEvent && this.props.lockedEvent.type === 'goal') {
                window.setTimeout(sendClick, 500);
            } else {
                sendClick();
            }
        } else {
            sendClick();
        }

        this.initializeChecker();
    }

    determinePlayerLock(isTeam) {
        const eventType = !!this.props.lockedEvent ? this.props.lockedEvent.type : null
        if (!!eventType) {
            if (eventType === 'turnover') {
                return this.props.configuration.top_right.track_players_giveaways ? true : false;
            } else if (eventType === 'takeaway') {
                return this.props.configuration.top_right.track_players_takeaways ? true : false;
            } else if ((eventType === 'check' || eventType === 'backcheck')) {
                return this.props.configuration.top_right.track_players_check ? true : false;
            } else {
                return this.props.blockLock || isTeam;
            }
        } else {
            return this.props.blockLock || isTeam;
        }
    }

    /**
     * Loop every 250ms until there is no locked event prop. When it sees that no locked
     * event is present, it will clear the interval and remove the puck position from state.
     */
    initializeChecker = () => {
        window.puckInterval = window.setInterval(() => {
            if (!this.props.lockedEvent || this.props.lockedEvent.type === 'faceoff') {
                window.clearInterval(window.puckInterval);
                this.setState({ puck: null });
            }

        }, 500);
    }

    renderFaceoffGuides() {
        if (this.props.faceoff) {
            return [
                {section: 'top_left', position: 1}, {section: 'middle_top_left', position: 3}, {section: 'middle_top_right', position: 4},
                {section: 'top_right', position: 2}, {section: 'bottom_left', position: 2}, {section: 'middle_bottom_left', position: 4},
                {section: 'middle_bottom_right', position: 3}, {section: 'bottom_right', position: 1}, {section: 'center', position: 5}
            ].map((section, idx) => {
                return (
                    <div
                        key={idx}
                        onClick={(e) => this.handleClick(section.position, e, section.section)}
                        className={`faceoff-guide ${section.section} ${this.props.lockedEvent && this.props.lockedEvent.zoneName === section.section ? 'active-guide' : ''}`}>
                    </div>
                );
            });
        } else {
            return '';
        }
    }

    renderDangerZones() {
        return [
            {zone: 'high_danger', danger: 'high'}, {zone: 'mid_danger_top', danger: 'medium'},
            {zone: 'mid_danger_bottom', danger: 'medium'}, {zone: 'mid_danger', danger: 'medium'},

            {zone: 'high_danger left', danger: 'high'}, {zone: 'mid_danger_top left', danger: 'medium'},
            {zone: 'mid_danger_bottom left', danger: 'medium'}, {zone: 'mid_danger left', danger: 'medium'},
        ].map((zone,idx) => {
            return (
                <div
                    data-danger={zone.danger}
                    key={idx}
                    onClick={(e) => this.handleClick('free', e, null)}
                    className={`danger-zone ${zone.zone}`}>
                </div>
            );
        });
    }

    renderShot() {
        // if (!this.props.lockedEvent || !this.props.lockedEvent.ice || typeof this.props.lockedEvent.ice === 'number') {
        //     return '';
        // }

        /**
         * We now only check state for puck, since it's all controlled there instead of the store
         */
        if (!this.state.puck || (this.props.lockedEvent && this.props.lockedEvent.ice && typeof this.props.lockedEvent.ice === 'number')) {
            return '';
        }

        // const shot = Offset.formatPercentage(this.props.lockedEvent.ice);
        const shot = Offset.formatPercentage(this.state.puck);

        return (
            <div className="shot" style={{ left: shot.left, top: shot.top }}></div>
        );
    }

    skipFaceoff() {
        Dispatcher.dispatch({
            type: 'skip-faceoff'
        });
    }

    /**
     * Next to the rink direction button we display an action button based on if the faceoff is locked or not.
     * In that case we show the Skip Faceoff button, otherwise we show the Video Stamp button.
     */
    renderRinkActionButton() {
        if (this.props.faceoff) {
            return this.renderSkipFaceoffButton()
        } else {
            return null
            // return this.renderVideoStampButton()
        }
    }

    renderSkipFaceoffButton() {
        return (
            <div className={`btn flex-btn`} onClick={this.skipFaceoff}>
                <span>Skip Faceoff</span>
            </div>
        )
    }

    // renderVideoStampButton() {
    //     return (
    //         <div className='btn direction-indicator skip-faceoff-margin' onClick={this.videoStamp}>
    //             <div className='test'>Video Stamp</div>
    //         </div>
    //     )
    // }

    // videoStamp = () => {
    //     Dispatcher.dispatch({
    //         type: 'video-stamp',
    //         data: {
    //             time: this.props.time
    //         }
    //     })
    // }

    handleKickedOut() {
        Dispatcher.dispatch({
            type: 'faceoff-button-click',
            data: {
                item: 'result',
                value: 'kicked_out'
            }
        });
    }

    showGiveaway() {
        try {
            return this.props.configuration.top_right.actions.filter(a => {
                return a.value === 'turnover';
            }).length > 0;
        } catch (e) {
            console.log(e);
            return false;
        }
    }

    showTakeaway() {
        try {
            return this.props.configuration.top_right.actions.filter(a => {
                return a.value === 'takeaway';
            }).length > 0;
        } catch (e) {
            console.log(e);
            return false;
        }
    }

    showCheck() {
        try {
            return this.props.configuration.top_right.actions.filter(a => {
                return a.value === 'check';
            }).length > 0;
        } catch (e) {
            console.log(e);
            return false;
        }
    }

    showBackcheck() {
        try {
            return this.props.configuration.top_right.actions.filter(a => {
                return a.value === 'backcheck';
            }).length > 0;
        } catch (e) {
            console.log(e);
            return false;
        }
    }

    handleTopRightClick(conf, section) {
        let track, trackLocation, trackTeams = false;

        if (section === 'top_right') {
            if (conf.value === 'check' || conf.value === 'backcheck') {
                track = this.props.configuration.top_right.track_players_checks;
                trackLocation = this.props.configuration.top_right.track_location_checks;
            } else if (conf.value === 'turnover') {
                track = this.props.configuration.top_right.track_players_giveaways;
                trackLocation = this.props.configuration.top_right.track_location_giveaways;
            } else if (conf.value === 'takeaway') {
                track = this.props.configuration.top_right.track_players_takeaways;
                trackLocation = this.props.configuration.top_right.track_location_takeaways;
            }
        } else if (section === 'zone_exit' && this.props.configuration.zone_exit.track_teams) {
            trackTeams = true;
        } else if (section === 'zone_entry' && this.props.configuration.zone_entry.track_teams) {
            trackTeams = true;
        }

        Dispatcher.dispatch({
            type: 'interface-button-click',
            data: {
                section: section,
                time: this.props.time,
                track_players: track,
                track_teams: trackTeams,
                track_location: trackLocation,
                ...conf,
            }
        });
    }

    giveawayConf() {
        return this.props.configuration.top_right.actions.filter(a => a.value === 'turnover')[0]
    }

    takeawayConf() {
        return this.props.configuration.top_right.actions.filter(a => a.value === 'takeaway')[0]
    }

    checkConf() {
        return this.props.configuration.top_right.actions.filter(a => a.value === 'check')[0]
    }

    backcheckConf() {
        return this.props.configuration.top_right.actions.filter(a => a.value === 'backcheck')[0]
    }

    active(section, conf) {
        if (!this.props.playerLock && !this.props.iceLock) {
            return '';
        } else if (this.props.lockedEvent.type === section && this.props.lockedEvent.value === conf.value) {
            return 'active';
        } else if (conf.value === this.props.lockedEvent.type) {
            return 'active';
        } else {
            return 'inactive';
        }
    }

    battleActive() {
        if (!!this.props.lockedEvent && this.props.lockedEvent.type === 'battle' && (this.props.playerLock || this.props.outcomeLock)) {
            return 'active';
        } else {
            return 'inactive';
        }
    }

    logGenericEvent() {
        Dispatcher.dispatch({
            type: 'log-generic-event',
            data: {
                time: this.props.time
            }
        })
    }

    logBattleEvent() {
        const trackPlayer = this.props.configuration.battle.track_player;
        const trackOutcome = this.props.configuration.battle.track_outcome;

        Dispatcher.dispatch({
            type: 'interface-button-click',
            data: {
                section: 'battle',
                value: 'battle',
                time: this.props.time,
                track_players: trackPlayer,
                track_outcome: trackOutcome
            }
        })
    }

    render() {
        return (
            <div
                className="section"
                id="ice">

                <div style={{ display: 'flex', gap: '2em' }}>

                    {this.renderRinkActionButton()}

                    {
                        !this.props.faceoff && (
                            <span style={{ flex: 1, display: 'flex', gap: '1em' }}>
                                {
                                    <>
                                        {this.showGiveaway() && (
                                            <>
                                                <div className={`btn flex-btn ${this.active('top_right', this.giveawayConf())}`} onClick={() => this.handleTopRightClick(this.giveawayConf(), 'top_right')}>
                                                    <span>GIVEAWAY</span>
                                                </div>
                                            </>
                                        )}
                                    
                                        {this.showTakeaway() && (
                                            <>
                                                <div className={`btn flex-btn ${this.active('top_right', this.takeawayConf())}`} onClick={() => this.handleTopRightClick(this.takeawayConf(), 'top_right')}>
                                                    <span>TAKEAWAY</span>
                                                </div>
                                            </>
                                        )}
                                    </>
                                }
                            </span>
                        )
                    }

                    <div
                        onTouchStart={() => this.handleButtonPress()}
                        onTouchEnd={() => this.handleButtonRelease()}
                        onMouseDown={() => this.handleButtonPress()}
                        onMouseUp={() => this.handleButtonRelease()}
                        onMouseLeave={() => this.handleButtonRelease()}
                        className={`btn flex-btn direction-indicator ${this.props.time.minuteBump ? 'highlighted' : ''}`}>
                        <span>
                            <img
                                className={`direction ${this.props.direction === 'left' ? 'left' : 'right'}`}
                                src="ui-direction-arrow.svg"
                                style={{ width: '115px' }}
                                alt="rink" />
                        </span>
                    </div>

                    {
                        this.props.faceoff && (
                            <div className={`btn flex-btn`} onClick={this.handleKickedOut}>
                                <span>Kicked Out</span>
                            </div>

                        )
                    }

                    {
                        !this.props.faceoff && (
                            <span style={{ flex: '1', display: 'flex', gap: '1em' }}>
                                {
                                    this.showCheck() && !this.showBackcheck() && (
                                        <div className={`btn flex-btn ${this.active('top_right', this.checkConf())}`} onClick={() => this.handleTopRightClick(this.checkConf(), 'top_right')}>
                                            <span>CHECK</span>
                                        </div>
                                    )
                                }

                                {
                                    this.showBackcheck() && (
                                        <div className={`btn flex-btn ${this.active('top_right', this.backcheckConf())}`} onClick={() => this.handleTopRightClick(this.backcheckConf(), 'top_right')}>
                                            <span>BACKCHECK</span>
                                        </div>
                                    )
                                }
                                
                                {
                                    !this.showCheck() && !this.showBackcheck() && (
                                        <div style={{ flex: '1' }}></div>
                                    )
                                }

                                {
                                    this.props.eventButton && !this.props.battleButton && (
                                        <div className={`btn flex-btn`} onClick={() => this.logGenericEvent()}>
                                            <span>EVENT</span>
                                        </div>
                                    )
                                }

                                {
                                    this.props.battleButton && (
                                        <div className={`btn flex-btn ${this.battleActive()}`} onClick={() => this.logBattleEvent()}>
                                            <span>BATTLE</span>
                                        </div>
                                    )
                                }
                                
                                {
                                    !this.props.eventButton && !this.props.battleButton && (
                                        <div style={{ flex: '1' }}></div>
                                    )
                                }
                            </span>
                        )
                    }

                </div>

                <div className={`rink ${(this.props.playerLock || this.props.faceoffLock) && !this.props.iceLock ? 'inactive' : ''}`} style={{ position: 'relative' }}>
                    {this.renderFaceoffGuides()}
                    {this.renderDangerZones()}
                    <img
                        id="rink"
                        data-danger="low"
                        onClick={(e) => this.handleClick('free', e, null)}
                        src={`${this.props.direction === 'right' ? 'rink-ltr.svg' : 'rink-rtl.svg'}`}
                        alt="Perfectly Measured Ice Rink"/>
                    {this.renderShot()}
                </div>
            </div>
        );
    }
}
