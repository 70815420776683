/**
 * ---------------------------------------------------------------------------------
 *
 * .d8888. db   d8b   db d888888b d888888b  .o88b. db   db d8888b.  .d88b.  db    db
 * 88'  YP 88   I8I   88   `88'   `~~88~~' d8P  Y8 88   88 88  `8D .8P  Y8. `8b  d8'
 * `8bo.   88   I8I   88    88       88    8P      88ooo88 88oooY' 88    88  `8bd8'
 *   `Y8b. Y8   I8I   88    88       88    8b      88~~~88 88~~~b. 88    88  .dPYb.
 * db   8D `8b d8'8b d8'   .88.      88    Y8b  d8 88   88 88   8D `8b  d8' .8P  Y8.
 * `8888Y'  `8b8' `8d8'  Y888888P    YP     `Y88P' YP   YP Y8888P'  `Y88P'  YP    YP
 *
 * ---------------------------------------------------------------------------------
 */
import moment from 'moment';

import { ReduceStore } from 'flux/utils';
import Dispatcher from '../dispatchers/Dispatcher';
import Timer from '../lib/Timer';
import Storage from '../lib/Storage';
import Client from '../lib/Client';
import Penalty from '../lib/Penalty';
import parseGoal from '../lib/Goal';

class GameStore extends ReduceStore {
    constructor() {
        super(Dispatcher);
    }

    getInitialState() {
        return {
            taggerId: this.fetchTaggerId(),
            events: [],
            bottomMenu: 'default',
            bottomItem: null,
            modal: null,
            view: 'select',
            currentGame: null,
            direction: 'right',
            clockStopped: false,
            shots: {
                team: 0,
                opponent: 0
            },
            goals: {
                team: 0,
                opponent: 0
            },
            message: '',
            faceoffAlert: null,
            flagEvent: false,
            completed: false,
            sidebar: false,
            clockRunning: false,
            taggerPaused: false,
            offsettingPenalty: false,
            penaltyShotPlayer: null,
            penaltyShotTeam: null,
            shootoutGoals: {
                team: 0,
                opponent: 0
            },
            tagger_type: 'event',
            goalModal: this.goalModal(),
            taggerTarget: null,
            faceoffBump: false,
            uploading: false,
            alternateJerseys: false,
            isSyncStats: false,
            penaltyType: 1,
            iceLock: false,
            outcomeLock: false,
            playerLock: false,
        };
    }

    fetchTaggerId() {
        var taggerId = Storage.getTaggerId();
        if (taggerId !== null) {
            return taggerId;
        } else {
            return Storage.generateTaggerId();
        }
    }

    goalModal() {
        return {
            assist_1: null,
            assist_2: null,
            players_on_ice: [],
            screen: null,
            value: null,
            item: 'assist_1',
            net: null,
            shot_type: null,
            goalie_screened: false
        };
    }

    initialFaceoff(time, useDuration) {
        /**
         * We use this same function for all faceoff events that need to take place. When a period ends
         * we need to use the `duration` param, not the time.minutes, since we need to restart the clock
         * as <duration>:00, in other words this is the first faceoff of the next period.
         */
        let initialFaceoffTime = useDuration ? `${time.duration}:00` : `${time.minutes}:${time.seconds}`;

        const minutes = Number.parseInt(initialFaceoffTime.split(':')[0])

        if (initialFaceoffTime[0] !== '0' && minutes < 10) {
            initialFaceoffTime = `0${initialFaceoffTime}`;
        }

        return {
            result: null,
            opponent_hand: null,
            player: null,
            ice: null,
            type: 'faceoff',
            period: time.period,
            time: initialFaceoffTime,
            ...this.numberOfPlayers(time)
        };
    }

    calculateTimeSinceStart() {
        const now = moment(new Date());
        const gameStart = moment(window.gameStartTime);
        let minutesStr = "";
        let secondsStr = "";

        const minutes = now.diff(gameStart, 'minutes')
        const seconds = now.diff(gameStart, 'seconds')

        if (minutes < 10) {
            minutesStr = `00${minutes}`;
        } else if (minutes < 100) {
            minutesStr = `0${minutes}`;
        } else {
            minutesStr = `${minutes}`;
        }

        let secondsMod = seconds % 60;
        if (secondsMod%60 < 10) {
            secondsStr = `0${secondsMod}`;
        } else {
            secondsStr = `${secondsMod}`;
        }

        return `${minutesStr}:${secondsStr}`;
    }

    calculateSecondsSinceStart() {
        const now = moment(new Date())
        const gameStart = moment(window.gameStartTime)

        const seconds = now.diff(gameStart, 'seconds')

        return seconds
    }

    /**
     * Return the current real time in ISO string format.
     */
    realTime() {
        return {
            real_time: Timer.offsetRealTime(),
            time_since_start: this.calculateTimeSinceStart(),
            seconds_since_start: this.calculateSecondsSinceStart()
        };
    }

    /**
     * Return state attributes for starting a faceoff
     *
     * `isCenterIce` denotes whether or not to lock the faceoff position by default
     * to the center of the ice for the beginning of periods and for goals.
     */
    faceoff(time, isCenterIce, useDuration) {
        let dynamic = {};

        /**
         * When the faceoff is marked for center ice we turn off the ice lock
         * and set the individual attributes for the `lockedEvent` for the faceoff
         * we're dealing with.
         */
        if (isCenterIce) {
            dynamic = {
                iceLock: false,
                lockedEvent: {
                    ...this.initialFaceoff(time, useDuration),
                    ice: 5,
                    direction: 'center',
                    side: 'center',
                    zoneName: 'center'
                }
            };
        } else {
            dynamic = {
                iceLock: true,
                lockedEvent: this.initialFaceoff(time, useDuration)
            };
        }

        return {
            ...dynamic,
            topMenu: 'faceoff',
            playerLock: true,
            faceoffLock: true,
            clockStopped: false,
            modal: null,
            bottomMenu: 'default',
            bottomItem: null
        };
    }

    message(msg) {
        return msg || 'Event Saved';
    }

    numberOfPlayers(time) {
        return {
            number_of_players_on_ice: { ...time.numberOfPlayersOnIce }
        };
    }

    bottomRowModalSave(state, data) {
        // First check if this is a zone event. We are reusing this portion to make use of the
        // existing team modal which sends it here.
        if (state.lockedEvent && (state.lockedEvent.type === 'zone_exit' || state.lockedEvent.type === 'zone_entry')) {
            return {
                ...state,
                ...this.defaultState(),
                events: state.events.concat({
                    ...state.lockedEvent,
                    team: data.team,
                    attack_direction: state.direction
                }),
                message: this.message()
            };
        }

        let penaltyNumberOfPlayers = this.numberOfPlayers(data.time).number_of_players_on_ice;

        if (state.bottomItem === 'penalty') {

            const bottomSavePenaltyTypeObject = Penalty.selectedPenaltyTypeOptions(state.penaltyType)

            // from time store of what we ignore -- i.e. not putting on the board that would subtract from the players on ice -- therefore we don't have to bump the number by 1...
            // if (action.data.penaltyType === penaltyTypeObj.misconduct || action.data.penaltyType === 'Game' || action.data.penaltyType === 'Penalty Shot' || action.data.penaltyType === null || action.data.offsetting) {
            const keepCountTheSame = data.penaltyShot || data.penaltyType === bottomSavePenaltyTypeObject.misconduct || data.penaltyType === 'Game' || data.penaltyType === 'Penalty Shot' || data.penaltyType === null || data.offsetting

            penaltyNumberOfPlayers = {
                ...penaltyNumberOfPlayers,
                // [data.side]: ++penaltyNumberOfPlayers[data.side]
                // [data.side]: data.penaltyShot ? penaltyNumberOfPlayers[data.side] : ++penaltyNumberOfPlayers[data.side]
                [data.side]: keepCountTheSame ? penaltyNumberOfPlayers[data.side] : ++penaltyNumberOfPlayers[data.side]
            };
        }

        const events = state.events.concat({
            ...data,
            type: state.bottomItem,
            period: data.time.period,
            time: `${data.time.minutes}:${data.time.seconds}`,
            number_of_players_on_ice: penaltyNumberOfPlayers,
            ...this.realTime()
        });

        // for penalties we have an `offsetting` attribute that we
        // have to keep the modal the same but with certain locked
        // items i.e. team, penalty length, etc.
        if (data.offsetting && !state.offsettingPenalty) {
            return { ...state, events: events, offsettingPenalty: true };
        } else {
            let modal = data.penaltyShot ? 'penalty-shot' : null;

            // this is what we will reset the bottom item to... if we are adding another penalty this has to be 'penalty',
            // since the event type gets sent here from the bottomItem... if that is null the penalty event will be null
            // on the save & add penalty modal feature.
            let bottomItemPenaltyEval = null;

            // check if the user wants to add another penalty now... whenever that happens all we have to do is save the data
            // like normal but then keep the modal open
            if (data.addAnotherPenalty) {
                // keep the modal the same so they can enter another penalty, don't set it to null or penalty shot modal.
                modal = state.modal;
                // keep the bottom item as penalty so the event can save properly
                bottomItemPenaltyEval = 'penalty';
            }

            // if it's a penalty shot and the team is the same as our team, remove the player, otherwise keep the player...
            const player = (data.penaltyShot && data.team === state.team.name) ? null : data.player;

            const team = data.penaltyShot ? ( data.team === state.team.name ? state.opponent_team_name : state.team.name ) : null;

            return {
                ...state,
                bottomMenu: 'default',
                message: this.message(),
                events: events,
                ...this.faceoff(data.time), // this resets bottomItem to null, move bottomItem below...
                modal: modal,
                offsettingPenalty: false,
                penaltyShotPlayer: player,
                penaltyShotTeam: team,
                bottomItem: bottomItemPenaltyEval
            };
        }
    }

    /**
     * The default state that should be active after we log a lockedEvent
     * as an actual event.
     */
    defaultState() {
        return {
            lockedEvent: null,
            topMenu: 'default',
            bottomMenu: 'default',
            bottomItem: null,
            modal: null,
            iceLock: false,
            playerLock: false,
            faceoffLock: false,
            sidebar: false
        };
    }

    endGame(state) {
        Storage.completeGame({
            ...state,
            completed: true
        });

        return {
            ...this.defaultState(),
            completed: true,
            view: 'select',
            message: 'Game Completed'
        };
    }

    changeDirection(direction) {
        return direction === 'left' ? 'right' : 'left';
    }

    flag(state, data) {
        return {
            ...state,
            events: state.events.concat({
                type: data.type,
                time: `${data.time.minutes}:${data.time.seconds}`,
                ...this.numberOfPlayers(data.time),
                period: data.time.period,
                ...this.realTime(),
                attack_direction: state.direction
            }),
            message: this.message(data.message),
            flagEvent: true
        };
    }

    undoMessage(lastEvent) {
        let event = '';

        switch(lastEvent.type) {
        case 'turnover':
            event = 'Giveaway';
            break;
        default:
            event = lastEvent.type.split('_').join(' ');
        }

        return `${event} Undone`;
    }

    reduce(state, action) {
        switch (action.type) {
        case 'skip-faceoff':
            Timer.startClock();
            return {
                ...state,
                ...this.defaultState(),
                faceoffAlert: true
            };
        case 'interface-button-click':
            if (state.lockedEvent && (state.lockedEvent.value === action.data.value || state.lockedEvent.type === action.data.value)) {
                return { ...state, ...this.defaultState() };
            }

            if (action.data.value === 'video_stamp') {
                return this.flag(state, { ...action.data, type: 'video_stamp' });
            }

            // the object we will be setting no matter what, either to lock or event
            const obj = {
                type:   action.data.section === 'top_right' ? action.data.value : action.data.section,
                value:  action.data.section === 'top_right' ? null : action.data.value,
                period: action.data.time.period,
                time:   `${action.data.time.minutes}:${action.data.time.seconds}`,
                /**
                 * Here we are setting the team to the user's team. When track_teams is on, this will be overwritten
                 * by the modal that asks for the team. Otherwise, it will get set to the default user's team since
                 * all other events (check, giveaway, takeaway) have the context of the user's team by default.
                 */
                team: state.team.name,
                ...this.numberOfPlayers(action.data.time),
                ...this.realTime(),
                attack_direction: state.direction
            };

            if (state.playerLock) {
                return { ...state };
            }

            if (action.data.track_players) {
                return {
                    ...state,
                    playerLock: true,
                    outcomeLock: action.data.track_outcome ? true : false,
                    iceLock: action.data.track_location,
                    lockedEvent: obj
                };
            } else if (action.data.track_location) {
                return {
                    ...state,
                    iceLock: true,
                    lockedEvent: obj
                };
            } else if (action.data.track_outcome) {
                // If tracking battle outcomes
                if (action.data.value === 'battle') {
                    return {
                        ...state,
                        modal: 'battle-outcome',
                        lockedEvent: obj
                    };
                // If tracking zone exit/entry outcomes, make sure it's a "FAIL" type of event before opening the outcome modal
                } else if (['no_control', 'chip_nz', 'clear_oz', 'flip', 'dump_chck'].includes(action.data.value)) {
                    return {
                        ...state,
                        modal: 'zone-event-outcome',
                        lockedEvent: obj
                    };
                } else if (action.data.value === 'dump_chng') {
                    return {
                        ...state,
                        modal: 'zone-event-outcome',
                        lockedEvent: {
                            ...obj,
                            outcome: 'no_possession'
                        }
                    }
                } else {
                    // For controlled Zone exits/entries, we can skip the outcome modal step
                    if (action.data.track_teams) { // If we still need to track the teams, open the team modal
                        return {
                            ...state,
                            modal: 'team',
                            lockedEvent: obj,
                        };  
                    } else { // Save the event now
                        return {
                            ...state,
                            ...this.defaultState(),
                            events: state.events.concat({
                                ...obj,
                            }),
                            message: this.message('Event Saved')
                        };
                    }
                }
            } else if (action.data.track_teams) {
                return {
                    ...state,
                    modal: 'team',
                    lockedEvent: obj
                };  
            } else {
                return {
                    ...state,
                    message: this.message(),
                    events: state.events.concat(obj)
                };
            }
        case 'player-button-click':
            if (state.bottomMenu === 'goal' && !state.iceLock) { // only launch the goal modal if it's a goal AND there's no ice lock
                return {
                    ...state,
                    modal: 'goal',
                    goalModal: {
                        ...state.goalModal,
                        players_on_ice: [action.data.player.id]
                    },
                    lockedEvent: {
                        ...state.lockedEvent,
                        player: action.data.player.id,
                        attack_direction: state.direction
                    }
                };
            } else if (!state.playerLock && !state.iceLock && !state.faceoffLock && !state.outcomeLock) {
                /**
                 * When we have no locks, there's no reason to do anything.
                 */
                return { ...state };
            } else {
                /**
                 * When we still have another lock besides player, add the player
                 * to the locked event and turn off player lock
                 *
                 * When it comes to goals, if the ice lock is still on,
                 * this is what will register as true and add the player
                 * to the locked event since the iceLock will still be true
                 * if they went from goal, to player, to ice cLick
                 */
                if (state.iceLock || state.faceoffLock) {
                    return {
                        ...state,
                        playerLock: false,
                        lockedEvent: {
                            ...state.lockedEvent,
                            player: action.data.player.id,
                            attack_direction: state.direction
                        }
                    };
                } else if (state.outcomeLock && state.lockedEvent.type === 'battle') {
                    return {
                        ...state,
                        modal: 'battle-outcome',
                        lockedEvent: {
                            ...state.lockedEvent,
                            player: action.data.player.id,
                        }
                    };
                } else {
                    /**
                     * When the user is kickd out of a faceoff we have to reset the locked event
                     * to needing a player & result, and registering the current locked event
                     * as an individual event i.e. faceoff kicked out
                     */
                    if (state.lockedEvent.result === 'kicked_out') {
                        return {
                            ...state,
                            playerLock: true,
                            faceoffLock: true,
                            lockedEvent: {
                                ...state.lockedEvent,
                                player: null,
                                result: null,
                                attack_direction: state.direction
                            },
                            events: state.events.concat(state.lockedEvent),
                            message: this.message()
                        };
                    } else {
                        /**
                         * Here we have no other locks and the player wasn't kicked out.
                         * This is where we log the faceoff as an event in itself and
                         * start the game again.
                         */
                        let dynamic = {};
                        let playerLock = false;

                        /**
                         * When the clock is manually frozen it typically means that when
                         * an event is logged we need to go into a faceoff. This is a way
                         * to say never start the clock inside of an action because it's
                         * waiting for a faceoff which will remove this flag itself.
                         */
                        if (!state.clockStopped) {
                            Timer.startClock();
                        } else {
                            /**
                             * Trigger faceoff mode and set the player lock to true since
                             * the default action here would be to obviously set it to false,
                             * however we need a player for a faceoff. Not doing this would make
                             * the app think the player has already been chosen for the faceoff.
                             */
                            dynamic = { ...this.faceoff(action.data.time) };
                            playerLock = true;
                        }

                        if (state.lockedEvent.type === 'faceoff') {
                            dynamic.faceoffBump = true;
                        }

                        // last click was player

                        return {
                            ...state,
                            playerLock: playerLock,
                            ...this.defaultState(),
                            ...dynamic,
                            message: this.message(),
                            faceoffAlert: state.lockedEvent.type === 'faceoff',
                            events: state.events.concat({
                                ...state.lockedEvent,
                                player: action.data.player.id,
                                attack_direction: state.direction
                            })
                        };
                    }
                }
            }
        case 'ice-clicked':
            let dynamicShot = {};

            /**
             * Whenever a user shoots, blocks, or misses we have to register the direction of the attack
             * in terms of physical location i.e. right/left side of the ice itself.
             */
            let dynamicEvent = {
                direction: state.direction,
                goalie: state.current_goalie,
                danger: action.data.danger,
                zoneName: action.data.zoneName,
                attack_direction: state.direction
            };

            const shotBottomCheck = state.configuration.track_secondary_actions ? state.bottomMenu : state.lockedEvent.type;

            /**
             * No matter what type of shot, whenever the user clicks the ice and we know what side
             * the shot was on we can log the shot. The `dynamicShot` gets logged wherever we are
             * updating the state.
             * The first check below on action data is that there's a player lock which is only
             * used for team shots. When the opponent shoots we also have to register that shot in state.
             */
            if (shotBottomCheck === 'shot') {
                const target = action.data.isTeam ? 'team' : 'opponent';

                /**
                 * Update the amount of shots for the given team in the game's shot object
                 */
                dynamicShot = {
                    shots: {
                        ...state.shots,
                        [target]: ++state.shots[target]
                    }
                };
            }

            /**
             * ZONE ENTRY / ZONE EXIT logic
             * Refactor this later. Or not...
             */
            if (state.lockedEvent.type === 'zone_entry' || state.lockedEvent.type === 'zone_exit') {
                if (state.configuration.zone_events.track_outcome) {
                    // These values for zone exit/entry prompt the outcome selection
                    if (['no_control', 'chip_nz', 'clear_oz', 'flip', 'dump_chck'].includes(state.lockedEvent.value)) {
                        return {
                            ...state,
                            iceLock: false,
                            modal: 'zone-event-outcome',
                            lockedEvent: {
                                ...state.lockedEvent,
                                ice: action.data.target,
                                side: action.data.side,
                                direction: state.direction,
                                zoneName: action.data.zoneName,
                                attack_direction: state.direction
                            }
                        };
                    } else if (state.lockedEvent.value === 'dump_chng') {   // dump changes are automatically assigned "no posession"
                        if (state.configuration.zone_events.track_teams) {
                            return {
                                ...state,
                                iceLock: false,
                                modal: 'team',
                                lockedEvent: {
                                    ...state.lockedEvent,
                                    ice: action.data.target,
                                    side: action.data.side,
                                    direction: state.direction,
                                    zoneName: action.data.zoneName,
                                    attack_direction: state.direction,
                                    outcome: 'no_possession'
                                }
                            };
                        } else {  // log the event immediately
                            return {
                                ...state,
                                ...this.defaultState(),
                                iceLock: false,
                                events: state.events.concat({
                                    ...state.lockedEvent,
                                    team: state.team.name,
                                    ice: action.data.target,
                                    side: action.data.side,
                                    attack_direction: state.direction,
                                    outcome: 'no_possession'
                                })
                            };
                        }
                    } else {   // not one of the values that prompts for outcome
                        if (state.configuration.zone_events.track_teams) {   // show team modal if needed
                            return {
                                ...state,
                                iceLock: false,
                                modal: 'team',
                                lockedEvent: {
                                    ...state.lockedEvent,
                                    ice: action.data.target,
                                    side: action.data.side,
                                    direction: state.direction,
                                    zoneName: action.data.zoneName,
                                    attack_direction: state.direction
                                }
                            };
                        } else {   // no team selection needed, save event
                            return {
                                ...state,
                                ...this.defaultState(),
                                iceLock: false,
                                events: state.events.concat({
                                    ...state.lockedEvent,
                                    team: state.team.name,
                                    ice: action.data.target,
                                    side: action.data.side,
                                    attack_direction: state.direction
                                }),
                                message: this.message('Event Saved')
                            };
                        }
                    }
                } else if (state.configuration.zone_events.track_teams) {       // not tracking outcome (poss/no poss) but we are tracking teams
                    return {
                        ...state,
                        iceLock: false,
                        modal: 'team',
                        lockedEvent: {
                            ...state.lockedEvent,
                            ice: action.data.target,
                            side: action.data.side,
                            direction: state.direction,
                            zoneName: action.data.zoneName,
                            attack_direction: state.direction
                        }
                    };
                } else {        // not tracking any extra info, save immediately
                    return {
                        ...state,
                        ...this.defaultState(),
                        iceLock: false,
                        events: state.events.concat({
                            ...state.lockedEvent,
                            team: state.team.name,
                            ice: action.data.target,
                            side: action.data.side,
                            attack_direction: state.direction
                        }),
                        message: this.message('Event Saved')
                    };
                }
            
            } else if (state.bottomMenu === 'goal') {
                const team = action.data.isTeam ? state.team.name : state.opponent_team_name;

                let goalModalDynamic = {};

                if (!action.data.isTeam) {
                    goalModalDynamic = {
                        goalModal: {
                            ...state.goalModal,
                            item: 'players_on_ice'
                        }
                    };
                }

                /**
                 * If there is no player lock, it means we can go ahead and launch the goal modal
                 */
                if (!state.playerLock) {
                    return {
                        ...state,
                        modal: 'goal',
                        goalModal: {
                            ...state.goalModal,
                            players_on_ice: action.data.isTeam ? [state.lockedEvent.player] : [],
                            item: action.data.isTeam ? 'assist_1' : 'players_on_ice'
                        },
                        lockedEvent: {
                            ...state.lockedEvent,
                            ice: action.data.target,
                            team: team,
                            player: action.data.isTeam ? state.lockedEvent.player : null,
                            attack_direction: state.direction
                        }
                    };
                }

                return {
                    ...state,
                    playerLock: action.data.isTeam,
                    iceLock: false,
                    modal: action.data.isTeam ? null : 'goal',
                    ...goalModalDynamic,
                    lockedEvent: {
                        ...state.lockedEvent,
                        ...dynamicEvent,
                        ice: action.data.target,
                        team: team,
                        attack_direction: state.direction
                    }
                };
            } else if (!action.data.lockedSpot && action.data.playerLock && !state.lockedEvent.player) {
                /**
                 * Here the user has clicked freely on the ice and should
                 * coorespond with an event placed on the ice i.e. shot, etc.
                 * When the user clicked on defense for shots we want to simply
                 * log the event with the ice position since we don't track their
                 * players. If it's on the "team" side we have to enable player lock
                 * and turn off ice lock, also logging ice position in the locked event.
                 *
                 * For blocks we have to lock the player no matter
                 * what but still log the team either way. The other
                 * use case is only ever if it's our team since we
                 * only track our player stats, however for block we
                 * want to know the shots we blocked and our shots
                 * that were blocked. Associated player (player lock)
                 * is always a player on our team.
                 *
                 * ---------------------------------------------------------------------
                 *
                 * NOTE: Above we're making sure they're on a:
                 *       1. free area in the ice (not a faceoff circle)
                 *       2. player lock
                 *       3. a locked event player - otherwise we can complete the event
                 *
                 */
                const team = action.data.isTeam ? state.team.name : state.opponent_team_name;

                return {
                    ...state,
                    ...dynamicShot,
                    playerLock: true,
                    iceLock: false,
                    lockedEvent: {
                        ...state.lockedEvent,
                        ...dynamicEvent,
                        ice: action.data.target,
                        team: team,
                        side: action.data.side,
                        attack_direction: state.direction
                    }
                };
            } else if (state.playerLock || state.faceoffLock) {
                /**
                 * Just like player click, if there are other locks we simply log the
                 * ice data into the locked event and wait for the other triggers to
                 * be cleared elsewhere.
                 */
                return {
                    ...state,
                    iceLock: false,
                    lockedEvent: {
                        ...state.lockedEvent,
                        ...state.dynamicEvent,
                        ice: action.data.target,
                        side: action.data.side,
                        direction: state.direction,
                        zoneName: action.data.zoneName,
                        attack_direction: state.direction
                    }
                };
            } else {
                /**
                 * Since order doesn't matter duing a faceoff we have to code in multiple
                 * places what to do when a user is kicked out of a faceoff.
                 */
                if (state.lockedEvent.result === 'kicked_out') {
                    return {
                        ...state,
                        playerLock: true,
                        faceoffLock: true,
                        iceLock: false,
                        lockedEvent: {
                            ...state.lockedEvent,
                            player: null,
                            result: null,
                            ice: action.data.target,
                            side: action.data.side,
                            attack_direction: state.direction
                        },
                        events: state.events.concat(state.lockedEvent),
                        message: this.message()
                    };
                } else {
                    if (!state.clockStopped) {
                        Timer.startClock();
                    }

                    let shotTeam = {};

                    // log team
                    if (!action.data.lockedSpot && !action.data.playerLock) {
                        shotTeam.team = state.opponent_team_name;
                    }

                    let dynamic = {};
                    let iceLock = false;

                    /**
                     * Also like player click we do not want to start the clock
                     * or be in a weird mode if we have `clockStopped` true since
                     * that tells us we are waiting for a faceoff to happen once
                     * all the needed locks are cleared. Also set ice lock to true
                     * since we need that for faceoffs.
                     */
                    if (state.clockStopped) {
                        dynamic = { ...this.faceoff(action.data.time) };
                        iceLock = true;
                    }

                    const lockedEventIcePlayer = {
                        ...state.lockedEvent,
                        // before unsetting the player, first check to see if we are on a faceoff.
                        // for faceoffs we would never want to unselect the player. the isTeam logic
                        // is pretty old so i don't remember every use-case... for now just manually check for faceoffs.
                        // see same logic for team below in return block.
                        player: action.data.isTeam || state.lockedEvent.type === 'faceoff' ? state.lockedEvent.player : null
                    };

                    // last click was faceoff circle
                    return {
                        ...state,
                        ...this.defaultState(),
                        ...dynamic,
                        ...dynamicShot, // register the shot for opponents
                        iceLock: iceLock,
                        message: this.message(),
                        faceoffAlert: state.lockedEvent.type === 'faceoff',
                        events: state.events.concat({
                            ...lockedEventIcePlayer,
                            ...shotTeam,
                            ...dynamicEvent,
                            team: action.data.isTeam || state.lockedEvent.type === 'faceoff' ? state.team.name : state.opponent_team_name,
                            ice: action.data.target,
                            side: action.data.side,
                            attack_direction: state.direction
                        })
                    };
                }
            }
        case 'bottom-menu-parent-click':
            if (state.playerLock && action.data.menu !== 'stop') {
                return { ...state };
            } else {
                let dGoal = {};

                // stop the clock right when stop is clicked
                if (action.data.menu === 'stop') {
                    if (!state.clockRunning) {
                        Timer.stopClock();
                    }
                }

                if (action.data.menu === 'goal') {
                    if (!state.clockRunning) {
                        Timer.stopClock();
                    }

                    dGoal = {
                        iceLock: true,
                        playerLock: true,
                        lockedEvent: {
                            type: 'goal',
                            value: null,
                            time: `${action.data.time.minutes}:${action.data.time.seconds}`,
                            ...this.numberOfPlayers(action.data.time),
                            ...this.realTime(),
                            period: action.data.time.period,
                            player: null,
                            ice: null,
                            assist_1: null,
                            assist_2: null,
                            attack_direction: state.direction
                        }
                    };
                }

                return {
                    ...state,
                    ...dGoal,
                    bottomMenu: action.data.menu
                };
            }
        case 'bottom-menu-item-click':
            let dynamic;

            /**
             * If we are dealing with the mini menu and the user clicks an individual item, it is only in the
             * context of another sub-button, which only affects the locked event's `value` as of right now.
             * Everything else should be able to stay the same, since this is only really feeding in that one
             * extra "sub" piece, setting the value, otherwise it would stay 'miss: miss' instead of 'miss:post_crossbar_in_play'.
             *
             * This should work for any type of mini menu sub-options, which right is only supported on 'miss'
             * during the 1st-level option.
             */

            

            if (state.bottomMenu === 'mini') {
                // The mini missed event needs to be able to toggle "post" on and off
                const postValue = state.lockedEvent && state.lockedEvent.value === 'post' ? 'miss' : action.data.item
                return {
                    ...state,
                    lockedEvent: {
                        ...state.lockedEvent,
                        value: postValue,
                        attack_direction: state.direction
                    }
                }
            }

            /**
             * Whenever we are either not tracking secondary actions or have a locked event already we don't want
             * to accept bottom menu item clicks. This is so the use can't get into a weird state where they have
             * a locked faceoff + something else like a shot, etc. The last check is to make sure the bottom item
             * is not a penalty. When we introduced the secondary actions the user could no longer click on a penalty.
             * The user needs to be able to log penalties in any state of the game since it's just a modal that pops up
             * and resets the state back to a faceoff whenever it happens.
             */
            if (!state.configuration.track_secondary_actions && state.lockedEvent && action.data.item !== 'penalty') {
                return state;
            }

            /**
             * When the user selects the `track_secondary_actions` option, the user will get here by
             * selecting the bottomMenu sub-option first. If they don't select that sub-option
             * we will come here directly. When that happens we want to use the `action.data.item`
             * for the bottomMenu type i.e. shot,miss,block because `state.bottomMenu` will be null otherwise.
             */
            const bottomMenuTarget = state.configuration.track_secondary_actions ? state.bottomMenu : action.data.item;

            /**
             * For some bottom row items like shot, miss, etc we have to enable ice lock
             * and register the event as a lockedEvent for more data to be entered, while
             * some bottom row elements just display a modal that will fill the information
             * out there.
             */
            if (bottomMenuTarget === 'shot' || bottomMenuTarget === 'miss' || bottomMenuTarget === 'block') {
                dynamic = {
                    iceLock: true,
                    lockedEvent: {
                        type: bottomMenuTarget,
                        ice: null,
                        period: action.data.time.period,
                        time: `${action.data.time.minutes}:${action.data.time.seconds}`,
                        ...this.numberOfPlayers(action.data.time),
                        ...this.realTime(),
                        value: action.data.item,
                        attack_direction: state.direction
                    }
                };

                /**
                 * Some bottom row elements above need to trigger a stop in play.
                 * The `clockStopped` attribute tells any other flag that we are
                 * waiting for a faceoff now so the clock can't be started by any
                 * other action besides a faceoff.
                 */
                switch (action.data.item) {
                case 'deflect_out_of_play':
                case 'puck_frozen':
                case 'post_crossbar_out_of_play':
                case 'out_of_play':
                    if (!state.clockRunning) {
                        Timer.stopClock();
                    }

                    dynamic = {
                        ...dynamic,
                        clockStopped: true
                    };
                    break;
                default:
                    break;
                }

            } else {
                // These are the stop in play options right now
                if (!state.clockRunning) {
                    Timer.stopClock();
                }

                switch (action.data.item) {
                case 'penalty':
                    dynamic = { modal: 'penalty' };
                    break;
                case 'icing':
                case 'offside':
                case 'puck_frozen':
                // eslint-disable-next-line
                case 'penalty':
                    dynamic = { modal: 'team' };
                    break;
                case 'other':
                    // dynamic = { modal: 'stop-other' };
                    // break;
                    return {
                        ...state,
                        message: this.message(),
                        events: state.events.concat({
                            type: 'stop',
                            value: 'other',
                            period: action.data.time.period,
                            time: `${action.data.time.minutes}:${action.data.time.seconds}`,
                            ...this.numberOfPlayers(action.data.time),
                            ...this.realTime(),
                            attack_direction: state.direction
                        }),
                        ...this.faceoff(action.data.time)
                    };
                default:
                    dynamic = {};
                }
            }

            return {
                ...state,
                ...dynamic,
                bottomItem: action.data.item,
                bottomMenu: state.configuration.track_secondary_actions ? state.bottomMenu : 'mini'
            };
        case 'zone-event-outcome-modal-click':
            if (state.configuration.zone_events.track_teams) {  // We are tracking teams, so open the team modal
                return {
                    ...state,
                    ...this.defaultState(),
                    lockedEvent: {
                        ...state.lockedEvent,
                        outcome: action.data.outcome,
                    },
                    modal: 'team'
                };
            } else {
                return {
                    ...state,
                    ...this.defaultState(),
                    events: state.events.concat({
                        ...state.lockedEvent,
                        outcome: action.data.outcome
                    }),
                    message: this.message()
                };
            }
        case 'battle-outcome-modal-click':
            return {
                ...state,
                ...this.defaultState(),
                events: state.events.concat({
                    ...state.lockedEvent,
                    value: action.data.outcome
                }),
                message: this.message()
            };
        case 'bottom-team-modal-save':
            return this.bottomRowModalSave(state, action.data);
        case 'save-penalty':
            /**
             * We have to have a different action here because the TimeStore needs to listen
             * specifically to the save-penalty action. In theory we could dispatch a second
             * action but it's much easier to just listen for both here and pass them off
             * to the same method i.e. `bottomRowModalSave`.
             */
            return this.bottomRowModalSave(state, action.data);
        case 'save-goal':
            // get the number of players on ice from the shared parseGoal function lib helper
            const postGoalPlayersOnIce = parseGoal(action.data.time, action).numberOfPlayersOnIce

            const target = state.lockedEvent.team === state.team.name ? 'team' : 'opponent';

            let dynamicGoal = {};

            // when a goal is scored in overtime (any period > 3) we pop the overtime goal modal
            // asking the user if they want to end the game or not.
            if (action.data.time.period > 3) {
                dynamicGoal = { modal: 'overtime-goal' };
            }

            return {
                ...state,
                ...this.defaultState(),
                message: this.message(),
                goals: {
                    ...state.goals,
                    [target]: ++state.goals[target]
                },
                shots: {
                    ...state.shots,
                    [target]: ++state.shots[target]
                },
                events: state.events.concat({
                    ...state.lockedEvent,
                    ...action.data.form,
                    goalie: state.current_goalie,
                    attack_direction: state.direction
                }),
                ...this.faceoff(
                    {
                        ...action.data.time,
                        // number of players needs to be from the shared parseGoal that is used here and in the TimeStore.
                        // Otherwise, GameStore was always behind and not in sync with the time store's parsed player counts
                        // based on if penalties or cancelled, etc.
                        numberOfPlayersOnIce: postGoalPlayersOnIce
                    },
                    true
                ),
                goalModal: this.goalModal(),
                ...dynamicGoal
            };
        case 'faceoff-button-click':
            /**
             * The only faceoff button that is required is the result button. If they click
             * the opponent hand button we still have to keep the lock on for the result.
             * If the user clicks the result it closes the faceoff lock.
             */
            const lock = { faceoffLock: action.data.item !== 'result' };

            // the team that is set based on the faceoff result
            let faceoffResultTeam = null

            /**
             * When the data item being sent is 'result', we have to check the data value
             * which is win/loss/kicked_out and we have to match the teams appropriately.
             * This is set in all scenarios below to make sure we didn't miss it since the user
             * can click the different buttons in any combination they want.
             * ---------------------
             * Win      -> team
             * Loss     -> opponent
             * Kick Out -> team
             * ---------------------
             */
            if (action.data.item === 'result') {
                if (action.data.value === 'win') {
                    faceoffResultTeam = state.team.name
                } else if (action.data.value === 'loss') {
                    faceoffResultTeam = state.opponent_team_name
                } else {
                    faceoffResultTeam = state.team.name
                }
            }

            if (lock.faceoffLock || state.iceLock || state.playerLock) {
                return {
                    ...state,
                    ...lock,
                    lockedEvent: {
                        ...state.lockedEvent,
                        ...this.realTime(),
                        [action.data.item]: action.data.value,
                        team: faceoffResultTeam,
                        attack_direction: state.direction
                    }
                };
            } else {
                const newEvent = {
                    ...state.lockedEvent,
                    ...this.realTime(),
                    [action.data.item]: action.data.value,
                    team: faceoffResultTeam,
                    attack_direction: state.direction
                };

                /**
                 * When the 'kicked out' button was clicked all we have to do is register
                 * the current lockedEvent as a 'kicked out' event and keep the lockedEvent
                 * in the same exact state as it was in.
                 * We also reset the player lock to true and the locked event player
                 * to null since that player was removed from the faceoff.
                 */
                if (newEvent.result === 'kicked_out') {
                    return {
                        ...state,
                        playerLock: true,
                        faceoffLock: true,
                        lockedEvent: {
                            ...state.lockedEvent,
                            player: null,
                            result: null,
                            team: this.faceoffResultTeam,
                            attack_direction: state.direction
                        },
                        events: state.events.concat(newEvent),
                        message: this.message()
                    };
                } else {
                    Timer.startClock();

                    // last click was result

                    return {
                        ...state,
                        faceoffLock: false,
                        lockedEvent: null,
                        topMenu: 'default',
                        events: state.events.concat(newEvent),
                        message: this.message(),
                        faceoffAlert: true,
                        faceoffBump: true
                    };
                }
            }
        case 'bump-faceoff-time':
            return { ...state, faceoffBump: false };
        case 'switch-view':
            return {
                ...state,
                view: action.data,
                sidebar: false
            };
        case 'change-goalie':
            return {
                ...state,
                message: this.message('Goalie changed'),
                current_goalie: action.data.id,
                events: state.events.concat({
                    ...this.realTime(),
                    team: state.team.name,
                    type: 'goalie_change',
                    previous_goalie: state.current_goalie,
                    player: action.data.id,
                    period: action.time.period,
                    time: `${action.time.minutes}:${action.time.seconds}`,
                    ...this.numberOfPlayers(action.time),
                    attack_direction: state.direction
                })
            };
        case 'open-select-game-tagger-modal':
            return {
                ...state,
                modal: 'select-tagger',
                currentGame: action.data.id
            };
        case 'close-select-game-tagger-modal':
            return {
                ...state,
                modal: null,
                currentGame: null
            };
        case 'select-tagger':
            return {
                ...state,
                modal: null,
                view: action.data.view,
                taggerTarget: action.data.taggerTarget
            };
        case 'launch-tagger':
            return {
                ...state,
                ...action.data,
                /**
                 * Navigate to whatever the tagger target is. This is what gets set
                 * since both the game event tagger and the time on ice tagger launch
                 * the same configure game screen so they can change the period duration
                 * and set the players that are inactive.
                 */
                view: state.taggerTarget
            };
        case 'change-direction':
            return {
                ...state,
                direction: this.changeDirection(state.direction)
            };
        case 'end-period':
            // always stop the clock when the period is over
            Timer.stopClock();

            if (action.data.period > 3) {
                // when the score isn't the same just end the game
                if (state.goals.team !== state.goals.opponent) {
                    return {
                        ...state,
                        ...this.endGame({
                            ...state,
                            powerPlaysCount: action.data.powerPlaysCount,
                            penaltyKillsCount: action.data.penaltyKillsCount
                        })
                    };
                } else {
                    return {
                        ...state,
                        modal: 'overtime'
                    };
                }
            } else {
                return {
                    ...state,
                    ...this.faceoff(action.data, true, true),
                    direction: this.changeDirection(state.direction)
                };
            }
        case 'end-game':
            // receive time pk/pp data here and slice it into the endGame call so it gets saved in local storage.
            return {
                ...state,
                ...this.endGame({
                    ...state,
                    ...action.data.time
                })
            };
        case 'toi-end-game':
            return {
                ...this.defaultState(),
                view: 'select',
                message: 'Game Completed'
            };
        case 'start-overtime':
            return {
                ...state,
                ...this.faceoff({
                    ...action.data.time,
                    // overwrite the default period duration with the duration sent
                    // in the OvertimeModal.jsx form dispatch.
                    duration: action.data.duration
                }, true, true)
            };
        case 'start-shootout':
            return { ...state, modal: 'shootout' };
        case 'sync-tagger-clock':
            const paddedTime = action.data < 10 ? `0${action.data}` : action.data;

            /**
             * When we sync our tagger clock we can then set the initial faceoff
             * time since up until that point we don't know the period duration.
             * This action is called when the tagger itself is loaded.
             *
             * TODO if we are able to open back up the tagger based on something
             * in local storage, etc, we need to check that variable here to see
             * if the game has already been started. When that's the case we don't
             * want to log a new faceoff every time the page loads.
             *
             * When the game first loads we need to get the initial faceoff object back
             * and modify its number of players on ice to 5v5. This happens automatically
             * after the clock is running, but on initial load we have to manually set it.
             */
            const initialFaceoff = this.faceoff({ minutes: paddedTime, seconds: '00', period: 1 }, true);

            return {
                ...state,
                ...initialFaceoff,
                modal: 'select-penalty-type',
                lockedEvent: {
                    ...initialFaceoff.lockedEvent,
                    number_of_players_on_ice: {
                        team: 5,
                        opponent: 5
                    },
                    attack_direction: state.direction
                }
            };
        case 'cancel-action':
            /**
             * This event is for when a user clicks an active button and wants
             * to cancel that action and choose something else. This should
             * just reset the UI back to the default state with no lockedEvent.
             *
             * Whenever the bottom menu is stop, start the clock again.
             */
            if (state.bottomMenu === 'stop' || state.bottomMenu === 'goal') {
                Timer.startClock();
            }

            return {
                ...state,
                ...this.defaultState()
            };
        case 'cancel-mini-action':
            Timer.startClock();
            return {
                ...state,
                ...this.defaultState(),
                clockStopped: false
            };
        case 'clear-message':
            return { ...state, message: '', flagEvent: false };
        case 'clear-faceoff-alert':
            return { ...state, faceoffAlert: null };
        case 'toggle-sidebar':
            return {
                ...state,
                sidebar: !state.sidebar
            };
        case 'toggle-running-clock':
            return {
                ...state,
                clockRunning: !state.clockRunning
            };
        case 'toggle-tagger-paused':
            // if tagger is not paused we are turning pausing on -- call pause tagger routine
            if (!state.taggerPaused) {
                Timer.pauseTagger();
            } else {
                Timer.unpauseTagger();
            }

            return {
                ...state,
                taggerPaused: !state.taggerPaused
            };
        case 'flag':
            return this.flag(state, action.data);
        case 'save-penalty-shot':
            let ps = {};

            /**
             * When a user scored a goal we have to increment both shots and goals for that team.
             */
            if (action.data.form.result === 'goal') {
                const target = state.penaltyShotTeam === state.team.name ? 'team' : 'opponent';

                ps = {
                    goals: {
                        ...state.goals,
                        [target]: ++state.goals[target]
                    },
                    shots: {
                        ...state.shots,
                        [target]: ++state.shots[target]
                    }
                };
            }

            /**
             * When the penalty shot is marked as a 'save' it's marked as a shot for the other team
             */
            if (action.data.form.result === 'save') {
                const target = state.penaltyShotTeam === state.team.name ? 'team' : 'opponent';

                ps = {
                    shots: {
                        ...state.shots,
                        [target]: ++state.shots[target]
                    }
                };
            }

            return {
                ...state,
                ...ps,
                events: state.events.concat({
                    period: action.data.time.period,
                    time: `${action.data.time.minutes}:${action.data.time.seconds}`,

                    /**
                     * Per e-rok on saving penalty shots, the players on ice should always be 0/0... i.e. { team: 0, opponent: 0 }
                     */
                    number_of_players_on_ice: { team: 0, opponent: 0 },
                    // ...this.numberOfPlayers(action.data.time),

                    // the UI has 'save' but it is actually marked as a 'shot'
                    type: action.data.form.result !== 'save' ? action.data.form.result : 'shot',
                    shot_type: action.data.form.shot_type,
                    net: action.data.form.net,
                    // always mark sub-value as penalty shot
                    value: 'penalty_shot',
                    player: state.penaltyShotPlayer,
                    team: state.penaltyShotTeam,
                    goalie: state.current_goalie || null,
                    ...this.realTime(),
                    attack_direction: state.direction
                }),
                penaltyShotPlayer: null,
                penaltyShotTeam: null,
                ...this.faceoff(action.data.time)
            };
        case 'save-shootout-event':
            let sd = {};

            // when there's a shootout goal, increment the shootout goal numbers.
            if (action.data.form.value === 'goal') {
                const target = action.data.form.team === state.team.name ? 'team' : 'opponent';

                sd = {
                    shootoutGoals: {
                        ...state.shootoutGoals,
                        [target]: ++state.shootoutGoals[target]
                    }
                };
            }

            return {
                ...state,
                ...sd,
                events: state.events.concat({
                    period:    action.data.time.period,
                    time:      `${action.data.time.minutes}:${action.data.time.seconds}`,

                    /**
                     * Per e-rok on saving penalty shots, the players on ice should always be 0/0... i.e. { team: 0, opponent: 0 }
                     */
                    number_of_players_on_ice: { team: 0, opponent: 0 },
                    // ...this.numberOfPlayers(action.data.time),

                    goalie:    state.current_goalie,
                    type:      'shootout',
                    ...action.data.form,
                    ...this.realTime(),
                    attack_direction: state.direction
                })
            };
        case 'end-shootout':
            const winner = state.shootoutGoals.team > state.shootoutGoals.opponent ? 'team' : 'opponent';

            return {
                ...state,
                goals: {
                    ...state.goals,
                    [winner]: ++state.goals[winner]
                },
                ...this.endGame(state)
            };
        case 'undo':
            const undoEvents = state.events;
            const lastEvent  = state.events[state.events.length-1];

            if (!lastEvent) { return state; }

            // obj that will be expanded when returning
            let undoDynamic = {};

            // when we have a shot or a goal we have to decrement the shot/goal counter by 1
            if (lastEvent.type === 'shot') {
                const shotTarget = lastEvent.team === state.team.name ? 'team' : 'opponent';
                undoDynamic = {
                    shots: {
                        ...state.shots,
                        [shotTarget]: --state.shots[shotTarget]
                    }
                };
            }

            // with goals we also decrement the shot just like we do when they score (increment)
            if (lastEvent.type === 'goal') {
                const goalTarget = lastEvent.team === state.team.name ? 'team' : 'opponent';
                undoDynamic = {
                    goals: {
                        ...state.goals,
                        [goalTarget]: --state.goals[goalTarget]
                    },
                    shots: {
                        ...state.shots,
                        [goalTarget]: --state.shots[goalTarget]
                    }
                };
            }

            // there are certain types of events that we can stop the play. when a user clicks
            // one of those options we have to reset the game mode back to normal with the
            // time running instead of the faceoff state. this is used for example if the user
            // clicked a shot event and accidentally clicked out of play when it wasn't.
            //
            // 8/26/19 `goal` was removed from `stopTypes` per Erik since in most cases this undo
            //         is done when a goal is waved off which still results in a faceoff afterwards.
            //         when that's the case we want to keep the clock stopped.
            const stopValues = ['deflect_out_of_play', 'puck_frozen', 'post_crossbar_out_of_play', 'out_of_play'];
            const stopTypes  = ['stop', 'penalty'];

            // when we are on a matched type (above) start the clock and go into default state.
            if (stopTypes.includes(lastEvent.type) || stopValues.includes(lastEvent.value)) {
                // Timer.startClock();
                undoDynamic = this.defaultState();
            }

            // remove last (target) event from event array
            undoEvents.splice(-1,1);

            return {
                ...state,
                ...undoDynamic,
                message: this.undoMessage(lastEvent),
                events: undoEvents
            };
        case 'close-penalty-modal':
            // when the top menu is faceoff we want to wipe the state of the bottom menu, and
            // set the modal to null, otherwise we want to just set it back to the default state.
            if (state.topMenu !== 'faceoff') {
                // Timer.startClock();
                return { ...state, ...this.defaultState() };
            } else {
                return {
                    ...state,
                    modal: null,
                    bottomMenu: 'default',
                    bottomItem: null
                };
            }
        case 'set-goal-modal-item':
            // dyanmic attrs to add to the goal modal
            let modalItem = {};
            let goalSectionTarget = action.data.target;

            // items that need to add onto `players_on_ice` in goal modal
            const itemCheck = ['assist_1', 'assist_2', 'screen'];

            // check if our section needs to add to the players on ice
            if (itemCheck.includes(action.data.section)) {
                // find the current id at the target section -- this is the player
                // that we will remove from the current players_on_ice array
                const currentId = state.goalModal[action.data.section];

                // when the current id is the same as the player sent up, we remove them from being selected instead of adding,
                // allowing the user to click the same user again to de-select them
                if (currentId === action.data.target) {
                    // remove item from players on ice
                    modalItem = { players_on_ice: state.goalModal.players_on_ice.filter(playerId => playerId !== currentId) };
                    // set goalSectionTarget back to null to remove the selectino from this section (action.data.section)
                    goalSectionTarget = null;
                } else {
                    // when the action.data.object (player object)'s position is goalie, we do not add them to players on ice array
                    if (action.data.object.position !== 'goalie') {
                        modalItem = {
                            // when evaluating players on ice we want to concat the id that was sent
                            // (the new player) while also removing the old player that was in that
                            // same section (currentId). That way we're always adding & removing whatever
                            // was already there since we're grabbing it from `state.goalModal[action.data.section]`
                            players_on_ice: state.goalModal.players_on_ice.concat(action.data.target).filter(playerId => playerId !== currentId)
                        };
                    }
                }
            }

            return {
                ...state,
                goalModal: {
                    ...state.goalModal,
                    ...modalItem,
                    [action.data.section]: goalSectionTarget
                }
            };
        case 'change-goal-modal-item':
            return {
                ...state,
                goalModal: {
                    ...state.goalModal,
                    item: action.data
                }
            };
        case 'goal-modal-net-click':
            return {
                ...state,
                goalModal: {
                    ...state.goalModal,
                    net: action.data
                }
            };
        case 'close-modal':
            return { ...state, modal: null };
        case 'bottom-row-stop-clock':
            // This is the action that gets triggered when a team is not tracking secondary
            // information for the shot/miss/block events and they click `stop clock`.
            // What needs to happen is the clock needs to stop, and we need to log clockStopped
            // into state so that whenever the event is saved, we check the `clockStopped` attribute
            // in order to determine whether or not we should enter faceoff mode. Stopping the clock
            // directly will make the clock start again whenever the event is saved.
            if (state.clockStopped) {
                Timer.startClock();
                return {
                    ...state,
                    clockStopped: false
                };
            } else {
                if (!state.clockRunning) {
                    Timer.stopClock();
                }

                return {
                    ...state,
                    clockStopped: true
                };
            }
        case 'view-game-stats':
            return {
                ...state,
                view: 'game-stats'
            };
        case 'view-event-editor':
            return {
                ...state,
                view: 'event-editor'
            };
        case 'upload-in-progress-game':
            if (action.data.tagger === 'event') {
                // dispatch both the game state and TOI pp/pk data to be uploaded instead of just the game.
                // nothing in the client method should have to change since we're merging the data into one.
                Client.uploadGameInProgress({
                    ...state,
                    ...action.data.time
                });
            }
            // No matter what type of tagger the user is trying to upload, we should return with an `uploading`
            // state in one place to trigger loading prompts, etc... that's really all this does.
            return {
                ...state,
                uploading: true
            };
        case 'done-uploading':
            return {
                ...state,
                uploading: false
            };
        case 'close-goal-modal':
            // start the clock again since they're cancelling the goal
            // Timer.startClock();
            return {
                ...state,
                ...this.defaultState(),
                goalModal: this.goalModal()
            };
        case 'open-auto-penalty-modal':
            return {
                ...state,
                modal: 'auto-penalty'
            };
        case 'close-auto-penalty-modal':
            return {
                ...state,
                modal: null,
                sidebar: false
            };
        case 'save-auto-penalty':
            return {
                ...state,
                events: state.events.concat({
                    ...this.realTime(),
                    ...this.numberOfPlayers(action.data.time),
                    period: action.data.time.period,
                    time: `${action.data.time.minutes}:${action.data.time.seconds}`,
                    type: 'penalty',
                    infraction: 'Auto',
                    team: action.data.teamName,
                    // as of 9/24/21 erik only wants the ? to be filled in for TEAM penalties -- blank on opponent
                    playerNumber: action.data.side === 'team' ? '?' : null,
                    player: action.data.side === 'team' ? '?' : null,
                    bench: false,
                    firstFilled: false,
                    length: Number.parseFloat(action.data.length.replace(/:30/g, '.5')),
                    offsetting: false,
                    penaltyType: action.data.length,
                    servedBy: null,
                    servedByNumber: null,
                    attack_direction: state.direction
                }),
                message: 'Auto-Penalty Saved',
                sidebar: false,
                modal: null
            }
        case 'video-stamp':
            return this.flag(state, { ...action.data, type: 'video_stamp', message: 'Video stamp saved' });
        case 'use-alternate-jerseys':
            return {
                ...state,
                alternateJerseys: action.data.alternateJerseys
            };
        case 'set-completed-game-stats':
            return {
                ...action.data,
                view: 'game-stats',
                isSyncStats: true
            };
        case 'show-end-period-modal':
            if (action.data) {
                return {
                    ...state,
                    modal: 'end-period',
                };
            }

            Timer.startClock();
            return {
                ...state,
                ...this.defaultState()
            }
        case 'show-end-exit-game-modal':
            return {
                ...state,
                modal: action.data,
            };
        case 'manual-close-penalty-modal':
            return {
                ...state,
                modal: 'manual-close-penalty-modal'
            }
        case 'close-manual-close-penalty-modal':
        case 'manual-close-penalty':
            return {
                ...state,
                modal: null
            }
        case 'bump-time':
            /**
             * Whenever a user bumps time, we have check to see if there is a locked event. Otherwise, if
             * the user updates the clock during a penalty or something along those lines the event itself
             * will still have the time before the clock update. If the play stops and there is a faceoff
             * locked event in state, and they bump the time, the locked event needs the new time also.
             */
            if (state.lockedEvent) {
                const amount = action.data.increment ? action.data.amount : (action.data.amount * -1);
                const newTime = Timer.bump(action.data.time, amount);

                return {
                    ...state,
                    lockedEvent: {
                        ...state.lockedEvent,
                        time: `${newTime.minutes}:${newTime.seconds}`,
                        attack_direction: state.direction
                    }
                };
            }

            return state;
        case 'set-penalty-type':
            return {
                ...state,
                modal: null,
                penaltyType: action.data.penaltyType
            }
        case 'log-generic-event':
            return {
                ...state,
                events: state.events.concat({
                    type: 'generic',
                    time: `${action.data.time.minutes}:${action.data.time.seconds}`,
                    ...this.numberOfPlayers(action.data.time),
                    period: action.data.time.period,
                    ...this.realTime(),
                    attack_direction: state.direction
                }),
                message: this.message('Generic Event Saved')
            };
        case 'log-battle-event':
            const event = {
                type:   'battle',
                period: action.data.time.period,
                time:   `${action.data.time.minutes}:${action.data.time.seconds}`,
                /**
                 * Here we are setting the team to the user's team. When track_teams is on, this will be overwritten
                 * by the modal that asks for the team. Otherwise, it will get set to the default user's team since
                 * all other events (check, giveaway, takeaway) have the context of the user's team by default.
                 */
                team: state.team.name,
                ...this.numberOfPlayers(action.data.time),
                ...this.realTime(),
                attack_direction: state.direction
            };

            if (action.data.track_players) {
                return {
                    ...state,
                    playerLock: true,
                    iceLock: action.data.track_location,
                    lockedEvent: obj
                };
            } else if (action.data.track_players) {
                return {
                    ...state,
                    playerLock: true,
                    iceLock: action.data.track_location,
                    lockedEvent: obj
                };
            } else {
                return {
                    ...state,
                    events: state.events.concat({
                        ...event
                    }),
                    message: this.message('Battle Event Saved')
                };
            }
        case 'save-updated-event-data':
            return {
                ...state,
                events: action.data.events,
                view: 'tagger',
                sidebar: false
            }
        default:
            return state;
        }
    }
}

export default new GameStore();
