import React from 'react';
import Dispatcher from '../../dispatchers/Dispatcher';
import TimeOnIcePlayers from './TimeOnIcePlayers';
import GameClock from '../GameClock';
import Message from '../Message';
import EndPeriodModal from './EndPeriodModal';
import EndGameModal from './EndGameModal';
import ExitGameModal from './ExitGameModal';
import TimeOnIceActionButtons from './TimeOnIceActionButtons';
import TaggerMenu from '../TaggerMenu';
import TimeOnIceZones from './TimeOnIceZones';
import TimeOnIceLines from './TimeOnIceLines';
import FaceoffAlert from '../FaceoffAlert';
import ClearLine from './ClearLine';

export default class TimeOnIceTagger extends React.Component {
    componentWillMount() {
        Dispatcher.dispatch({
            type: 'sync-current-game',
            data: this.props
        });

        Dispatcher.dispatch({
            type: 'sync-tagger-clock',
            data: this.props.period_duration
        });

        Dispatcher.dispatch({
            type: 'toi-sync-starting-players',
            data: {
                duration: this.props.period_duration
            }
        });
    }

    openMenu() {
        Dispatcher.dispatch({ type: 'toggle-sidebar' });
    }

    render() {
        return (
            <div id="tagger" className="toi">

                <EndPeriodModal open={this.props.showEndPeriodModal} time={this.props.time} />
                <EndGameModal open={this.props.showEndGameModal} time={this.props.time} />
                <ExitGameModal open={this.props.showExitGameModal}/>
                
                <TaggerMenu
                    open={this.props.sidebar}
                    hideRunningClock={true}
                    endGame={'toi-end-game'}
                    uploading={this.props.uploading}
                    tagger='toi'
                    showUpload={true}
                    hideStats={true} 
                    hideEditor={true} 
                    time={this.props.time}/>

                <div className="configure-game">
                    <div className="header">
                        <div className="menu padded" onClick={() => this.openMenu()}>
                            Menu
                            &nbsp;
                            <span className="vellipsis">&hellip;</span>
                        </div>
                        <div className="host">
                            <div className="team-name">
                                <div className="name" style={{ textAlign: 'left' }}>{this.props.team.name}</div>
                            </div>
                        </div>

                        <GameClock time={this.props.time} toiClockNotStarted={!this.props.clockStarted} />

                        <div className="opponent">
                            <div className="team-name">
                                {/* <div className="date-loc">{`${date.format("MMMM D, YYYY")} at ${date.format("h:mma")}`}</div> */}
                                <div className="name">{this.props.opponent_team_name}</div>
                            </div>
                        </div>
                        <div className="clear"></div>
                    </div>
                </div>
                <div id="interface">

                    {
                        this.props.team.use_faceoff_alerts && this.props.clockStartedAlert && (
                            <FaceoffAlert message="Clock Started" />
                        )
                    }

                    <div className="section players no-border">
                        <div className="inner">
                            <div className="forwards">
                                <div className="label label-line">Forwards</div>

                                <TimeOnIceLines
                                    position={'forward'}
                                    active={this.props.lines.forward}
                                    time={this.props.time}
                                    showLines={this.props.team.players['forward'].filter(p => !!p.line).length > 0} />

                                <div className="player-numbers btn-row-5">
                                    <TimeOnIcePlayers
                                        players={this.props.team.players['forward']}
                                        active={this.props.activeShifts}
                                        goalie={false}
                                        time={this.props.time}
                                        position={'forward'} />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="section players no-border">
                        <div className="inner">
                            <div className="defense">
                                <div className="label label-line">Defense</div>

                                <TimeOnIceLines
                                    position={'defense'}
                                    active={this.props.lines.defense}
                                    time={this.props.time}
                                    showLines={this.props.team.players['defense'].filter(p => !!p.line).length > 0 } />

                                <div className="player-numbers btn-row-4">
                                    <TimeOnIcePlayers
                                        players={this.props.team.players['defense']}
                                        active={this.props.activeShifts}
                                        goalie={false}
                                        time={this.props.time}
                                        position='defense' />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="section players no-border">
                        <div className="inner">
                            <div className="goalies no-select">
                                <div className="label">Goalies</div>
                                <div className="player-numbers btn-row-fit">
                                    <TimeOnIcePlayers
                                        players={this.props.team.players['goalie']}
                                        active={this.props.activeShifts}
                                        goalie={true}
                                        time={this.props.time} />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="section players no-border">
                        <div className="inner">
                            <div className="goalies no-select">
                                <div className="label">ZONE & POSSESSION</div>
                                <div className="player-numbers btn-row-fit">
                                    <TimeOnIceZones
                                        time={this.props.time}
                                        activeZone={this.props.activeZone} />
                                </div>
                            </div>
                        </div>
                    </div>

                    <TimeOnIceActionButtons
                        time={this.props.time}
                        clockRunning={this.props.clockRunning} />

                </div>
                <Message message={this.props.message} />
            </div>
        );
    }
}
