import React from 'react';
import Dispatcher from '../dispatchers/Dispatcher';
import FaceoffMenu from './FaceoffMenu';

export default class InterfaceTop extends React.Component {
    renderSection(section, parentClass) {
        return (
            <div className={parentClass}>
                {this.renderSectionButtons(section)}
            </div>
        );
    }

    active(section, conf) {
        if (!!this.props.lockedEvent && ['zone_exit', 'zone_entry'].includes(this.props.lockedEvent.type) && (this.props.lockedEvent.type === section && this.props.lockedEvent.value === conf.value)) {
            return 'active';
        } else if (!this.props.playerLock) {
            return '';
        } else if (this.props.lockedEvent.type === section && this.props.lockedEvent.value === conf.value) {
            return 'active';
        } else if (conf.value === this.props.lockedEvent.type) {
            return 'active';
        } else {
            return 'inactive';
        }
    }

    renderSectionButtons(section) {
        return this.props.configuration[section].actions.map((conf) => {
            return (
                <div
                    key={conf.value}
                    className={`btn ${this.active(section, conf)}`}
                    onClick={() => this.handleClick(conf, section)}>
                    <span>{this.parseDisplayName(conf.display_name)}</span>
                </div>
            );
        });
    }

    /**
     * Format individual display names so we have more control over how it renders
     */
    parseDisplayName = name => {
        switch(name.toLowerCase()) {
            case 'giveaway':
                return 'GIVE AWAY';
            case 'takeaway':
                return 'TAKE AWAY';
            default:
                return name;
        }
    }

    handleClick(conf, section) {
        let track, trackTeams, trackLocation, trackOutcome = false;
        
        // if (section === 'zone_exit' && this.props.configuration.zone_exit.track_teams) {
        //     trackTeams = true;
        // } else if (section === 'zone_entry' && this.props.configuration.zone_entry.track_teams) {
        //     trackTeams = true;
        // }

        if (section === 'zone_exit' || section === 'zone_entry') {
            if (this.props.configuration.zone_events.track_location) {
                trackLocation = true;
            }
            if (this.props.configuration.zone_events.track_outcome) {
                trackOutcome = true;
            }
            if (this.props.configuration.zone_events.track_teams) {
                trackTeams = true;
            }
        }

        Dispatcher.dispatch({
            type: 'interface-button-click',
            data: {
                section: section,
                time: this.props.time,
                track_players: track,
                track_teams: trackTeams,
                track_location: trackLocation,
                track_outcome: trackOutcome,
                ...conf,
            }
        });
    }

    handleVideoStampClick() {
        Dispatcher.dispatch({
            type: 'video-stamp',
            data: {
                time: this.props.time
            }
        })
    }

    render() {
        switch(this.props.topMenu) {
        case 'faceoff':
            return (
                <FaceoffMenu
                    lockedEvent={this.props.lockedEvent}
                    configuration={this.props.configuration.faceoff.actions}
                    time={this.props.time} />
            );
        default:
            return (
                <div className="section flex top-row">
                    <div className="pr-1 flex-1">
                        <div className="label">Zone Exit</div>
                        {this.renderSection('zone_exit', 'btn-group btn-row-fit')}
                    </div>
                    <div className="pl-1 pr-1 flex-1">
                        <div className="label">Zone Entry</div>
                        {this.renderSection('zone_entry', 'btn-group btn-row-fit')}
                    </div>
                    <div className="sep-left pl-1" style={{ flex: '.2' }}>
                        <div className="label" style={{paddingBottom:'7px'}}>&nbsp;</div>
                        {/* {this.renderSection('top_right', 'btn-row-fit')} */}
                        <div
                            className={`btn`}
                            onClick={() => this.handleVideoStampClick()}>
                            <span>VIDEO STAMP</span>
                        </div>
                    </div>
                </div>
            );
        }
    }
}
