import Storage from './Storage';

export default class Games {
    static setActive(players) {
        /**
         * If the user forgets to set a certain block of players i.e. defense for a game it seems like
         * we are not even getting an empty array back from the API, we are just not getting that attribute
         * all together in our fetch games payload. Since that is the case we have to check to see if it is
         * undefined here, and if so just set that attribute to an empty array.
         */
        if (typeof players === 'undefined') {
            return [];
        } else {
            return players.map((player) => {
                return {
                    ...player,
                    active: true
                };
            });
        }
    }

    static configure(games) {
        // games are saved off as a block and then separated once the game starts
        let formattedGames = games.map((game) => {
            return {
                ...game,
                team: {
                    ...game.team,
                    players: {
                        ...game.team.players_for_api,
                        forward: this.setActive(game.team.players_for_api.forward),
                        defense: this.setActive(game.team.players_for_api.defense),
                        goalie: this.setActive(game.team.players_for_api.goalie)
                    }
                }
            };
        });

        // game.team.players got changed to game.team.players_for_api
        // so we don't want duplicate players & players for api. here
        // we delete players_for_api since we don't use it
        for (let i = 0; i < formattedGames.length; i++) {
            delete games[i].team.players_for_api;
        }

        Storage.setGames(formattedGames);
    }
}
