import React from 'react';
import Dispatcher from '../../dispatchers/Dispatcher';

export default class SaveUpdatedEventsModal extends React.Component {

    close = () => {
        Dispatcher.dispatch({ type: 'close-select-game-tagger-modal' });
    }

    navigate(view, taggerTarget) {
        Dispatcher.dispatch({
            type: 'select-tagger',
            data: {
                view: view,
                game: this.props.game,
                taggerTarget: taggerTarget
            }
        });
    }

    render() {
        return (
            <div className={`modal-overlay ${this.props.isOpen ? 'open' : ''}`}>
                <div className="modal-content">
                    <div className="section">
                        <h1>Save and overwrite existing event data?</h1>
                        <br />
                        <div className="btn-row-6-stack">
                            <div className="btn green" style={{ padding: '20px' }} onClick={this.props.saveData}><span>Save Event Data</span></div>
                            <div className="btn blue" style={{ padding: '20px' }} onClick={this.props.closeModal}><span>Cancel</span></div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
