import { ReduceStore } from 'flux/utils';
import Dispatcher from '../dispatchers/Dispatcher';
import Storage from '../lib/Storage';
import moment from 'moment';

class VideoStore extends ReduceStore {
    constructor() {
        super(Dispatcher);
    }

    getInitialState() {
        return {
            taggerId: this.fetchTaggerId(),
            events: [],
            sidebar: false,
            tagger_type: 'video',
            period: 1,
            game_started: false,
            message: '',
            showEndPeriodModal: false,
            showEndGameModal: false,
            showExitGameModal: false
        };
    }

    fetchTaggerId() {
        var taggerId = Storage.getTaggerId();
        if (taggerId !== null) {
            return taggerId;
        } else {
            return Storage.generateTaggerId();
        }
    }

    calculateSecondsSinceStart() {
        const now = moment(new Date())
        const gameStart = moment(window.gameStartTime)

        const seconds = now.diff(gameStart, 'seconds')

        return seconds
    }

    reduce(state, action) {
        switch (action.type) {
        case 'select-tagger':
            return { ...state, ...action.data.game };
        case 'video-click':
            return {
                ...state,
                events: state.events.concat({
                    type:                'video_tagger',
                    value:               action.data.type,
                    zone:                action.data.zone,
                    real_time:           new Date().toISOString(),
                    seconds_since_start: this.calculateSecondsSinceStart(),
                    period:              state.period
                }),
                message: 'EVENT SAVED'
            };
        case 'clear-message':
            return { ...state, message: '' };
        case 'video-end-game':
            Storage.completeGame(state);
            return { ...state };
        case 'video-end-period':
            return {
                ...state,
                period: state.period + 1,
                message: 'PERIOD ENDED',
                showEndPeriodModal: false
            };
        case 'toggle-sidebar':
            return { ...state, sidebar: !state.sidebar };
        case 'video-start-game':
            window.gameStartTime = new Date();
            console.log('Game start time: ', window.gameStartTime);

            return {
                ...state,
                game_started: true,
                events: state.events.concat({
                    type: 'video_tagger',
                    value: 'game_start',
                    zone: null,
                    real_time: new Date().toISOString(),
                    seconds_since_start: 0,
                    period: state.period
                }),
                message: 'GAME STARTED'
            };
        case 'video-toggle-end-modal':
            if (action.data.modal === 'period') {
                return {
                    ...state,
                    showEndPeriodModal: action.data.open
                }
            } else if (action.data.modal === 'end-game') {
                return {
                    ...state,
                    showEndGameModal: action.data.open
                }
            }

            return {
                ...state,
                showExitGameModal: action.data.open
            }
        default:
            return { ...state };
        }
    }
}

export default new VideoStore();
