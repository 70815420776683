import React from 'react';
import SkaterStats from './SkaterStats';
import GoalieStats from './GoalieStats';
import StatsHeader from './StatsHeader';
import TeamStats from './TeamStats';

export default class GameStats extends React.Component {
    constructor(props) {
        super(props)

        this.state = {
            period: null
        }
    }

    handlePeriodClick = (period) => {
        this.setState({ period });
    }

    events() {
        if (this.state.period === null) {
            // null period evaluates to the 'Game' tab (all events)
            return this.props.game.events;
        } else {
            // otherwise, only give back events for the current state's period
            return this.props.game.events.filter(e => {
                return e.period === this.state.period;
            })
        }
    }

    periodsPlayed = () => {
        // start with a null entry since that represents the whole game and it will be shipped along with
        // the other periods played as they get pushed to this array
        //
        // if we wanted to always show game 1 2 3 we could just add those to this starting array and it would
        // only fill in the OT periods from that point on instead
        let periods = [null];

        this.props.game.events.forEach(e => {
            if (!periods.includes(e.period)) {
                periods.push(e.period);
            }
        });

        return periods;
    }

    render() {
        return (
            <div id="tagger" className="game-report">

                <StatsHeader
                    handlePeriodClick={this.handlePeriodClick}
                    activePeriod={this.state.period}
                    periodsPlayed={this.periodsPlayed()}
                    isSyncStats={this.props.game.isSyncStats} />

                <SkaterStats
                    players={this.props.game.team.players}
                    events={this.events()}
                    teamName={this.props.game.team.name} />

                <TeamStats
                    teamName={this.props.game.team.name}
                    opposingName={this.props.game.opponent_team_name}
                    events={this.events()} />

                <GoalieStats
                    players={this.props.game.team.players}
                    events={this.events()}
                    teamName={this.props.game.team.name} />

            </div>
        );
    }
}
