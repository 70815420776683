import React from 'react'
import Dispatcher from '../../dispatchers/Dispatcher';

export default class VideoStartGameModal extends React.Component {

    start = () => {
        Dispatcher.dispatch({ type: 'video-start-game' });
    }

    render = () => {
        return (
            <div className={`modal-overlay other ${this.props.open ? 'open' : ''}`}>
                <div className="modal-content">
                    <div className="section">
                        <h1>VIDEO TAGGER - START GAME</h1>
                        <div className="btn-row-6-stack">
                            <div className="btn" onClick={this.start}>
                                <span>START GAME</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}