import React from 'react';
import moment from 'moment';
import Dispatcher from '../dispatchers/Dispatcher';
import Client from '../lib/Client';

export default class GameCard extends React.Component {
    handleClick() {
        if (this.props.completed) {
            return;
        }

        Dispatcher.dispatch({
            type: 'open-select-game-tagger-modal',
            data: this.props.game
        });
    }

    sync() {
        // if the game is uploading don't run the client action again, just return
        if (this.props.uploading) {
            return;
        }

        Client.uploadGame(this.props.game);
    }

    uploadText() {
        if (this.props.uploading) {
            return (
                <span>Uploading...</span>
            );
        } else {
            return (
                <span>Upload</span>
            );
        }
    }

    renderUpload() {
        if (this.props.completed) {
            return (
                <div className={`btn upload-btn ${this.props.uploading ? 'flashing' : ''}`} onClick={() => this.sync()}>
                    {this.uploadText()}
                </div>
            );
        } else {
            return '';
        }
    }

    openStats = () => {
        Dispatcher.dispatch({
            type: 'set-completed-game-stats',
            data: this.props.game
        });
    }

    render() {
        const date = moment.utc(this.props.game.date);

        return (
            <div className="card" onClick={() => this.handleClick()}>
                <div className="teams">
                    <h3>{this.props.game.full_name}</h3>
                    <p className="vs">at</p>
                    <h3>{this.props.game.opponent_team_name}</h3>
                </div>
                <div className="time-loc">
                    <p className="date">{date.format("MMMM Do, YYYY")}</p>
                    <p className="time">{date.format("h:mm a")}</p>
                    <p className="location">{this.props.game.venue_info.venue_name}</p>
                </div>

                {
                    this.props.completed && this.props.game.tagger_type === 'event' && (
                        <div className="view-stats">
                            <div className="btn green" onClick={this.openStats}>
                                <span>View Stats</span>
                            </div>
                        </div>
                    )
                }

                {this.renderUpload()}
            </div>
        );
    }
}
