import Storage from "./Storage";

export default class Endpoints {
    static ROOT = process.env.REACT_APP_API_URL;

    static LOGIN = `${this.ROOT}/users/sign_in`;

    static FETCH_GAMES = `${this.ROOT}/fetch_games?token=${Storage.getToken()}`;

    static UPLOAD_GAME = `${this.ROOT}/events?token=${Storage.getToken()}`;
}
