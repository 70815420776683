import React from 'react';
import Dispatcher from '../dispatchers/Dispatcher';
import Offset from '../lib/Offset';
import Strings from '../lib/Strings';
import Players from '../lib/Players';

export default class ShootoutModal extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            form: this.initialState(),
            modalContentRef: React.createRef()
        }
    }

    initialState() {
        return {
            value:     null,
            shot_type: null,
            net:       null,
            approach:  null,
            player:    null,
            team:      null
        };
    }

    save() {
        Dispatcher.dispatch({
            type: 'save-shootout-event',
            data: {
                form: this.state.form,
                time: this.props.time
            }
        });

        this.setState({
            ...this.state,
            form: {
                ...this.initialState(),
                team: this.state.form.team === this.props.teamName ? this.props.opponentName : this.props.teamName
            }
        });
    }

    end() {
        Dispatcher.dispatch({
            type: 'end-shootout',
            data: { form: this.state.form }
        });
    }

    setItem(attr, value) {
        this.setState({
            form: {
                ...this.state.form,
                [attr]: value
            }
        });
    }

    renderOptions(attr, set) {
        return set.map((item, idx) => {
            return (
                <div
                    key={idx}
                    className={`btn ${this.state.form[attr] === item ? 'active' : ''}`}
                    onClick={() => this.setItem(attr, item)}>
                    <span>{Strings.titleize(item)}</span>
                </div>
            );
        });
    }

    renderPlayerSection() {
        if (this.state.form.team === this.props.opponentName) {
            return '';
        } else {
            return (
                <div className="section">
                    <div className="inner">
                        <div className="label">Player</div>
                        <div className="btn-row-10">
                            {this.renderPlayers()}
                        </div>
                    </div>
                </div>
            );
        }
    }

    renderPlayers() {
        let players = [];

        const penaltyNumbers = this.props.time.penalties.team.map((p) => { return p.player; });

        ['forward', 'defense'].forEach((key) => {
            this.props.players[key].forEach((p) => {
                if (p.active && !penaltyNumbers.includes(p.number)) {
                    players.push(p);
                }
            });
        });

        Players.sort(players);

        return players.map((p, x) => {
            return (
                <div
                    key={x}
                    className={`btn shootout-player ${this.state.form.player === p.id ? 'active' : ''}`}
                    onClick={() => this.setItem('player', p.id)}>
                    <span>{p.number}</span>
                </div>
            );
        });
    }

    results() {
        return ['goal', 'miss', 'save'];
    }

    shotTypes() {
        return ['wrist', 'snap', 'slap', 'backhand', 'deke_to_forehand', 'deke_to_backhand'];
    }

    approachTypes() {
        return [ 'left', 'middle', 'right' ];
    }

    netClick(e) {
        this.setState({
            form: {
                ...this.state.form,
                net: Offset.parse(e.target, e, this.state.modalContentRef.current.scrollTop)
            }
        });
    }

    perc() {
        const format = Offset.formatPercentage(this.state.form.net);

        return {
            left: format.left,
            top: format.top
        };
    }

    renderPuck() {
        if (this.state.form.net) {
            const split = this.perc();

            return <div className="puck" style={{ left: split.left, top: split.top }}></div>;
        } else {
            return '';
        }
    }

    render() {
        return (
            <div className={`modal-overlay pen-modal shootout ${this.props.open ? 'open' : ''}`}>
                <div className="modal-content" ref={this.state.modalContentRef}>
                    <div className="section">
                        <div className="inner">
                            <div className="shootout-scores">
                                <div className="score">{this.props.goals.team}</div>
                                <span>-</span>
                                <div className="score">{this.props.goals.opponent}</div>
                            </div>
                        </div>
                    </div>
                    <div className="section">
                        <div className="inner">
                            <div className="label">Team</div>
                            <div className="btn-row-2">
                                {this.renderOptions('team', [this.props.teamName, this.props.opponentName])}
                            </div>
                        </div>
                    </div>
                    {this.renderPlayerSection()}
                    <div className="section">
                        <div className="inner">
                            <div className="label">Result</div>
                            <div className="btn-row-3">
                                {this.renderOptions('value', this.results())}
                            </div>
                        </div>
                    </div>
                    <div className="section">
                        <div className="inner">
                            <div className="label">Type of Shot</div>
                            <div className="btn-row-3">
                                {this.renderOptions('shot_type', this.shotTypes())}
                            </div>
                        </div>
                    </div>
                    {/* Removed on 8/6/2020 */}
                    {/* <div className="section">
                        <div className="inner">
                            <div className="label">Approach Path</div>
                            <div className="btn-row-3">
                                {this.renderOptions('approach', this.approachTypes())}
                            </div>
                        </div>
                    </div> */}
                    <div className="section net-container">
                        <div className="inner">
                            {/* <div className="label">Shot Position</div> */}
                            <img
                                onClick={(e) => this.netClick(e)}
                                className="net"
                                alt="net"
                                width="350px"
                                src="net.svg" />
                                {this.renderPuck()}
                        </div>
                    </div>
                    <div className="section">
                        <div className="inner">
                            <div
                                className="save btn half"
                                onClick={() => this.save()}>Save &amp; Continue
                            </div>
                            <div
                                className="save btn half right red"
                                onClick={() => this.end()}>
                                End Game
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
