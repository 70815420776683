import React from 'react'
import Dispatcher from '../dispatchers/Dispatcher'
import Penalty from '../lib/Penalty'

export default class AutoPenaltyModal extends React.Component {
    constructor(props) {
        super(props)

        this.state = {
            team: null,
            length: null
        }
    }

    handleTeam(teamName) {
        this.setState({ team: teamName })
    }

    handleLength(length) {
        this.setState({ length: length })
    }

    resetState() {
        this.setState({ team: null, length: null })
    }

    save = () => {
        if (!this.state.team) {
            window.alert('You must select a team')
            return
        }

        if (!this.state.length) {
            window.alert('You must select a length')
            return
        }

        Dispatcher.dispatch({
            type: 'save-auto-penalty',
            data: {
                length: this.state.length,
                teamName: this.state.team,
                time: this.props.time,
                side: this.props.teamName === this.state.team ? 'team' : 'opponent'
            }
        })

        this.resetState()
    }

    cancel = () => {
        Dispatcher.dispatch({ type: 'close-auto-penalty-modal' })

        this.resetState()
    }

    render() {

        const penaltyTypeObject = Penalty.selectedPenaltyTypeOptions(this.props.time.penaltyType)

        return (
            <div className={`modal-overlay team ${this.props.open ? 'open' : ''}`}>
                <div className="modal-content">
                    <div className="section">
                        <h1>Add Penalty</h1>
                        <h3>Offending Team</h3>
                        <div className="btn-row-2">
                            <div className={`btn ${this.state.team === this.props.teamName ? 'active' : ''}`} onClick={() => this.handleTeam(this.props.teamName)}>
                                <span>{this.props.teamName}</span>
                            </div>
                            <div className={`btn ${this.state.team === this.props.opposingName ? 'active' : ''}`} onClick={() => this.handleTeam(this.props.opposingName)}>
                                <span>{this.props.opposingName}</span>
                            </div>
                        </div>
                    </div>
                    <div className='section'>
                        <h3>Penalty Length</h3>
                        <div className="btn-row-2">
                            <div className={`btn ${this.state.length === penaltyTypeObject.minor ? 'active' : ''}`} onClick={() => this.handleLength(penaltyTypeObject.minor)}>
                                <span>{penaltyTypeObject.minor} MIN</span>
                            </div>
                            <div className={`btn ${this.state.length === penaltyTypeObject.major ? 'active' : ''}`} onClick={() => this.handleLength(penaltyTypeObject.major)}>
                                <span>{penaltyTypeObject.major} MIN</span>
                            </div>
                        </div>
                    </div>
                    <div className="section">
                        <div className="btn save wide" onClick={this.save}>
                            Save
                        </div>
                        <div className="btn save wide red" onClick={this.cancel}>
                            Cancel
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}