import React from 'react';
import Dispatcher from '../dispatchers/Dispatcher';

export default class TaggerTypeModal extends React.Component {

    close = () => {
        Dispatcher.dispatch({ type: 'close-select-game-tagger-modal' });
    }

    navigate(view, taggerTarget) {
        Dispatcher.dispatch({
            type: 'select-tagger',
            data: {
                view: view,
                game: this.props.game,
                taggerTarget: taggerTarget
            }
        });
    }

    render() {
        return (
            <div className={`modal-overlay ${this.props.open ? 'open' : ''}`}>
                <div className="modal-content">
                    <div className="section">
                        <h1>Select Tagger Type</h1>
                        <div className="btn-row-6-stack">
                            <div className="btn" onClick={() => this.navigate('configure', 'tagger')}><span>Event Tagger</span></div>
                            <div className="btn" onClick={() => this.navigate('configure', 'time-on-ice')}><span>Time On Ice Tagger</span></div>
                            <div className="btn" onClick={() => this.navigate('video', 'video')}><span>Video Tagger</span></div>
                            <div className="btn blue" onClick={this.close}><span>Cancel</span></div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
