import React from 'react';
import Dispatcher from '../dispatchers/Dispatcher';

export default class Message extends React.Component {
    render() {
        if (this.props.message.length) {
            if (window.messageTimeout) {
                console.log('Message timeout is present');
                window.messageTimeout = null;
            } else {
                window.messageTimeout = window.setTimeout(() => {
                    Dispatcher.dispatch({ type: 'clear-message' });
                    window.messageTimeout = null;
                }, 1500);
            }

            return (
                <div className={`message ${this.props.flagEvent ? 'flag' : ''}`}>{this.props.message}</div>
            );
        } else {
            return '';
        }
    }
}
