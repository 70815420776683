import React from 'react';
import moment from 'moment';
import Dispatcher from '../dispatchers/Dispatcher';
import ConfigureClock from '../components/ConfigureClock';

export default class ConfigureHeader extends React.Component {
    navigate(view) {
        Dispatcher.dispatch({
            type: 'switch-view',
            data: view
        });
    }

    render() {
        const date = moment.utc(this.props.game.date);

        return (
            <div className="header">
                <div className="host">
                    <div className="team-name">
                        <div className="back">
                            {/* eslint-disable-next-line */}
                            <a href="#" onClick={() => this.navigate('select')}>
                                &laquo; Change Game
                            </a>
                        </div>
                        <div className="name" style={{ textAlign: 'left' }}>{this.props.game.team.name}</div>
                        <div className="location">Home</div>
                    </div>
                </div>

                <ConfigureClock
                    gameId={this.props.game.id}
                    duration={this.props.game.period_duration} />

                <div className="opponent">
                    <div className="team-name">
                        <div className="date-loc">{`${date.format("MMMM D, YYYY")} at ${date.format("h:mma")}`}</div>
                        <div className="name">{this.props.game.opponent_team_name}</div>
                        <div className="location">Away</div>
                    </div>
                </div>
                <div className="clear"></div>
            </div>
        );
    }
}
