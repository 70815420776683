import React from 'react';
import ConfigureHeader from './ConfigureHeader';
import ConfigureRoster from './ConfigureRoster';
import Dispatcher from '../dispatchers/Dispatcher';

export default class ConfigureGame extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            alternateJerseys: false
        };
    }

    continue() {
        Dispatcher.dispatch({
            type: 'launch-tagger',
            data: {
                ...this.props.game,
                alternateJerseys: this.state.alternateJerseys
            }
        });
    }

    updateJerseys() {
        this.setState({alternateJerseys: !this.state.alternateJerseys},
            () => Dispatcher.dispatch({
                type: 'change-user-alternate-jersey',
                data: {
                    alternateJerseys: this.state.alternateJerseys,
                    gameId: this.props.game.id
                }
            })
        )
    }

    render() {
        return (
            <div id="tagger">
                <div className="configure-game">

                    <ConfigureHeader game={this.props.game} />

                    <div
                        className={`btn btn-small btn-indicator alternate-jersey-btn ${ this.props.game.alternate_jerseys ? 'active' : ''}`}
                        onClick={() => this.updateJerseys()}>
                        <span>Use Alternate Jeryseys</span>
                    </div>

                    <div className="roster-container" style={{marginTop: '20px'}}>

                        <ConfigureRoster game={this.props.game} alternateJerseys={this.state.alternateJerseys} type={this.props.type} />

                        <div className="section center">
                            <div className="btn save" onClick={() => this.continue()}>
                                <span>Save & Continue</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
