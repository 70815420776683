import React from 'react';

export default class ZoneEntry extends React.Component {

    updateValue = (event, fieldName) => {
        this.props.updateEventData(this.props.index, fieldName, event.target.value)
    }

    renderResultOptions() {
        return this.props.game.configuration.zone_entry.actions.map((option, i) => {
            return <option key={i} value={option.value}>{option.display_name}</option>
        });
    }

    render() {
        return (
            <div className="event-col">
                <label>Result</label>
                <select name="result" className="form-control" value={this.props.event.value} onChange={(e) => this.updateValue(e, 'value')}>
                    {this.renderResultOptions()}
                </select>
            </div>
        );
    }
}
