import Dispatcher from '../dispatchers/Dispatcher'
import React from 'react'

export default function FaceoffAlert({ message }) {
    window.faceoffAlertTimeout = window.setTimeout(() => {
        Dispatcher.dispatch({ type: 'clear-faceoff-alert' })
        window.faceoffAlertTimeout = null
    }, 1000)

    return (
        <div id="faceoff-alert">
            <div id="faceoff-alert-text">{`${message}, Clock Running`}</div>
        </div>
    )
}
