import React from 'react';
import { Container } from 'flux/utils';
import TimeStore from '../stores/TimeStore';
import GameStore from '../stores/GameStore';
import UserStore from '../stores/UserStore';
import TimeOnIceStore from '../stores/TimeOnIceStore';
import VideoStore from '../stores/VideoStore';
import Tagger from '../components/Tagger';
import TimeOnIceTagger from '../components/time-on-ice/TimeOnIceTagger';
import VideoTagger from '../components/video/VideoTagger';
import Login from '../components/Login';
import SelectGame from '../components/SelectGame';
import ConfigureGame from '../components/ConfigureGame';
import Strings from '../lib/Strings';

function getStores() {
    return [
        TimeStore,
        GameStore,
        UserStore,
        TimeOnIceStore,
        VideoStore
    ];
}

function getState() {
    return {
        time:  TimeStore.getState(),
        game:  GameStore.getState(),
        user:  UserStore.getState(),
        toi:   TimeOnIceStore.getState(),
        video: VideoStore.getState()
    };
}

function currentGame() {
    return getState().user.games.filter((game) => {
        return game.id === getState().game.currentGame;
    })[0];
}

function Game(props) {
    /**
     * When we go to render the container we have some screens i.e. login
     * that the user can visit without a token. Otherwise we need to check
     * if there is a token before changing views. When we don't have a token
     * also show the login screen.
     *
     * TODO right now we don't save which screen the user was trying to access
     * before sending them to the login page. We can add that later if needed.
     */
    if (getState().game.view === 'login') {
        return <Login user={getState().user} />;
    } else {
        if (window.localStorage.getItem(Strings.TOKEN_KEY)) {
            switch(getState().game.view) {
            case 'select':
                return (
                    <SelectGame
                        modal={getState().game.modal}
                        games={getState().user.games}
                        gamesLoading={getState().user.gamesLoading}
                        message={getState().game.message}
                        completedGames={getState().user.completedGames}
                        game={currentGame()}
                        gamesUploading={getState().user.gamesUploading} />
                );
            case 'configure':
                return <ConfigureGame game={currentGame()} type={getState().game.taggerTarget} />;
            case 'time-on-ice':
                return (
                    <TimeOnIceTagger
                        { ...getState().toi }
                        time={getState().time}
                        uploading={getState().game.uploading} />
                );
            case 'video':
                return (
                    <VideoTagger {...getState().video} />
                );
            default:
                return <Tagger {...getState()} />;
            }
        } else {
            return <Login user={getState().user} />;
        }
    }
}

export default Container.createFunctional(Game, getStores, getState);
