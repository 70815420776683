import React from 'react';
import Strings from '../../lib/Strings';
import Dispatcher from '../../dispatchers/Dispatcher';
import TaggerMenu from '../TaggerMenu';
import VideoStartGameModal from './VideoStartGameModal';
import EndPeriodModal from './EndPeriodModal';
import EndGameModal from './EndGameModal';
import ExitGameModal from './ExitGameModal';
import Message from '../Message';

export default class VideoTagger extends React.Component {
    click(type, zone) {
        Dispatcher.dispatch({
            type: 'video-click',
            data: {
                type: type,
                zone: zone
            }
        });
    }

    endGame() {
        Dispatcher.dispatch({
            type: 'video-toggle-end-modal', 
            data: { 
                modal: 'end-game',
                open: true
             } 
        });
    }

    endPeriod() {
        Dispatcher.dispatch({
            type: 'video-toggle-end-modal', 
            data: { 
                modal: 'period',
                open: true
             } 
        });
    }

    openMenu() {
        Dispatcher.dispatch({ type: 'toggle-sidebar' });
    }

    zoneButtons(type) {
        return ['attack', 'neutral', 'defense'].map((zone) => {
            return (
                <div
                    key={zone}
                    onClick={() => this.click(type, zone)}
                    className="btn">
                    <span>{Strings.titleize(zone)} &nbsp;ZONE</span>
                </div>
            );
        });
    }

    render() {
        return (
            <div id="tagger" className="video-tagger">
                <TaggerMenu
                    open={this.props.sidebar}
                    hideRunningClock={true}
                    endGame={'video-end-game'}
                    hideStats={true} 
                    hideEditor={true} 
                    time={this.props.time}
                />

                <VideoStartGameModal open={!this.props.game_started} />

                <EndPeriodModal open={this.props.showEndPeriodModal}/>
                <EndGameModal open={this.props.showEndGameModal}/>
                <ExitGameModal open={this.props.showExitGameModal}/>

                <div className="configure-game">
                    <div className="header">
                        <div className="menu padded" onClick={() => this.openMenu()}>
                            Menu
                            &nbsp;
                            <span className="vellipsis">&hellip;</span>
                            <div className="period">Period: {this.props.period}</div>
                        </div>
                        <div className="teams">
                            <div className="host">
                                <div className="team-name">
                                    <div className="name">{this.props.team.name}</div>
                                    <div className="location">Home</div>
                                </div>
                            </div>
                            <div className="opponent">
                                <div className="team-name">
                                    <div className="name">{this.props.opponent_team_name}</div>
                                    <div className="location">Away</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div id="interface">
                    <div className="button-container">
                        <div className="section">
                            <div className="inner">
                                <div className="label">Good Play</div>
                                <div className="btn-row-3">
                                    {this.zoneButtons('good')}
                                </div>
                            </div>
                        </div>
                        <div className="section">
                            <div className="inner">
                                <div className="label">Bad Play</div>
                                <div className="btn-row-3">
                                    {this.zoneButtons('bad')}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="section btn-row-2 end video-actions">
                        <div>
                            <div className="btn" onClick={() => this.endPeriod()}><span>End Period</span></div>
                        </div>
                        <div>
                            <div className="btn" onClick={() => this.endGame()}><span>End Game</span></div>
                        </div>
                    </div>
                </div>
                <Message message={this.props.message} />
            </div>
        );
    }
}
