import React from 'react';
import Dispatcher from '../dispatchers/Dispatcher';

export default class TeamModal extends React.Component {
    save(team) {
        Dispatcher.dispatch({
            type: 'bottom-team-modal-save',
            data: {
                team: team,
                time: this.props.time
            }
        });
    }

    render() {
        return (
            <div className={`modal-overlay team ${this.props.open ? 'open' : ''}`}>
                <div className="modal-content">
                    <div className="section">
                        <h1>Select Team</h1>
                        <div className="btn-row-6-stack">
                            <div className="btn" onClick={() => this.save(this.props.teamName)}>
                                <span>{this.props.teamName}</span>
                            </div>
                            <div className="btn" onClick={() => this.save(this.props.opposingName)}>
                                <span>{this.props.opposingName}</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
