import React from 'react';
import Dispatcher from '../../dispatchers/Dispatcher';

export default class TimeOnIceActionButtons extends React.Component {
    start() {
        Dispatcher.dispatch({ type: 'toi-start' });
    }

    stop() {
        Dispatcher.dispatch({
            type: 'toi-stop',
            data: {
                time: this.props.time
            }
        });
    }

    endPeriod() {
        Dispatcher.dispatch({
            type: 'toi-toggle-end-modal', 
            data: { 
                modal: 'period',
                open: true
             } 
        });
    }

    endGame() {
        Dispatcher.dispatch({
            type: 'toi-toggle-end-modal', 
            data: { 
                modal: 'end-game',
                open: true
             } 
        });
    }
    
    render() {
        return(
            <div className="section action-row btn-row-4 toi-actions">
                <div>
                    <div className="btn" onClick={() => this.start()}>
                        <span>Start Clock</span>
                    </div>

                    <div className={`btn ${!this.props.clockRunning ? 'active' : ''}`} onClick={() => this.stop()}>
                        <span>Stop Clock</span>
                    </div>

                    <div className="btn" onClick={() => this.endPeriod()}>
                        <span>End Period</span>
                    </div>

                    <div className="btn" onClick={() => this.endGame()}>
                        <span>End Game</span>
                    </div>
                </div>
            </div>
        );
    }
}
