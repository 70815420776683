import React from 'react';

export default class GoalieRow extends React.Component {
    render() {
        const nameSplit = this.props.player.name.split(' ');

        const goals = this.props.events.filter((event) => {
            return event.type === 'goal' && event.goalie === this.props.player.id && event.team !== this.props.teamName;
        }).length;

        const shots = this.props.events.filter((event) => {
            return (event.type === 'shot' || event.type === 'goal') && event.goalie === this.props.player.id && event.team !== this.props.teamName;
        }).length;

        return (
            <tr>
                {/* player */}
                <td className='left'>{`${this.props.player.number} ${nameSplit[nameSplit.length - 1]}`}</td>
                {/* start */}
                <td>{this.props.player.starting ? 'Yes' : 'No'}</td>
                {/* goals */}
                <td>{goals}</td>
                {/* saves (tsog) */}
                <td>{shots - goals}</td>
                {/* shots */}
                <td>{shots}</td>
                {/* save % */}
                <td>{shots ? `${(((shots - goals) / shots) * 100).toFixed(2)}%` : '--'}</td>
            </tr>
        );
    }
}