import React from 'react';
import InterfaceTop from './InterfaceTop';
import InterfacePlayers from './InterfacePlayers';
import InterfaceRink from './InterfaceRink';
import InterfaceBottom from './InterfaceBottom';
import FaceoffAlert from './FaceoffAlert';

export default class TaggerInterface extends React.Component {
    constructor(props) {
        super(props);
        this.audio = new Audio("faceoff_confirmation.mp3");
    }

    playFaceoffAlertSound() {
        this.audio.play();
    }

    render() {
        return (
            <div id="interface">

                {
                    this.props.showFaceoffAlerts && this.props.faceoffAlert && (
                        <>
                            { this.playFaceoffAlertSound() }
                            <FaceoffAlert message="Faceoff Saved" />
                        </>
                    )
                }

                <InterfaceTop
                    configuration={this.props.configuration}
                    time={this.props.time}
                    playerLock={this.props.playerLock}
                    lockedEvent={this.props.lockedEvent}
                    topMenu={this.props.topMenu} />

                <InterfacePlayers
                    players={this.props.players}
                    lockedEvent={this.props.lockedEvent}
                    currentGoalie={this.props.currentGoalie}
                    time={this.props.time} />

                <InterfaceRink
                    playerLock={this.props.playerLock}
                    faceoffLock={this.props.faceoffLock}
                    iceLock={this.props.iceLock}
                    faceoff={this.props.lockedEvent && this.props.lockedEvent.type === 'faceoff'}
                    direction={this.props.direction}
                    time={this.props.time}
                    blockLock={this.props.lockedEvent && this.props.lockedEvent.type === 'block'}
                    lockedEvent={this.props.lockedEvent }
                    eventButton={this.props.eventButton}
                    battleButton={this.props.battleButton}
                    configuration={this.props.configuration} />

                <InterfaceBottom
                    trackSecondary={this.props.configuration.track_secondary_actions}
                    playerLock={this.props.playerLock}
                    faceoffLock={this.props.faceoffLock}
                    iceLock={this.props.iceLock}
                    bottomMenu={this.props.bottomMenu}
                    bottomItem={this.props.bottomItem}
                    time={this.props.time}
                    clockStopped={this.props.clockStopped}
                    lockedEvent={this.props.lockedEvent} />

            </div>
        );
    }
}
