import React from 'react'

export default class TeamRow extends React.Component {

    faceoffWinPercentage() {
        // Total faceoffs (event objects) for `team`.
        const faceoffs = this.props.events.filter((event) => {
            return event.type === 'faceoff' && event.result !== 'kicked_out';
        });

        // Return -- if there is no faceoff data for this user.
        if (!faceoffs.length) {
            return '--';
        }

        // Faceoff win event objects for won-faceoffs.
        const wins = faceoffs.filter((faceoff) => {
            // since the faceoff event itself doesn't change the team/opponent, oppoonent "wins" are actually
            // marked as "loss" in the tagger, so we have to check which side we're on here
            return faceoff.result.match(this.props.side === 'team' ? /win/ : /loss/);
        });

        // Wins / Total faceoffs * 100 to give percentage.
        return `${Math.round(((wins.length / faceoffs.length) * 100))}%`;
    }

    penaltyMinutes() {
        // Gather the total penalties where the `player` is the penalty player.
        // then we map those penalties down to the individual minutes.
        const penalties = this.props.events.filter((event) => {
            // type has to be penalty, `player` has to match, and it has to be on our side (we committed).
            return event.type === 'penalty' && event.team === this.props.teamName;
        }).map((penalty) => {
            // map array down to the individual minutes scored
            return penalty.length;
        });

        // If we don't have any penalties for this player we can't reduce.
        // When that's the case default to 0 mins.
        if (penalties.length) {
            // total the array of minutes
            return penalties.reduce((a, b) => {
                return a + b;
            });
        } else {
            return 0;
        }
    }

    render() {
        return (
            <tr>
                {/* Name */}
                <td style={{ textAlign: 'left' }}>{this.props.teamName}</td>

                {/* G */}
                <td>
                    {
                        this.props.events.filter((event) => {
                            return event.type === 'goal' && event.team === this.props.teamName;
                        }).length
                    }
                </td>

                {/* ICE */}
                <td>
                    {
                        this.props.events.filter(event => {
                            return event.type === 'icing' && event.team === this.props.teamName;
                        }).length
                    }
                </td>

                {/* OFF */}
                <td>
                    {
                        this.props.events.filter(event => {
                            return event.type === 'offside' && event.team === this.props.teamName;
                        }).length
                    }
                </td>

                {/* GvA - Giveaways */}
                <td>
                    {
                        this.props.events.filter(event => {
                            return event.type === 'turnover' && event.team === this.props.teamName;
                        }).length
                    }
                </td>

                {/* TkA - Takeaways */}
                <td>
                    {
                        this.props.events.filter((event) => {
                            return event.type === 'takeaway' && event.team === this.props.teamName;
                        }).length
                    }
                </td>

                {/* TSOG */}
                <td>
                    {
                        this.props.events.filter((event) => {
                            return (event.type === 'shot' || event.type === 'goal') && event.team === this.props.teamName;
                        }).length
                    }
                </td>

                {/* TSATT */}
                <td>
                    {
                        this.props.events.filter((event) => {
                            return (event.type === 'shot' || event.type === 'goal' || event.type === 'miss' || event.type === 'block') && event.team === this.props.teamName;
                        }).length
                    }
                </td>

                {/* FO % */}
                <td>{this.faceoffWinPercentage()}</td>

                {/* BLK */}
                <td>
                    {
                        this.props.events.filter((event) => {
                            return event.type === 'block' && event.team !== this.props.teamName;
                        }).length
                    }
                </td>

                {/* CHK */}
                <td>
                    {
                        this.props.events.filter((event) => {
                            return event.type === 'check' && event.team === this.props.teamName;
                        }).length
                    }
                </td>

                {/* PIM */}
                <td>{this.penaltyMinutes()}</td>
            </tr>
        )
    }
}