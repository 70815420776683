import React from 'react'
import Dispatcher from '../../dispatchers/Dispatcher'

export default function ClearLine({ position, time }) {
    const handleClear = () => {
        Dispatcher.dispatch({
            type: 'toi-line-clear',
            data: {
                position: position,
                time: time
            }
        });
    }

    return (
        <div className='btn line' onClick={handleClear}>
            <span>CLEAR</span>
        </div>
    )
}
