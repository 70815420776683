import Strings from './Strings';

export default class Storage {
    static getGames() {
        return JSON.parse(window.localStorage.getItem(Strings.GAMES_KEY));
    }

    static setGames(games) {
        window.localStorage.setItem(Strings.GAMES_KEY, JSON.stringify(games));
    }

    static getCompletedGames() {
        return JSON.parse(window.localStorage.getItem(Strings.COMPLETED_GAMES_KEY)) || [];
    }

    static setCompletedGames(games) {
        window.localStorage.setItem(Strings.COMPLETED_GAMES_KEY, JSON.stringify(games));
    }

    static completeGame(game) {
        let cGames = this.getCompletedGames();

        cGames.push({
            ...game,
            completed: true,
            /**
             * The period_duration needs to be overwritten by the window.initialDuration that is set
             */
            period_duration: window.initialDuration || game.period_duration || null
        });

        this.setCompletedGames(cGames);

        let games = this.getGames().filter((g) => {
            return g.id !== game.id;
        });

        this.setGames(games);

        window.location.reload();
    }

    static setToken (token) {
        window.localStorage.setItem(Strings.TOKEN_KEY, token);
    }

    static getToken() {
        return window.localStorage.getItem(Strings.TOKEN_KEY);
    }

    static logout() {
        window.localStorage.removeItem(Strings.TOKEN_KEY);
    }

    /**
     * Generate a UUID for this tagger.
     * This will enable multiple taggers to be running for a single game and
     * allow us to only replace events for the tagger that is uploading.
     * 
     * This UUID matches a tagger to a game's events.
     * 
     * https://stackoverflow.com/a/2117523
     */
    static generateTaggerId() {
        var newId = ([1e7]+-1e3+-4e3+-8e3+-1e11).replace(/[018]/g, c =>
            /* eslint-disable-next-line */
            (c ^ crypto.getRandomValues(new Uint8Array(1))[0] & 15 >> c / 4).toString(16)
        );

        window.localStorage.setItem(Strings.TAGGER_ID, newId);
        return newId;
    }

    static getTaggerId() {
        return window.localStorage.getItem(Strings.TAGGER_ID);
    }
}
