import React from 'react';
import Dispatcher from '../dispatchers/Dispatcher';

export default class EndExitGameModal extends React.Component {
    
    evalAction = () => {
        this.props.modalType === 'end-game' ? this.end() : this.exit()
    }

    end = () => {
        // send up end game along with time pp/pk data
        Dispatcher.dispatch({
            type: 'end-game',
            data: {
                time: {
                    powerPlaysCount: this.props.time.powerPlaysCount,
                    penaltyKillsCount: this.props.time.penaltyKillsCount
                }
            }
        });
    }

    exit = () =>  {
        window.location.reload();
    }

    cancel = () => {
        Dispatcher.dispatch({
            type: 'show-end-exit-game-modal',
            data: false
        });
    }

    render() {
        return (
            <div className={`modal-overlay team ${this.props.open ? 'open' : ''}`}>
                <div className="modal-content">
                    <div className="section">
                        <h1>{this.props.headerText}</h1>
                        <div className="btn-row-6-stack">
                            <div className="btn" onClick={this.evalAction}>
                                <span>{this.props.buttonText}</span>
                            </div>
                            <div className="btn" onClick={this.cancel}>
                                <span>Cancel</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
