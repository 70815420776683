import React from 'react'
import Dispatcher from '../dispatchers/Dispatcher'

export default function TaggerHeaderPenaltyClose({ side }) {
    const handleClick = () => {
        Dispatcher.dispatch({
            type: 'manual-close-penalty-modal',
            data: {
                side: side
            }
        });
    }

    return (
        <span className="pen-close" onClick={handleClick}>x</span>
    )
}